import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "urql";
import uuid from "react-uuid";

import "./mainaccount.css";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const trans_uid = uuid();

const SUBMIT_DEPOSIT = `
        mutation submitBusinessDeposit($amount:Int!, $transactionId:String!) {
          submitBusinessDeposit(amount: $amount, transactionId: $transactionId)
        }
      `;

const SUBMIT_WITHDRAWAL = `
        mutation submitBusinessWithdrawal($amount:Int!, $transactionId:String!) {
          submitBusinessWithdrawal(amount: $amount, transactionId: $transactionId)
        }
      `;

const SUBMIT_TRANSFER = `
        mutation submitBusinessTransfer($fromAccount:String!, $toAccount:String!, $amount:Int!, $transactionId:String!) {
          submitBusinessTransfer(fromAccount:$fromAccount, toAccount:$toAccount, amount: $amount, transactionId: $transactionId)
        }
      `;

const Mainaccount = (props) => {
  const [depositvalue, set_depositvalue] = useState(null);
  const [withdrawalvalue, set_withdrawalvalue] = useState(null);
  const [transfervalue, set_transfervalue] = useState(null);

  const [transoption, set_transoption] = useState("NONE");

  const [result_dep, submit_deposit] = useMutation(SUBMIT_DEPOSIT);

  const [result_wit, submit_withdrawal] = useMutation(SUBMIT_WITHDRAWAL);

  const [result_tra, submit_transfer] = useMutation(SUBMIT_TRANSFER);

  const [success, setSuccess] = useState(null);

  const [depositOn, setDepositOn] = useState(false);
  const [withdrawOn, setWithdrawOn] = useState(false);
  const [transferOn, setTransferOn] = useState(false);

  const switch_deposit = () => {
    if (depositOn == true) {
      setDepositOn(false);
      setWithdrawOn(false);
      setTransferOn(false);
    } else {
      setDepositOn(true);
      setWithdrawOn(false);
      setTransferOn(false);
    }
  };

  const switch_withrawal = () => {
    if (withdrawOn == true) {
      setWithdrawOn(false);
      setDepositOn(false);
      setTransferOn(false);
    } else {
      setWithdrawOn(true);
      setDepositOn(false);
      setTransferOn(false);
    }
  };

  const switch_transfer = () => {
    if (transferOn == true) {
      setTransferOn(false);
      setDepositOn(false);
      setWithdrawOn(false);
    } else {
      setTransferOn(true);
      setDepositOn(false);
      setWithdrawOn(false);
    }
  };

  const business_dep = (event) => {
    event.preventDefault();

    if (depositvalue !== null) {
      if (depositvalue > 0) {
        submit_deposit({ amount: ~~depositvalue, transactionId: trans_uid });
        window.location.reload();
      }
    }
  };

  const business_with = (event) => {
    event.preventDefault();

    if (withdrawalvalue !== null) {
      if (withdrawalvalue > 0) {
        submit_withdrawal({ amount: ~~withdrawalvalue, transactionId: trans_uid });
        window.location.reload();
      }
    }
  };

  const business_trans = (event) => {
    event.preventDefault();

    if (transfervalue !== null) {
      if (transfervalue > 0) {
        submit_transfer({ fromAccount: "MAIN", toAccount: transoption, amount: ~~transfervalue, transactionId: trans_uid });
        window.location.reload();
      }
    }
  };

  return (
    <div className='mainaccount-mainaccount'>
      <div className='mainaccount-container'>
        <span className='mainaccount-text'>MAIN</span>
        <span className='mainaccount-text1'>{numberWithCommas(props.data.balance) + " VT"}</span>
      </div>
      <div className='mainaccount-container1'>
        <svg viewBox='0 0 1024 1024' className='mainaccount-icon' onClick={switch_deposit}>
          <path d='M853.333 640v170.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-597.333c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-170.667c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v170.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h597.333c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-170.667c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM554.667 537.003v-409.003c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v409.003l-140.501-140.501c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c3.925 3.925 8.619 7.083 13.824 9.259s10.795 3.243 16.341 3.243c10.923 0 21.845-4.181 30.165-12.501l213.333-213.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z'></path>
        </svg>
        <svg viewBox='0 0 1024 1024' className='mainaccount-icon2' onClick={switch_withrawal}>
          <path d='M853.333 640v170.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-597.333c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-170.667c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v170.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h597.333c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-170.667c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM469.333 230.997v409.003c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-409.003l140.501 140.501c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-213.333-213.333c-4.096-4.096-8.789-7.168-13.824-9.259s-10.539-3.243-16.341-3.243c-5.547 0-11.136 1.067-16.341 3.243-5.035 2.091-9.728 5.163-13.824 9.259l-213.333 213.333c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0z'></path>
        </svg>
        <svg viewBox='0 0 1024 1024' className='mainaccount-icon4' onClick={switch_transfer}>
          <path d='M86 682l170-170v128h640v86h-640v128zM938 342l-170 170v-128h-640v-86h640v-128z'></path>
        </svg>
        <div className='mainaccount-container2'></div>
        <Link to={"/wallet/" + props.data.accountNo}>
          <svg viewBox='0 0 1024 1024' className='mainaccount-icon6'>
            <path d='M366 708l196-196-196-196 60-60 256 256-256 256z'></path>
          </svg>
        </Link>
      </div>
      {(depositOn == true) & (withdrawOn == false) & (transferOn == false) ? (
        <form className='mainaccount-deposit-form' onSubmit={business_dep}>
          <input
            type='number'
            min='1'
            step='1'
            required
            placeholder='amount'
            className='mainaccount-textinput input'
            value={depositvalue}
            onChange={(e) => {
              set_depositvalue(e.target.value);
            }}
          />
          <button type='submit' className='mainaccount-button button'>
            DEPOSIT
          </button>
        </form>
      ) : (
        <></>
      )}
      {(depositOn == false) & (withdrawOn == true) & (transferOn == false) ? (
        <form className='mainaccount-withdrawal-form' onSubmit={business_with}>
          <input
            type='number'
            min='1'
            step='1'
            required
            placeholder='amount'
            className='mainaccount-textinput1 input'
            value={withdrawalvalue}
            onChange={(e) => {
              set_withdrawalvalue(e.target.value);
            }}
          />
          <button type='submit' className='mainaccount-button1 button'>
            WITHDRAW
          </button>
        </form>
      ) : (
        <></>
      )}
      {(depositOn == false) & (withdrawOn == false) & (transferOn == true) ? (
        <form className='mainaccount-transfer-form' onSubmit={business_trans}>
          <select
            className='mainaccount-select'
            value={transoption}
            onChange={(e) => {
              set_transoption(e.target.value);
            }}>
            <option value='NONE'>Please select an Account</option>
            <option value='EXPENSE'>Expense</option>
            <option value='TAX'>Tax</option>
            <option value='PROFIT'>Profit</option>
          </select>
          <div className='mainaccount-container3'>
            <input
              type='number'
              min='1'
              step='1'
              required
              placeholder='amount'
              className='mainaccount-textinput2 input'
              value={transfervalue}
              onChange={(e) => {
                set_transfervalue(e.target.value);
              }}
            />
            {transoption !== "NONE" && (
              <button type='submit' className='mainaccount-button2 button'>
                TRANSFER
              </button>
            )}
          </div>
        </form>
      ) : (
        <></>
      )}
      {success == true && (
        <div className='mainaccount-container4'>
          <span className='mainaccount-text2'>SUCCESS</span>
        </div>
      )}
      {success == false && (
        <div className='mainaccount-container5'>
          <span className='mainaccount-text3'>FAILED</span>
        </div>
      )}
    </div>
  );
};

export default Mainaccount;
