import React, { useState } from "react";
import { useMutation } from "urql";

import "./general_settings.css";

const savesettings = `mutation saveLendingSettings($interestPercentage:Float, $weeklyFee:Float, $initialLendingAmount:Float, $maximumLendingAmount:Float, $useLimits:Boolean, $loanTerm:Int, $investmentToLimit:Int, $stopEnforcedLoans:Boolean, $enforceAfterTerms:Int, $enforcmentCostPercentage:Float){
    saveLendingSettings(data:[{id:"lending-settings-0000",interestPercentage:$interestPercentage, weeklyFee:$weeklyFee, initialLendingAmount:$initialLendingAmount, maximumLendingAmount:$maximumLendingAmount, useLimits:$useLimits, loanTerm:$loanTerm, investmentToLimit:$investmentToLimit, stopEnforcedLoans:$stopEnforcedLoans, enforceAfterTerms:$enforceAfterTerms, enforcmentCostPercentage:$enforcmentCostPercentage}]){
        id
    }
    }
  `;

const GeneralSettings = (props) => {
  const [result, domutate] = useMutation(savesettings);

  const [interest, setInterest] = useState(props.data.getLendingSettings.interestPercentage);
  const [fee, setFee] = useState(props.data.getLendingSettings.weeklyFee);
  const [minamount, setMinAmmount] = useState(props.data.getLendingSettings.initialLendingAmount);
  const [maxamount, setMaxAmmount] = useState(props.data.getLendingSettings.maximumLendingAmount);
  const [investmantToLimit, setinvestmantToLimit] = useState(props.data.getLendingSettings.investmentToLimit);
  const [useLimit, setUseLimit] = useState(props.data.getLendingSettings.useLimits);
  const [term, setTerm] = useState(props.data.getLendingSettings.loanTerm);
  const [stopEnforced, setStopEnforced] = useState(props.data.getLendingSettings.stopEnforcedLoans);
  const [enforceTerm, setEnforceTerm] = useState(props.data.getLendingSettings.enforceAfterTerms);
  const [enforcmentCost, setEnforcmentCost] = useState(props.data.getLendingSettings.enforcmentCostPercentage);

  async function switch_limits() {
    if (useLimit == true) {
      setUseLimit(false);
    } else {
      setUseLimit(true);
    }
  }

  async function switch_enf() {
    if (stopEnforced == true) {
      setStopEnforced(false);
    } else {
      setStopEnforced(true);
    }
  }

  async function savedata(event) {
    event.preventDefault();
    domutate({
      interestPercentage: +interest,
      weeklyFee: +fee,
      initialLendingAmount: +minamount,
      maximumLendingAmount: +maxamount,
      useLimits: true,
      loanTerm: +term,
      investmentToLimit: +investmantToLimit,
      stopEnforcedLoans: stopEnforced,
      enforceAfterTerms: +enforceTerm,
      enforcmentCostPercentage: +enforcmentCost,
    });
    window.location.reload();
  }

  return (
    <div className='general-settings-container'>
      <form className='general-settings-general-settings' onSubmit={savedata}>
        <span className='general-settings-text'>General Settings</span>
        <div className='general-settings-container01'>
          <span className='general-settings-text01'>Interest Percentage</span>
          <input
            type='number'
            max='100'
            min='0'
            step='0.1'
            value={interest}
            onChange={(e) => {
              setInterest(e.target.value);
            }}
            required
            placeholder='interest percentage'
            className='general-settings-textinput input'
          />
        </div>
        <div className='general-settings-container02'>
          <span className='general-settings-text02'>Weekly Fee</span>
          <input
            type='number'
            min='1'
            step='1'
            value={fee}
            onChange={(e) => {
              setFee(e.target.value);
            }}
            required
            placeholder='weekly fee'
            className='general-settings-textinput1 input'
          />
        </div>
        <div className='general-settings-container03'>
          <span className='general-settings-text03'>Minimum Initial Lending Amount</span>
          <input
            type='number'
            min='1'
            step='1'
            value={minamount}
            onChange={(e) => {
              setMinAmmount(e.target.value);
            }}
            required
            placeholder='minimum initial lending amount'
            className='general-settings-textinput2 input'
          />
        </div>
        <div className='general-settings-container04'>
          <span className='general-settings-text04'>Maximum Lending Amount</span>
          <input
            type='number'
            min='1'
            step='1'
            value={maxamount}
            onChange={(e) => {
              setMaxAmmount(e.target.value);
            }}
            required
            placeholder='maximum lending amount'
            className='general-settings-textinput3 input'
          />
        </div>
        <div className='general-settings-container05' style={{ display: "none" }}>
          <span className='general-settings-text05'>Use the Limit System</span>
          <input type='checkbox' className='general-settings-checkbox' checked={useLimit} onChange={switch_limits} />
        </div>
        <div className='general-settings-container06'>
          <span className='general-settings-text06'>Default Loan Term</span>
          <input
            type='number'
            max='365'
            min='1'
            step='1'
            value={term}
            onChange={(e) => {
              setTerm(e.target.value);
            }}
            required
            placeholder='default loan term'
            className='general-settings-textinput4 input'
          />
        </div>

        <div className='general-settings-container06'>
          <span className='general-settings-text06'>Investment To Limit Multiplier</span>
          <input
            type='number'
            max='100'
            min='1'
            step='1'
            value={investmantToLimit}
            onChange={(e) => {
              setinvestmantToLimit(e.target.value);
            }}
            required
            placeholder='investment to limit multiplier'
            className='general-settings-textinput4 input'
          />
        </div>

        <div className='general-settings-container07'>
          <span className='general-settings-text07'>Stop Enforced Loans</span>
          <input type='checkbox' className='general-settings-checkbox1' checked={stopEnforced} onChange={switch_enf} />
        </div>
        <div className='general-settings-container08'>
          <span className='general-settings-text08'>Enforce After Failed Term</span>
          <input
            type='number'
            min='1'
            step='1'
            value={enforceTerm}
            onChange={(e) => {
              setEnforceTerm(e.target.value);
            }}
            required
            placeholder='enforce after term'
            className='general-settings-textinput5 input'
          />
        </div>
        <div className='general-settings-container09'>
          <span className='general-settings-text09'>Enforcment Cost</span>
          <input
            type='number'
            max='100'
            min='0'
            step='0.1'
            value={enforcmentCost}
            onChange={(e) => {
              setEnforcmentCost(e.target.value);
            }}
            required
            placeholder='enforcment cost percentage'
            className='general-settings-textinput6 input'
          />
        </div>
        <div className='general-settings-container10'>
          <button type='submit' className='general-settings-button button'>
            SAVE
          </button>
        </div>
      </form>
    </div>
  );
};

export default GeneralSettings;
