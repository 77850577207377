import React from "react";
import { useQuery } from "urql";

import { Helmet } from "react-helmet";

import Header from "../components/header";
import EditMemberRemove from "../components/edit_member_remove";

import "./cbm_edit_group.css";

import Searchandadd from "../components/search_and_add";

const settingsquery = `query getGroup($id:ID!){
    getGroup(id:$id){
      id
      name
      administrator {
        id
        name
        avatar {
          id
          path
        }
      }
      member {
        id
        client {
          id
          name
          avatar {
            id
            path
          }
        }
      }
      area {
        id
        name
      }
    }
    }
  `;

const Cbmeditgroup = (props) => {
  let group_uid = props.match.params.group_uid;

  const [result, reexecuteQuery] = useQuery({
    query: settingsquery,
    requestPolicy: "cache-and-network",
    variables: { id: group_uid },
  });

  let _F = [];
  let _A = [];
  let _B = [];

  const { data, fetching, error } = result;

  if (data) {
    data.getGroup.administrator.map((admin) => {
      _A.push(
        <EditMemberRemove
          key={admin.id}
          rootClassName='avatarlistitemwithdelete-root-class-name1'
          className=''
          name={admin.name}
          avatar={admin.avatar}
          reexecute={reexecuteQuery}
          profile_id={admin.id}
          group_uid={group_uid}
          widget_type='ADMINISTRATORS'></EditMemberRemove>
      );
    });

    data.getGroup.member.map((client) => {
      _B.push(
        <EditMemberRemove
          key={client.client.id}
          rootClassName='avatarlistitemwithdelete-root-class-name1'
          className=''
          name={client.client.name}
          avatar={client.client.avatar}
          reexecute={reexecuteQuery}
          profile_id={client.client.id}
          group_uid={group_uid}
          client_id={client.id}
          widget_type='MEMBERS'></EditMemberRemove>
      );
    });

    _F.push(
      <div className='cbmeditgroup-body'>
        <span className='cbmeditgroup-text'>{data.getGroup.name}</span>
        <span className='cbmeditgroup-text1'>{data.getGroup.area.name}</span>
        <div className='cbmeditgroup-maincontainer'>
          <Searchandadd widget_type='ADMINISTRATORS' group_uid={group_uid}></Searchandadd>

          <div className='cbmeditgroup-searchadmincontent'>{_A}</div>
          <Searchandadd widget_type='MEMBERS' group_uid={group_uid}></Searchandadd>

          <div className='cbmeditgroup-searchmembercontent'>{_B}</div>
        </div>
      </div>
    );
  }

  return (
    <div className='cbmeditgroup-container'>
      <Helmet>
        <title>Edit Group - CBM-UI</title>
        <meta property='og:title' content='Edit Group - CBM-UI' />
      </Helmet>
      <Header></Header>
      {_F}
    </div>
  );
};

export default Cbmeditgroup;
