import React, { useState } from "react";
import { useQuery, useMutation } from "urql";

import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";
import uuid from "react-uuid";
import storageService from "../../services/storage";
import "react-toastify/dist/ReactToastify.css";
import KycContent from "../components/kyc_content";

import UserLoanitem from "../components/user_loan_item";

import Ledgeritempositive from "../components/ledgeritempositive";
import Ledgeritemnegative from "../components/ledgeritemnegative";

import Header from "../components/header";
import "./cbm_user_loan.css";

const entity_query = `
    query loanContractQuery($id:ID!, $contract_uid:ID!, $account_no:String!, $other_id:ID!) {

    getMadruhamSummary(accountNo:$account_no, transactionDirection:"ALL"){
      madruhamAccount {
        id
        name
        accountNo
      }  
      balance
      ts
      tsBalance
      ledgerItems
    }



      getContract(id:$contract_uid) {
        id
        files {
          id
          path
        }
        balance
        debtAccount {
          id
          accountNo
        }
        client {
          id
          client {
            id
            name
            entity {
              id
              digitalId {
                id
                contactDetail {
                  id
                  island {
                    id
                    name
                  }
                  location
                }
              }
              confirmedPhone {
                id
                phone
              }
              phone {
                id
                phone
              }
            }
          }
        }
        createdBy
        originalDeadline
        enforcmentCutoff
        contractStage
        contractAmount
        _Created__
      }

      getEntity(id:$id) {
        id   
        profiles {
            id
            name
            avatar {
                id
                path
            }
        }
        status
        user {
            id
            username
        }
        email {
          id
          email
        }
        confirmedEmail {
          id
          email
        }

        phone {
          id
          phone
        }
        confirmedPhone {
          id
          phone
        }        

        digitalId {
          id
          loginAllowed

                entity {
                    id
                    profiles {
                        id
                        avatar {
                            id
                            path
                        }                        
                    }

                    email{
                      id
                      email
                    }      
                    confirmedEmail {
                      id
                      email
                    }      
                    phone{
                      id
                      phone
                    }      
                    confirmedPhone{
                      id
                      phone
                    } 


                }
                idImage {
                    id
                    path
                    status
                    reasoning
                }
                idNo {
                    id
                    status
                    idNo
                }
                personalDetail {
                    id
                    name
                    dobYear
                    dobMonth
                    dobDay
                    gender
                    status
                    householdHead
                }
                contactDetail {
                    id
                    location
                    island {
                        id
                        name
                    } 
                    status                   
                }


        }
      }

      otherEntity:getEntity(id:$other_id) {
        id   
        profiles {
            id
            name
        }
      }

    }
  `;

let SUBMIT_DEPOSIT = `
        mutation submitLoanPayBack($id:ID!, $amount:Int!) {
          submitLoanPayBack(contractUid: $id, amount: $amount)
        }
      `;

let db_name = "";
try {
  db_name = storageService.getStorageData("access_point").split("/")[2].split(".")[0];
} catch {}

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const zeroPad = (num, places) => String(num).padStart(places, "0");

const numberWithCommas = (x) => {
  try {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch {
    return "0";
  }
};

const trans_uid = uuid();

const Cbmuserloan = (props) => {
  const [status, setStatus] = useState("NONE");

  const [paybackvalue, set_paybackvalue] = useState(null);

  const [result_dep, submit_payback] = useMutation(SUBMIT_DEPOSIT);

  let contract_uid = props.match.params.contract_uid;
  let entity_uid = props.match.params.entity_uid;
  let account_no = props.match.params.account_no;
  let other_entity = props.match.params.other_entity;

  let _F = [];
  let _G = [];
  let _E = [];
  let _H = [];
  let _J = [];
  let _K = [];
  let _L = [];

  const loan_rep_first = (event) => {
    event.preventDefault();
    setStatus("REPAYCONF");
  };

  const loan_rep = (event) => {
    event.preventDefault();

    if (paybackvalue !== null) {
      if (paybackvalue > 0) {
        submit_payback({ amount: ~~paybackvalue, id: contract_uid }).then((result_req) => {
          if (result_req.data !== null) {
            setStatus("SUCCESS");
          } else {
            setStatus("FAILED");
          }
        });
        //window.location.reload();
      }
    }
  };

  const refr = (event) => {
    event.preventDefault();
    window.location.reload();
  };

  const [result, reexecuteQuery] = useQuery({
    query: entity_query,
    variables: { id: entity_uid, contract_uid: contract_uid, account_no: account_no, other_id: other_entity },
    requestPolicy: "cache-and-network",
  });
  const { data, fetching, error } = result;

  if (data) {
    let tmp_data = {};
    tmp_data["getdID"] = data.getEntity.digitalId;
    var _d = new Date(data.getContract._Created__);

    _F.push(
      <Link
        to={"/entity/" + entity_uid}
        style={{ width: "100%", "margin-bottom": "8px", padding: "8px" }}
        className='entityeditor-entityeditor'>
        <KycContent key='test01' data={tmp_data} hide={"TRUE"}></KycContent>
      </Link>
    );

    _E.push(<UserLoanitem data={data.getContract} key={data.getContract.id}></UserLoanitem>);

    _G.push(
      <div className='entityeditor-container' style={{ "margin-bottom": "12px" }}>
        <span className='entityeditor-text'>{"Registered By: " + data.otherEntity.profiles[0].name}</span>
        <span className='entityeditor-text1'>{_d.toISOString().replace("T", " ").split(".")[0]}</span>
      </div>
    );

    _H.push(
      <div className='cbmuserloan-balancestrip'>
        <span className='cbmuserloan-ledgerbalanetext'>Loan Balance:</span>
        <span className='cbmuserloan-balanceamounttext'>{numberWithCommas(data.getContract.balance) + " VT"}</span>
      </div>
    );

    data.getMadruhamSummary.ledgerItems.map((ledgeritem) => {
      if (ledgeritem.amount > 0) {
        _J.push(<Ledgeritemnegative className='' key={ledgeritem.id} data={ledgeritem}></Ledgeritemnegative>);
      } else {
        _J.push(<Ledgeritempositive className='' key={ledgeritem.id} data={ledgeritem}></Ledgeritempositive>);
      }
    });

    if (data.getContract.balance > 0) {
      _K.push(
        <form className='cbmuserloan-paybackloan' onSubmit={loan_rep_first}>
          <span className='cbmuserloan-text'>PAYBACK outstanding loan</span>
          <span className='cbmuserloan-text01'>outstanding balance</span>
          <span className='cbmuserloan-text02'>{numberWithCommas(data.getContract.balance) + " VT"}</span>
          <input
            type='number'
            min='1'
            step='1'
            max={data.getContract.balance}
            placeholder='amount'
            className='cbmuserloan-textinput input'
            value={paybackvalue}
            onChange={(e) => {
              set_paybackvalue(+e.target.value);
            }}
          />
          <button type='submit' className='cbmuserloan-button button'>
            -&gt; NEXT -&gt;
          </button>
        </form>
      );

      _L.push(
        <form className='cbmuserloan-confirmloan' onSubmit={loan_rep}>
          <span className='cbmuserloan-text03'>Confirm the amount</span>
          <span className='cbmuserloan-text04'>{numberWithCommas(paybackvalue) + " VT"}</span>
          <button type='submit' className='cbmuserloan-button1 button'>
            PAYBACK NOW
          </button>
        </form>
      );
    }
  }

  return (
    <div className='cbmuserloan-container'>
      <Helmet>
        <title>Loan - CBM-UI</title>
        <meta property='og:title' content='Loan - CBM-UI' />
      </Helmet>
      <Header></Header>
      <div className='cbmuserloan-body'>
        <div className='cbmuserloan-maincontainer'>
          {(status !== "SUCCESS") & (status !== "FAILED") ? (
            <>
              {_F}
              {_E}
              {_G}
            </>
          ) : (
            <></>
          )}

          {(status !== "SUCCESS") & (status !== "FAILED") & (status !== "REPAYCONF") ? <>{_K}</> : <></>}

          {status == "REPAYCONF" && (
            <form className='cbmuserloan-confirmloan' onSubmit={loan_rep}>
              <span className='cbmuserloan-text03'>Confirm the amount</span>
              <span className='cbmuserloan-text04'>{numberWithCommas(paybackvalue) + " VT"}</span>
              <button type='submit' className='cbmuserloan-button1 button'>
                PAYBACK NOW
              </button>
            </form>
          )}

          {status == "SUCCESS" && (
            <form className='cbmuserloan-successloan' onSubmit={refr}>
              <span className='cbmuserloan-text05'>SUCCESS</span>
              <svg viewBox='0 0 1024 1024' className='cbmuserloan-icon'>
                <path d='M864 128l-480 480-224-224-160 160 384 384 640-640z'></path>
              </svg>
              <button type='submit' className='cbmuserloan-button2 button'>
                FINISH
              </button>
            </form>
          )}
          {status == "FAILED" && (
            <form className='cbmuserloan-errorloan' onSubmit={refr}>
              <span className='cbmuserloan-text06'>
                <span>ERROR</span>
                <br></br>
                <br></br>
                <span> TRY AGAIN</span>
              </span>
              <svg viewBox='0 0 1024 1024' className='cbmuserloan-icon2'>
                <path d='M874.040 149.96c-96.706-96.702-225.28-149.96-362.040-149.96s-265.334 53.258-362.040 149.96c-96.702 96.706-149.96 225.28-149.96 362.040s53.258 265.334 149.96 362.040c96.706 96.702 225.28 149.96 362.040 149.96s265.334-53.258 362.040-149.96c96.702-96.706 149.96-225.28 149.96-362.040s-53.258-265.334-149.96-362.040zM896 512c0 82.814-26.354 159.588-71.112 222.38l-535.266-535.268c62.792-44.758 139.564-71.112 222.378-71.112 211.738 0 384 172.262 384 384zM128 512c0-82.814 26.354-159.586 71.112-222.378l535.27 535.268c-62.794 44.756-139.568 71.11-222.382 71.11-211.738 0-384-172.262-384-384z'></path>
              </svg>
              <button type='submit' className='cbmuserloan-button3 button'>
                RESTART
              </button>
            </form>
          )}
          {(status !== "SUCCESS") & (status !== "FAILED") ? (
            <div className='cbmuserloan-all-loans'>
              {_H}

              <div className='cbmuserloan-loanitems'>{_J}</div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cbmuserloan;
