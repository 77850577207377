import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "urql";
import uuid from "react-uuid";

import "./expenseaccount.css";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const trans_uid = uuid();

const SUBMIT_TRANSFER = `
        mutation submitBusinessTransfer($fromAccount:String!, $toAccount:String!, $amount:Int!, $transactionId:String!) {
          submitBusinessTransfer(fromAccount:$fromAccount, toAccount:$toAccount, amount: $amount, transactionId: $transactionId)
        }
      `;

const SUBMIT_EXPENSE = `
        mutation submitBusinessExpense($amount:Int!, $transactionId:String!, $expenseType:String!, $note:String!) {
          submitBusinessExpense(amount: $amount, transactionId: $transactionId, expenseType:$expenseType, note:$note)
        }
      `;

const Expenseaccount = (props) => {
  const [success, setSuccess] = useState(null);

  const [spendOn, setSpendOn] = useState(false);
  const [transferOn, setTransferOn] = useState(false);

  const [transfervalue, set_transfervalue] = useState(null);
  const [expvalue, set_expvalue] = useState(null);
  const [note, set_note] = useState("");

  const [transoption, set_transoption] = useState("NONE");

  const [expoption, set_expoption] = useState("NONE");

  const [result_tra, submit_transfer] = useMutation(SUBMIT_TRANSFER);
  const [result_exp, submit_expense] = useMutation(SUBMIT_EXPENSE);

  const switch_spend = () => {
    if (spendOn == true) {
      setSpendOn(false);
      setTransferOn(false);
    } else {
      setSpendOn(true);
      setTransferOn(false);
    }
  };

  const switch_transfer = () => {
    if (transferOn == true) {
      setTransferOn(false);
      setSpendOn(false);
    } else {
      setTransferOn(true);
      setSpendOn(false);
    }
  };

  const business_trans = (event) => {
    event.preventDefault();

    if (transfervalue !== null) {
      if (transfervalue > 0) {
        submit_transfer({ fromAccount: "EXPENSE", toAccount: transoption, amount: ~~transfervalue, transactionId: trans_uid });
        window.location.reload();
      }
    }
  };

  const business_exp = (event) => {
    event.preventDefault();

    if (expvalue !== null) {
      if (expvalue > 0) {
        submit_expense({ amount: ~~expvalue, transactionId: trans_uid, expenseType: expoption, note: note });
        window.location.reload();
      }
    }
  };

  return (
    <div className='expenseaccount-expenseaccount'>
      <div className='expenseaccount-container'>
        <span className='expenseaccount-text'>EXPENSE</span>
        <span className='expenseaccount-text1'>{numberWithCommas(props.data.balance) + " VT"}</span>
      </div>
      <div className='expenseaccount-container1'>
        <svg viewBox='0 0 1024 1024' className='expenseaccount-icon' onClick={switch_transfer}>
          <path d='M86 682l170-170v128h640v86h-640v128zM938 342l-170 170v-128h-640v-86h640v-128z'></path>
        </svg>
        <svg viewBox='0 0 1097.142857142857 1024' className='expenseaccount-icon2' onClick={switch_spend}>
          <path d='M438.857 658.286h219.429v-54.857h-73.143v-256h-65.143l-84.571 78.286 44 45.714c13.714-12 22.286-18.286 31.429-32.571h1.143v164.571h-73.143v54.857zM731.429 512c0 104-62.857 237.714-182.857 237.714s-182.857-133.714-182.857-237.714 62.857-237.714 182.857-237.714 182.857 133.714 182.857 237.714zM1024 658.286v-292.571c-80.571 0-146.286-65.714-146.286-146.286h-658.286c0 80.571-65.714 146.286-146.286 146.286v292.571c80.571 0 146.286 65.714 146.286 146.286h658.286c0-80.571 65.714-146.286 146.286-146.286zM1097.143 182.857v658.286c0 20-16.571 36.571-36.571 36.571h-1024c-20 0-36.571-16.571-36.571-36.571v-658.286c0-20 16.571-36.571 36.571-36.571h1024c20 0 36.571 16.571 36.571 36.571z'></path>
        </svg>
        <div className='expenseaccount-container2'></div>
        <Link to={"/wallet/" + props.data.accountNo}>
          <svg viewBox='0 0 1024 1024' className='expenseaccount-icon4'>
            <path d='M366 708l196-196-196-196 60-60 256 256-256 256z'></path>
          </svg>
        </Link>
      </div>

      {(transferOn == true) & (spendOn == false) ? (
        <form className='expenseaccount-transfer-form' onSubmit={business_trans}>
          <select
            className='expenseaccount-select'
            value={transoption}
            onChange={(e) => {
              set_transoption(e.target.value);
            }}>
            <option value='NONE'>Please select an Account</option>
            <option value='MAIN'>Main</option>
            <option value='TAX'>Tax</option>
            <option value='PROFIT'>Profit</option>
          </select>
          <div className='expenseaccount-container3'>
            <input
              type='number'
              min='1'
              step='1'
              required
              placeholder='amount'
              className='expenseaccount-textinput input'
              value={transfervalue}
              onChange={(e) => {
                set_transfervalue(e.target.value);
              }}
            />
            {transoption !== "NONE" && (
              <button type='submit' className='expenseaccount-button button'>
                TRANSFER
              </button>
            )}
          </div>
        </form>
      ) : (
        <></>
      )}

      {(transferOn == false) & (spendOn == true) ? (
        <form className='expenseaccount-transfer-form1' onSubmit={business_exp}>
          <select
            className='expenseaccount-select1'
            value={expoption}
            onChange={(e) => {
              set_expoption(e.target.value);
            }}>
            <option value='NONE'>Please Select a Type</option>
            <option value='SALARY'>Salary</option>
            <option value='TRANSPORT'>Transport Cost</option>
            <option value='OFFICE'>Office Cost</option>
            <option value='BILL'>Bills</option>
            <option value='RENT'>Rent</option>
            <option value='LOSS'>Loss</option>
            <option value='OTHER'>Other</option>
          </select>
          <input
            type='text'
            required
            minLength={4}
            placeholder='note on spending'
            className='expenseaccount-textinput1 input'
            value={note}
            onChange={(e) => {
              set_note(e.target.value);
            }}
          />
          <div className='expenseaccount-container4'>
            <input
              type='number'
              min='1'
              step='1'
              required
              placeholder='amount'
              className='expenseaccount-textinput2 input'
              value={expvalue}
              onChange={(e) => {
                set_expvalue(e.target.value);
              }}
            />
            {expoption !== "NONE" && (
              <button type='submit' className='expenseaccount-button1 button'>
                REGISTER
              </button>
            )}
          </div>
        </form>
      ) : (
        <></>
      )}

      {success == true && (
        <div className='expenseaccount-container5'>
          <span className='expenseaccount-text2'>SUCCESS</span>
        </div>
      )}
      {success == false && (
        <div className='expenseaccount-container6'>
          <span className='expenseaccount-text3'>FAILED</span>
        </div>
      )}
    </div>
  );
};

export default Expenseaccount;
