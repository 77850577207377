import React, { useState } from "react";
import { useQuery, useMutation } from "urql";

import { Helmet } from "react-helmet";

import storageService from "../../services/storage";

import Cbmcolorfulbar from "../components/cbmcolorfulbar";

import Grouplistitem from "../components/grouplistitem";
import Header from "../components/header";

import "./cbm_groups.css";

const getarea = `query allArea{
    allArea(pageLength:100){
      id
      name
      group {
        id
        name
        member {
          id
        }
        administrator {
          id
          name
        }
      }
    }
    }
  `;

const savesettings = `mutation saveGroup($name:String!, $areaid:ID!){
    saveGroup(data:[{name:$name, area:{id:$areaid}}]){
        id
    }
    }
  `;

const Cbmgroups = (props) => {
  let dta = JSON.parse(storageService.getStorageData("fps")).fronPageStats;
  const [resultMut, domutate] = useMutation(savesettings);

  const [result, reexecuteQuery] = useQuery({
    query: getarea,
    requestPolicy: "cache-and-network",
  });

  let _F = [];

  let _G = [];

  let _firstarea = "NONE";

  _F.push(
    <option value='NONE' key='NONE'>
      Please Select an Area
    </option>
  );

  const { data, fetching, error } = result;

  if (data) {
    data.allArea.map((area) => {
      if (area.group.length > 0) {
        _G.push(<span className='cbmgroups-text2'>{area.name}</span>);
        area.group.map((group) => {
          _G.push(<Grouplistitem data={group}></Grouplistitem>);
        });
      }
      _F.push(
        <option value={area.id} key={area.id}>
          {area.name}
        </option>
      );
    });
  }

  const [hidden, setHidden] = useState(true);
  const [area, setArea] = useState(_firstarea);

  const [name, setName] = useState("");

  async function switch_hid() {
    if (hidden == true) {
      setHidden(false);
    } else {
      setHidden(true);
    }
  }

  async function savedata(event) {
    if (area !== "NONE") {
      event.preventDefault();
      domutate({
        name: name,
        areaid: area,
      });
      window.location.reload();
    }
  }

  return (
    <div className='cbmgroups-container'>
      <Helmet>
        <title>Groups - CBM-UI</title>
        <meta property='og:title' content='Groups - CBM-UI' />
      </Helmet>
      <Header></Header>
      <div className='cbmgroups-body'>
        <div className='cbmgroups-maincontainer'>
          {dta.isHealthy == false && (
            <div className='pushscreen-systemwarning'>
              <span className='pushscreen-text'>FINANCIAL SYSTEM IS DOWN! CONTACT POST OFFICE IT.</span>
            </div>
          )}

          <Cbmcolorfulbar rootClassName='cbmcolorfulbar-root-class-name2'></Cbmcolorfulbar>

          <div className='cbmgroups-container1' onClick={switch_hid}>
            <svg viewBox='0 0 1024 1024' className='cbmgroups-icon'>
              <path d='M726 554v-84h-172v-172h-84v172h-172v84h172v172h84v-172h172zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z'></path>
            </svg>
            <span className='cbmgroups-text1'>Add Group</span>
          </div>
          {hidden == false && (
            <form className='cbmgroups-form' onSubmit={savedata}>
              <input
                type='text'
                required
                placeholder='group name'
                className='cbmgroups-textinput input'
                minLength='6'
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <select
                className='cbmgroups-select'
                value={area}
                onChange={(e) => {
                  setArea(e.target.value);
                }}>
                {_F}
              </select>
              {area !== "NONE" && (
                <div className='cbmgroups-container2'>
                  <button type='submit' className='cbmgroups-button button'>
                    SAVE
                  </button>
                </div>
              )}
            </form>
          )}
          {_G}
        </div>
      </div>
    </div>
  );
};

export default Cbmgroups;
