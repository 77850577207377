import React from "react";
import { useQuery } from "urql";

import { Helmet } from "react-helmet";

import Header from "../components/header";
import KycContent from "../components/kyc_content";
import "./kyc_info.css";

const postcheck = `
        query getdID($id:ID!) {
          getdID(id:$id) {
                id
                additionalImage {
                    id
                    path
                }
                entity {
                    id
                    profiles {
                        id
                        avatar {
                            id
                            path
                        }
                    } 
                    email{
                      id
                      email
                    }      
                    confirmedEmail {
                      id
                      email
                    }      
                    phone{
                      id
                      phone
                    }      
                    confirmedPhone{
                      id
                      phone
                    } 
                }
                idImage {
                    id
                    path
                    status
                    reasoning
                }
                idNo {
                    id
                    status
                    idNo
                }
                personalDetail {
                    id
                    name
                    dobYear
                    dobMonth
                    dobDay
                    gender
                    status
                    householdHead
                }
                contactDetail {
                    id
                    location
                    island {
                        id
                        name
                    }
                    status   
              
                }

          }
        }
      `;

const Kycinfo = (props) => {
  let did_uid = props.match.params.did_uid;

  const [result, reexecuteQuery] = useQuery({
    query: postcheck,
    requestPolicy: "cache-and-network",
    variables: { id: did_uid },
  });

  let _F = [];

  const { data, fetching, error } = result;

  if (data) {
    _F.push(<KycContent key='test01' data={data}></KycContent>);
  }

  return (
    <div className='kycinfo-container'>
      <Helmet>
        <title>KYC info - Vanuatu Post</title>
        <meta property='og:title' content='KYC info - Vanuatu Post' />
      </Helmet>
      <Header></Header>
      <div className='kycinfo-body'>
        <div className='kycinfo-maincontainer'>
          <div className='kycinfo-screencontent'>{_F}</div>
        </div>
      </div>
    </div>
  );
};

export default Kycinfo;
