import React, { useState, useEffect } from "react";
import { useQuery } from "urql";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import Header from "../components/header";

import UserListIteWwithArrow from "../components/user_listitem_with_arrow";

import storageService from "../../services/storage";

import BiggestDepartments from "../components/biggestdepartment";
import Cbmcolorfulbar from "../components/cbmcolorfulbar";

import SoloStatisticsStorePerIsland from "../components/soloChart_storePerIsland";

import "./users.css";

let access_point = storageService.getStorageData("access_point");

const search_profile = `
    query allDepartment($filter:String) {
      allDepartment(filter:{name:$filter}, fuzzy:true) {
        id
        name
        logo {
          id
          path
        }      
      }
    }
  `;

const Departments = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [searchInputInput, setSearchInputInput] = useState("");

  const [usersearch, setUserSearch] = useState([]);
  let _usersearch = [];

  let dta = JSON.parse(storageService.getStorageData("fps")).fronPageStats;

  const zeros = () => {
    if (searchInput.trim().length == 0) {
      return true;
    } else {
      return false;
    }
  };

  const szero = zeros() === true;

  const slength = () => {
    if (searchInput.trim().length < 3) {
      return true;
    } else {
      return false;
    }
  };

  const shouldPause = slength() === true;
  const [result, reexecuteQuery] = useQuery({
    url: access_point,
    query: search_profile,
    variables: { filter: searchInput.trim() },
    requestPolicy: "cache-and-network",
    pause: shouldPause,
  });
  const { data, fetching, error } = result;

  useEffect(() => {
    if (searchInput.trim().length > 2) {
      reexecuteQuery();
    }
    _usersearch = [];

    if (error) {
      _usersearch.push();
    }

    if (fetching) {
      _usersearch.push();
    }

    try {
      data.allDepartment.map((emp) => {
        _usersearch.push(
          <Link to={"/department_settings/" + emp.id} key={"x-" + emp.id} style={{ width: "100%" }}>
            <UserListIteWwithArrow
              rootClassName='avatarlistitemwithadder-root-class-name'
              user_name={emp.name}
              avatar={emp.logo}
              key={emp.id}></UserListIteWwithArrow>
          </Link>
        );
      });
    } catch {}

    setUserSearch(_usersearch);
  }, [searchInput]);

  return (
    <div className='users-container'>
      <Helmet>
        <title>departments - Vanuatu Post</title>
        <meta
          name='description'
          content='the business ready database, featuring field level streaming, IoT support, GraphQL, fine grained access control, in-memory graph database backend'
        />
        <meta property='og:title' content='departments - Vanuatu Post' />
        <meta
          property='og:description'
          content='the business ready database, featuring field level streaming, IoT support, GraphQL, fine grained access control, in-memory graph database backend'
        />
      </Helmet>
      <Header rootClassName='header-root-class-name8'></Header>
      <div className='users-contentframe'>
        <div className='users-contentcontainer'>
          {dta.isHealthy == false && (
            <div className='pushscreen-systemwarning'>
              <span className='pushscreen-text'>FINANCIAL SYSTEM IS DOWN! CONTACT POST OFFICE IT.</span>
            </div>
          )}

          <Cbmcolorfulbar rootClassName='cbmcolorfulbar-root-class-name2'></Cbmcolorfulbar>

          <div className='storescreen-container1'>
            <SoloStatisticsStorePerIsland></SoloStatisticsStorePerIsland>
          </div>

          <div className='storescreen-searchbar'>
            <input
              type='text'
              placeholder='search'
              autoComplete='off'
              className='storescreen-textinput input'
              value={searchInputInput}
              onChange={(e) => {
                setSearchInput(e.target.value.trim());
                setSearchInputInput(e.target.value);
              }}
            />
          </div>
          <div className='storescreen-searchcontent'>
            <div className='storescreen-activecontainer'>{szero == true ? <BiggestDepartments></BiggestDepartments> : usersearch}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Departments;
