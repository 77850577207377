import React, { useState } from "react";
import Chart from "react-apexcharts";
import { useWindowSize } from "react-use";

import PropTypes from "prop-types";

import Ledgeritempositive from "./ledgeritempositive";
import Ledgeritemnegative from "./ledgeritemnegative";
import Countcorrect from "./count_correct";
import Countnegative from "./count_negative";
import Countpositive from "./count_positive";

import "./ledger_view.css";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }
  render() {
    if (this.state.errorInfo) {
      return <div></div>;
    }
    return this.props.children;
  }
}

export function numerizeFloat(value) {
  return [value["timestamp"], +value["value"]];
}

const LedgerView = (props) => {
  let bal = [[100000, 0]];
  bal = props.data.getMadruhamSummary.tsBalance;

  bal.push({ timestamp: props.data.getMadruhamSummary.ts, value: props.data.getMadruhamSummary.balance });

  bal = bal.map(numerizeFloat);

  let tmp = [];
  if (bal.length == 0) {
    tmp = [[1000, 0.1]];
  } else {
    tmp = bal;
  }
  let apex_data = [{ data: tmp }];

  let { width, height } = useWindowSize();
  if (width > 565) {
    width = 565;
  } else {
    width = width - 35;
  }

  let apex_options = {
    colors: ["#0293cdff"],
    yaxis: {
      labels: {
        show: true,
        formatter: function (val) {
          return ~~val;
        },
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    chart: {
      id: "area-datetime",
      type: "area",
      animations: {
        enabled: false,
        dynamicAnimation: {
          enabled: false,
        },
      },
      zoom: {
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
      tooltip: {
        enabled: false,
        show: false,
      },
    },

    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
      show: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0.0,
        stops: [0, 100],
        gradientToColors: ["#0293cdff"],
      },
    },
  };

  let _F = [];

  props.data.getMadruhamSummary.ledgerItems.map((ledgeritem) => {
    if (ledgeritem.transaction_type == "MARK") {
      if (ledgeritem.amount == 0) {
        _F.push(<Countcorrect className='' key={ledgeritem.id} data={ledgeritem}></Countcorrect>);
      } else if (ledgeritem.amount < 0) {
        _F.push(<Countnegative className='' key={ledgeritem.id} data={ledgeritem}></Countnegative>);
      } else {
        _F.push(<Countpositive className='' key={ledgeritem.id} data={ledgeritem}></Countpositive>);
      }
    } else {
      if (ledgeritem.amount < 0) {
        _F.push(<Ledgeritemnegative className='' key={ledgeritem.id} data={ledgeritem}></Ledgeritemnegative>);
      } else {
        _F.push(<Ledgeritempositive className='' key={ledgeritem.id} data={ledgeritem}></Ledgeritempositive>);
      }
    }
  });

  async function remove_page() {
    if (props.page < 1) {
      props.set_page(0);
    } else {
      props.set_page(props.page - 1);
    }
  }

  async function add_page() {
    props.set_page(props.page + 1);
  }

  return (
    <div className={`ledgerview-container ${props.rootClassName} `}>
      <div className='ledgerview-ledgerchart'>
        <ErrorBoundary>
          <Chart options={apex_options} series={apex_data} type='area' height='100%' width={width} />
        </ErrorBoundary>
      </div>
      <div className='ledgerview-balancestrip'>
        <span className='ledgerview-ledgerbalanetext'>Balance:</span>
        <span className='ledgerview-balanceamounttext'>{numberWithCommas(props.data.getMadruhamSummary.balance) + " VT"}</span>
      </div>
      <span className='ledgerview-ledgersmallprint'> </span>
      <div className='ledgerview-ledgerhistorytopstrip'>
        <div></div>
        <div className='ledgerview-ledgerpagingtopbit'>
          <svg viewBox='0 0 1024 1024' className='ledgerview-topleftarrow' onClick={remove_page}>
            <path d='M658 708l-60 60-256-256 256-256 60 60-196 196z' className=''></path>
          </svg>
          <span className='ledgerview-toppageno'>{props.page + 1}</span>
          <svg viewBox='0 0 1024 1024' className='ledgerview-toprightarrow' onClick={add_page}>
            <path d='M366 708l196-196-196-196 60-60 256 256-256 256z' className=''></path>
          </svg>
        </div>
      </div>
      <div className='ledgerview-transactionlist'>{_F}</div>
      <div className='ledgerview-ledgerhistorybottomstrip'>
        <div className='ledgerview-ledgerpagerbottombit'>
          <svg viewBox='0 0 1024 1024' className='ledgerview-bottomleftarrow' onClick={remove_page}>
            <path d='M658 708l-60 60-256-256 256-256 60 60-196 196z' className=''></path>
          </svg>
          <span className='ledgerview-bottompageno'>{props.page + 1}</span>
          <svg viewBox='0 0 1024 1024' className='ledgerview-bottomrightarrow' onClick={add_page}>
            <path d='M366 708l196-196-196-196 60-60 256 256-256 256z' className=''></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

LedgerView.defaultProps = {
  rootClassName: "",
};

LedgerView.propTypes = {
  rootClassName: PropTypes.string,
};

export default LedgerView;
