import React from "react";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import storageService from "../../services/storage";
import Activepushitem from "../components/active_push_item";
import Historicpushitem from "../components/historic_push_item";

import Header from "../components/header";

import Cbmcolorfulbar from "../components/cbmcolorfulbar";

import "./manager.css";

import { useQuery } from "urql";

const frontpageData = `query fronPageStats{
    fronPageStats{
      noOfPushes
      didCount
      storeCount
      transactedValue
      isHealthy
      activePushes
      ts
      historicPushes
      myCashBalance
      cashAccount
      goodLoans
      slowLoans
      badLoans       
    }
    }
  `;

const FinanceManager = (props) => {
  const [result, reexecuteQuery] = useQuery({
    query: frontpageData,
    requestPolicy: "cache-and-network",
  });

  let _F = [];
  let _D = [];
  let _E = [];

  let _FPS = [];
  const { data, fetching, error } = result;

  if (data) {
    storageService.setStorageData("fps", JSON.stringify(data));

    data.fronPageStats.activePushes.map((push) => {
      _D.push(<Activepushitem key={push.id} ts={data.fronPageStats.ts} data={push}></Activepushitem>);
    });

    data.fronPageStats.historicPushes.map((push) => {
      _E.push(<Historicpushitem key={push.id} ts={data.fronPageStats.ts} data={push}></Historicpushitem>);
    });

    _FPS.push(
      <div className='manager-contentcontainer' key='001'>
        {data.fronPageStats.isHealthy == false && (
          <div className='pushscreen-systemwarning'>
            <span className='pushscreen-text'>FINANCIAL SYSTEM IS DOWN! CONTACT POST OFFICE IT.</span>
          </div>
        )}
        <Cbmcolorfulbar rootClassName='cbmcolorfulbar-root-class-name2' data={data.fronPageStats}></Cbmcolorfulbar>

        <div className='pushscreen-searchbar'>
          <svg viewBox='0 0 1024 1024' className='pushscreen-icon'>
            <path d='M772.736 673.792c2.944-2.261 5.547-4.907 7.808-7.851l113.195-113.536c58.325-58.325 87.509-134.869 87.509-211.285s-29.184-152.96-87.509-211.285-134.869-87.509-211.285-87.509-152.96 29.184-211.285 87.509l-288 288c-8.32 8.32-12.501 19.243-12.501 30.165v345.003l-115.499 115.499c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l115.499-115.499h345.003c11.819 0 22.485-4.779 30.208-12.544zM401.664 682.667h241.707l-85.077 85.333h-241.963zM728.448 597.333h-241.451l225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-396.501 396.501v-242.005l275.499-275.499c41.685-41.685 96.256-62.507 150.955-62.507s109.269 20.821 150.955 62.507 62.507 96.256 62.507 150.955-20.821 109.269-62.507 150.955z'></path>
          </svg>
          <Link to='/new_push'>
            <svg viewBox='0 0 1024 1024' className='pushscreen-icon2'>
              <path d='M512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM554 298v172h172v84h-172v172h-84v-172h-172v-84h172v-172h84z'></path>
            </svg>
          </Link>
        </div>

        <span className='pushscreen-activetext'>Active</span>
        <div className='pushscreen-activecontainer' style={{ width: "100%" }}>
          {_D}
        </div>
        <span className='pushscreen-activetext'>Closed</span>
        <div className='pushscreen-activecontainer' style={{ width: "100%" }}>
          {_E}
        </div>
      </div>
    );
  }
  return (
    <div className='manager-container'>
      <Helmet>
        <title>Finance - Vanuatu Post</title>
        <meta property='og:title' content='Finance - Vanuatu Post' />
      </Helmet>
      <Header rootClassName='header-root-class-name'></Header>
      <div className='manager-contentframe'>{_FPS}</div>
    </div>
  );
};

export default FinanceManager;
