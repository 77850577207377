import React from "react";
import Avatar from "react-avatar";

import "./user_listitem_with_arrow.css";

const UserListIteWwithArrow = (props) => {
  let avatar_img = "";
  try {
    if (props.avatar.path == "") {
    } else {
      avatar_img = process.env.REACT_APP_STORAGE_READ_HOST + "/" + props.avatar.path;
    }
  } catch {}

  return (
    <div className={`userlistitemwitharrow-applicationitem`}>
      <Avatar src={avatar_img} size='50' name={props.user_name} className='userlistitemwitharrow-image' />

      <div className='userlistitemwitharrow-container'>
        <span className='userlistitemwitharrow-text'>{props.user_name}</span>
        <span className='userlistitemwitharrow-text1'>{props.other_info}</span>
      </div>
      <svg viewBox='0 0 1024 1024' className='userlistitemwitharrow-icon'>
        <path d='M366 708l196-196-196-196 60-60 256 256-256 256z' className=''></path>
      </svg>
    </div>
  );
};

export default UserListIteWwithArrow;
