import React from "react";
import { copyTextToClipboard, getNumberUnit } from "../../services/utilities";
import Chart from "react-apexcharts";
import { useWindowSize } from "react-use";

import "./demographicstatistics.css";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }
  render() {
    if (this.state.errorInfo) {
      return <div></div>;
    }
    return this.props.children;
  }
}

export function numerizeFloat(value) {
  return [value["timestamp"], +value["value"]];
}

const Demographicstatistics = (props) => {
  let { width, height } = useWindowSize();
  if (width > 565) {
    width = 565;
  } else {
    width = width - 35;
  }

  let ao02 = {
    chart: {
      type: "bar",
      animations: {
        enabled: false,
        dynamicAnimation: {
          enabled: false,
        },
      },
      zoom: {
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
      tooltip: {
        enabled: false,
        show: false,
      },
    },
    plotOptions: {
      bar: {
        barHeight: "100%",
        distributed: true,
        horizontal: false,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      shared: false,
      intersect: false,
    },
    xaxis: {
      categories: props.data.ageGroups.names,
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
        formatter: function (val) {
          return getNumberUnit(~~val);
        },
      },
    },
  };

  let ao03 = {
    chart: {
      type: "pie",
      animations: {
        enabled: false,
        dynamicAnimation: {
          enabled: false,
        },
      },
    },
    labels: props.data.schoolLevels.names,

    chartOptions: {
      labels: props.data.schoolLevels.names,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      shared: false,
      intersect: false,
    },
  };

  let ao04 = {
    chart: {
      type: "pie",
      animations: {
        enabled: false,
        dynamicAnimation: {
          enabled: false,
        },
      },
    },
    labels: props.data.relationshipStatuses.names,

    chartOptions: {
      labels: props.data.relationshipStatuses.names,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      shared: false,
      intersect: false,
    },
  };

  let bo01 = {
    chart: {
      type: "bar",
      animations: {
        enabled: false,
        dynamicAnimation: {
          enabled: false,
        },
      },
      zoom: {
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
      tooltip: {
        enabled: false,
        show: false,
      },
    },
    plotOptions: {
      bar: {
        barHeight: "100%",
        distributed: true,
        horizontal: false,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      shared: false,
      intersect: false,
    },
    xaxis: {
      categories: props.data.schoolLevels.names,
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
        formatter: function (val) {
          return getNumberUnit(~~val);
        },
      },
    },
  };

  let bo02 = {
    chart: {
      type: "bar",
      animations: {
        enabled: false,
        dynamicAnimation: {
          enabled: false,
        },
      },
      zoom: {
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
      tooltip: {
        enabled: false,
        show: false,
      },
    },
    plotOptions: {
      bar: {
        barHeight: "100%",
        distributed: true,
        horizontal: false,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      shared: false,
      intersect: false,
    },
    xaxis: {
      categories: props.data.relationshipStatuses.names,
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
        formatter: function (val) {
          return getNumberUnit(~~val);
        },
      },
    },
  };

  let ao05 = {
    chart: {
      type: "bar",
      animations: {
        enabled: false,
        dynamicAnimation: {
          enabled: false,
        },
      },
      zoom: {
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
      tooltip: {
        enabled: false,
        show: false,
      },
    },
    plotOptions: {
      bar: {
        barHeight: "100%",
        distributed: true,
        horizontal: false,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      shared: false,
      intersect: false,
    },
    xaxis: {
      categories: props.data.selfdisabilities.names,
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  return (
    <div className='demographicstatistics-demographicstatistics'>
      <div className='demographicstatistics-container03'>
        <span className='demographicstatistics-text03'>Percentage of Woman:</span>
        <span className='demographicstatistics-text04'>{(props.data.percentageOfWoman * 100).toFixed(2) + "%"}</span>
      </div>
      <div className='demographicstatistics-container04'>
        <div className='demographicstatistics-container05' style={{ width: "100%" }}>
          <ErrorBoundary style={{ width: "100%" }}>
            <Chart
              options={ao02}
              series={[{ data: props.data.ageGroups.AgeGroups, name: "AgeGroupd" }]}
              type='bar'
              height='100%'
              width={width}
            />
          </ErrorBoundary>
        </div>
      </div>
      <div className='demographicstatistics-container06'>
        <div className='demographicstatistics-container05'>
          <ErrorBoundary>
            <Chart
              options={bo01}
              series={[{ data: props.data.schoolLevels.Education, name: "Education" }]}
              type='bar'
              height='100%'
              width={width}
            />
          </ErrorBoundary>
        </div>
        <div className='demographicstatistics-container06'>
          <div className='demographicstatistics-container05'>
            <ErrorBoundary>
              <Chart
                options={bo02}
                series={[{ data: props.data.relationshipStatuses.RelationshipStatus, name: "RelationshipStatus" }]}
                type='bar'
                height='100%'
                width={width}
              />
            </ErrorBoundary>
          </div>
        </div>
      </div>
      <div className='demographicstatistics-container07'>
        <span className='demographicstatistics-text06'>Percentage of Single Parents:</span>
        <span className='demographicstatistics-text07'>{(props.data.percentageOfSingleParents * 100).toFixed(2) + "%"}</span>
      </div>
      <div className='demographicstatistics-container08'>
        <span className='demographicstatistics-text08'>Average No. of Kids</span>
        <span className='demographicstatistics-text09'>{props.data.avgNoOfKids.toFixed(2)}</span>
      </div>
      <div className='demographicstatistics-container09'>
        <span className='demographicstatistics-text10'>Percentage of Gardens:</span>
        <span className='demographicstatistics-text11'>{(props.data.percentageOfGardens * 100).toFixed(2) + "%"}</span>
      </div>
      <div className='demographicstatistics-container10'>
        <span className='demographicstatistics-text12'>Disabilities</span>
        <div className='demographicstatistics-container11'>
          <ErrorBoundary style={{ width: "100%" }}>
            <Chart
              options={ao05}
              series={[
                { data: props.data.selfdisabilities.selfDisability, name: "Self-Disability" },
                { data: props.data.familydisabilities.familyDisability, name: "Family-Disability" },
              ]}
              type='bar'
              height='100%'
              width={width}
            />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};

export default Demographicstatistics;
