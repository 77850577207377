import storageService from "../services/storage";
import uuid from "react-uuid";
import { createClient } from "urql";

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

const SAVE_ORG_IMG = `
        mutation saveAdminFile($id:ID!, $mimeType:String!, $path:String!) {
          saveFile:saveAdminFile(data:[{id:$id, mimeType:$mimeType, path:$path}]) {
            id
          }        
        }
      `;

const SAVE_DEPT_IMG = `
        mutation saveAdminFile($id:ID!, $mimeType:String!, $path:String!) {
          saveFile:saveAdminFile(data:[{id:$id, mimeType:$mimeType, path:$path}]) {
            id
          }
        }
      `;

const NET_LINKING = `
        mutation link_image {
          save<object>(data:[{id:"<base_uid>", <field_name>:{id:"<field_value>"}}]) {
            id
          } 
        }
      `;

const save_image = (path, parent_uid, object_type, field_name, img_uid, mime, data_location, return_url_base, uploading) => {
  // submission part comes herer to create a data-entry
  let device_uid = storageService.getStorageData("device_uid");
  let accessPoint = storageService.getStorageData("access_point");
  let token = storageService.getStorageData("access_token");
  let dep_uid = storageService.getStorageData("active_department");

  const tmp_client = createClient({
    url: accessPoint,
    fetchOptions: () => {
      if (data_location === "org") {
        return {
          headers: {
            app_uid: "000000",
            dep_uid: dep_uid,
            device_uid: device_uid,
            nonce: uuid(),
            authorization: token ? `Bearer ${token}` : "",
          },
        };
      } else if (data_location === "dep") {
        return {
          headers: {
            app_uid: "000000",
            dep_uid: dep_uid,
            device_uid: device_uid,
            nonce: uuid(),
            authorization: token ? `Bearer ${token}` : "",
          },
        };
      }
    },
  });

  let fileMutation = "";
  if (data_location === "org") {
    fileMutation = SAVE_ORG_IMG;
  } else {
    fileMutation = SAVE_DEPT_IMG;
  }

  let second_q = NET_LINKING;
  second_q = second_q.replace("<object>", object_type);
  second_q = second_q.replace("<base_uid>", parent_uid);
  second_q = second_q.replace("<field_name>", field_name);
  second_q = second_q.replace("<field_value>", img_uid);

  tmp_client
    .mutation(fileMutation, { id: img_uid, mimeType: mime, path: path })
    .toPromise()
    .then((result) => {
      tmp_client
        .mutation(second_q)
        .toPromise()
        .then((result) => {
          uploading(true);
          window.location.reload();
        })
        .catch((error) => {});
    })
    .catch((error) => {});
};

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  path,
  parent_uid,
  object_type,
  field_name,
  img_uid,
  uploading,
  return_url_base,
  data_location,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob

  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      uploading(true);

      const options = {
        method: "PUT",
        headers: {
          AccessKey: process.env.REACT_APP_STORAGE_ACCESSKEY,
          "content-type": "application/octet-stream",
        },
        body: file,
      };

      const uriimg = process.env.REACT_APP_STORAGE_BASE_HOST + path + ".webp";

      fetch(uriimg, options)
        .then((response) => response.json())
        .then((response) => {
          save_image(path + ".webp", parent_uid, object_type, field_name, img_uid, "image/webp", data_location, return_url_base, uploading);
        })
        .catch((err) => console.error(err));
    }, "image/webp");
  });
}
