import React from "react";
import { useQuery } from "urql";
import { Link } from "react-router-dom";

import Ledgerlistitem from "./ledger_listitem";

const biggestbiz = `
    query getBiggestDepartmentHoldings {
      getBiggestDepartmentHoldings 

    }
  `;

const BiggestDepartments = (props) => {
  let _usersearch = [];

  const [result, reexecuteQuery] = useQuery({
    query: biggestbiz,
    requestPolicy: "cache-and-network",
  });

  const { data, fetching, error } = result;

  if (error) {
    _usersearch.push();
  }

  if (fetching) {
    _usersearch.push();
  }

  if (data == null) {
  } else {
    data.getBiggestDepartmentHoldings.map((emp) => {
      try {
        _usersearch.push(
          <Link to={"/department_settings/" + emp.id} key={"x-" + emp.id} style={{ width: "100%" }}>
            <Ledgerlistitem
              rootClassName='avatarlistitemwithadder-root-class-name'
              name={emp.name}
              balance={emp.balance}
              account_no={emp.accountNo}
              key={emp.id}></Ledgerlistitem>
          </Link>
        );
      } catch {}
    });
  }

  return <div style={{ width: "100%" }}>{_usersearch}</div>;
};

export default BiggestDepartments;
