import React, { useState, useRef } from "react";
import { useQuery, useMutation } from "urql";

import { Helmet } from "react-helmet";

import Header from "../components/header";
import KycContent from "../components/kyc_content";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Fab from "@mui/material/Fab";
import { Link } from "react-router-dom";
import { Camera } from "react-camera-pro";
import uuid from "react-uuid";
import storageService from "../../services/storage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { dataURItoBlob } from "../../services/utilities";

import { blobToWebP } from "webp-converter-browser";

import "./deposit_manager.css";

const entity_query = `
    query entityQuery($id:ID!) {

      getEntity(id:$id) {
        id   
        profiles {
            id
            name
            avatar {
                id
                path
            }
        }
        status
        user {
            id
            username
        }
        email {
          id
          email
        }
        confirmedEmail {
          id
          email
        }

        phone {
          id
          phone
        }
        confirmedPhone {
          id
          phone
        }        

        digitalId {
          id
          loginAllowed

                entity {
                    id
                    profiles {
                        id
                        avatar {
                            id
                            path
                        }                        
                    }

                    email{
                      id
                      email
                    }      
                    confirmedEmail {
                      id
                      email
                    }      
                    phone{
                      id
                      phone
                    }      
                    confirmedPhone{
                      id
                      phone
                    } 


                }
                idImage {
                    id
                    path
                    status
                    reasoning
                }
                idNo {
                    id
                    status
                    idNo
                }
                personalDetail {
                    id
                    name
                    dobYear
                    dobMonth
                    dobDay
                    gender
                    status
                    householdHead
                }
                contactDetail {
                    id
                    location
                    island {
                        id
                        name
                    } 
                    status                   
                }


        }
      }

    }
  `;

let SUBMIT_DEPOSIT = `
        mutation submitDeposit($entity:ID!, $amount:Int!, $imageId:ID!, $transactionId:String!) {
          submitDeposit(entity: $entity, amount: $amount, imageId: $imageId, transactionId: $transactionId)
        }
      `;

let db_name = "";
try {
  db_name = storageService.getStorageData("access_point").split("/")[2].split(".")[0];
} catch {}

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const zeroPad = (num, places) => String(num).padStart(places, "0");

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const Depositmanager = (props) => {
  let SAVE_PROFILE = `
        mutation saveAdminFile($uid:ID!, $mime_type:String!, $path:String!) {
          saveAdminFile(data:[{id:$uid, mimeType:$mime_type, path:$path, digitalIdBacklink:{id:"<did_uid>"}}]) {
            id
          }
        }
      `;

  const [fabon, setfabon] = useState(true);
  const [fixeropen, setfixeropen] = useState(false);
  const [cameradirection, setcameradirection] = useState("environment");
  const [legacyfile, setlegacyfile] = useState("");

  const camera = useRef(null);
  const [image, setImage] = useState(null);
  const [showprogress, setShowProgress] = useState(false);

  const [result_prof, save_profile] = useMutation(SAVE_PROFILE);
  const [result_dep, submit_deposit] = useMutation(SUBMIT_DEPOSIT);

  const [stage, setStage] = useState("FIRST");

  const [thisImage, setThisImage] = useState("");

  const [amount_to_deposit, set_amount_to_deposit] = useState(null);

  let entity_uid = props.match.params.entity_uid;

  const trans_uid = uuid();

  const [result, reexecuteQuery] = useQuery({
    query: entity_query,
    variables: { id: entity_uid },
    requestPolicy: "cache-and-network",
  });

  let _F = [];
  const { data, fetching, error } = result;

  if (data) {
    SAVE_PROFILE = SAVE_PROFILE.replace("<did_uid>", data.getEntity.digitalId.id);

    let tmp_data = {};
    tmp_data["getdID"] = data.getEntity.digitalId;
    _F.push(<KycContent key='test01' data={tmp_data} hide={"TRUE"}></KycContent>);
  }

  const switchfixer = () => {
    if (fixeropen == true) {
      setfixeropen(false);
      setfabon(true);
    } else {
      setfixeropen(true);
      setfabon(false);
    }
  };

  const switch_to_second = (event) => {
    event.preventDefault();
    setStage("SECOND");
  };

  const switchcamera = () => {
    if (cameradirection == "environment") {
      setcameradirection("user");
      camera.current.switchCamera();
    } else {
      setcameradirection("environment");
      camera.current.switchCamera();
    }
  };

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      setfixeropen(false);
      setImage(imageDataUrl);
    }
  };

  const submitDeposit = (event) => {
    event.preventDefault();
    submit_deposit({ entity: entity_uid, amount: Number(amount_to_deposit), imageId: thisImage, transactionId: trans_uid }).then(
      (result_dep) => {
        if (result_dep.data !== null) {
          setStage("SUCCESS");
        } else {
          setStage("ERROR");
        }
      }
    );
  };

  return (
    <div className='depositmanager-container'>
      <Helmet>
        <title>Vanuatu post - Deposit</title>
        <meta property='og:title' content='Vanuatu post - Deposit' />
      </Helmet>
      <Header rootClassName='header-root-class-name30'></Header>
      <div className='depositmanager-contentframe'>
        <div className='depositmanager-contentcontainer'>
          <div className='depositmanager-homepanel'>
            {(stage !== "SECOND") & (stage !== "SUCCESS") & (stage !== "ERROR") ? (
              <div className='depositmanager-kyccontainer'>{_F}</div>
            ) : (
              <></>
            )}

            {stage == "FIRST" && (
              <form className='depositmanager-deposit-form' onSubmit={switch_to_second}>
                <span className='depositmanager-text'>Enter the amount of how much you would like to DEPOSIT</span>
                <input
                  type='number'
                  min='1'
                  step='1'
                  required
                  placeholder='amount'
                  className='depositmanager-textinput input'
                  value={amount_to_deposit}
                  onChange={(e) => {
                    set_amount_to_deposit(e.target.value.trim());
                  }}
                />
                <button type='submit' className='depositmanager-button button'>
                  -&gt; NEXT -&gt;
                </button>
              </form>
            )}

            {stage == "SECOND" && (
              <div className='depositmanager-takepicturebox'>
                <form className='kycedititem-page05' id='pp_form'>
                  <span className='kycedititem-text18'>Take a profile picture of the Client</span>
                  {image ? (
                    <div>
                      <img src={image} className='uploadid-uploadid' style={{ width: "100%", height: "auto" }} />
                      {showprogress && (
                        <Box sx={{ width: "100%" }}>
                          <LinearProgress />
                        </Box>
                      )}
                    </div>
                  ) : (
                    <Camera ref={camera} facingMode={cameradirection} aspectRatio={1 / 1} className='uploadid-uploadid' />
                  )}

                  <div className='registar-camerafixer'>
                    <div className='registar-headlinecamerafixer' onClick={switchfixer}>
                      <span className='registar-camerafixertext'>Fix Camera issues</span>
                      {fixeropen == false && (
                        <svg viewBox='0 0 1024 1024' className='registar-openicon'>
                          <path d='M366 708l196-196-196-196 60-60 256 256-256 256z'></path>
                        </svg>
                      )}
                      {fixeropen == true && (
                        <svg viewBox='0 0 1024 1024' className='registar-closeicon'>
                          <path d='M316 658l-60-60 256-256 256 256-60 60-196-196z'></path>
                        </svg>
                      )}
                    </div>
                    {fixeropen == true && (
                      <div className='registar-fixcameracollapsible'>
                        <div className='registar-fixcamerarefreshcontainer'>
                          <span className='registar-fixcamerarefreshtext'>1, Refresh the page -&gt;</span>
                          <button
                            className='registar-refreshfixcamera button'
                            onClick={() => {
                              window.location.reload();
                            }}>
                            REFRESH
                          </button>
                        </div>
                        <div className='registar-fixcameraswitchcontainer'>
                          <span className='registar-fixcameraswitchtext'>2, Try to Switch the Camera Direction -&gt;</span>
                          <button className='registar-switchfixedcamera button' onClick={switchcamera}>
                            SWITCH
                          </button>
                        </div>

                        <div className='registar-fixcamerauploadcontainer'>
                          <span className='registar-fixcamerauploadtext'>3, Select an Image</span>
                          <div className='registar-fixcamerauploadform'>
                            <input
                              type='file'
                              placeholder='select a picture'
                              className='registar-fixcamerauploadinput input'
                              accept='image/*'
                              onChange={onFileChange}
                            />
                            <svg viewBox='0 0 1024 1024' className='registar-uploadfixcamera'>
                              <path d='M362 214l214 298-214 298h-192l214-298-214-298h192zM662 214l212 298-212 298h-192l212-298-212-298h192z'></path>
                            </svg>
                          </div>
                        </div>

                        <div className='registar-fixcameraexplanationcontainer'>
                          <span className='registar-fixcameraexplanationtext'>
                            If your camera is permanently not switching on or blocked, you may forget to authorize Camera Access when you
                            first used the application. Please go to your Browsers SETTINGS -&gt; Site Settings -&gt; Camera to authorize
                            it, than refresh the page.
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  {image ? (
                    <div className='kycedititem-container25'>
                      <button
                        type='button'
                        className='kycedititem-button15 button'
                        onClick={() => {
                          if (showprogress == false) {
                            setImage(null);
                          }
                        }}>
                        New Picture
                      </button>

                      <button
                        type='button'
                        className='kycedititem-button16 button'
                        onClick={async () => {
                          if (showprogress == false) {
                            var cf = dataURItoBlob(image);

                            setShowProgress(true);

                            const webpBlob = await blobToWebP(cf);

                            const options = {
                              method: "PUT",
                              headers: {
                                AccessKey: process.env.REACT_APP_STORAGE_ACCESSKEY,
                                "content-type": "application/octet-stream",
                              },
                              body: webpBlob,
                            };

                            const img_uid = uuid();

                            const pathprepare = "/" + db_name + "/Deposit/" + entity_uid + "/" + img_uid;

                            const uriimg = process.env.REACT_APP_STORAGE_BASE_HOST + pathprepare + ".webp";

                            setThisImage(img_uid);

                            fetch(uriimg, options)
                              .then((response) => response.json())
                              .then((response) => {
                                save_profile({ uid: img_uid, mime_type: "image/webp", path: pathprepare + ".webp" }).then((result) => {
                                  setShowProgress(false);

                                  try {
                                    setStage("THIRD");
                                  } catch {
                                    toast.error("Some error happened..");
                                  }
                                });
                              })
                              .catch((err) => {
                                console.error(err);
                                toast.error("Upload error..");
                              });
                          }
                        }}>
                        Submit Picture
                      </button>
                    </div>
                  ) : (
                    <>
                      {fabon == true && (
                        <Fab
                          color='primary'
                          aria-label='camera'
                          size='large'
                          style={{ position: "fixed", bottom: "50px" }}
                          onClick={() => {
                            let current_photo = camera.current.takePhoto();
                            setImage(current_photo);
                          }}>
                          <svg viewBox='0 0 1024 1024' className='floatinccameraicon'>
                            <path d='M1024 810.667v-469.333c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-147.84l-72.661-109.013c-7.765-11.52-20.736-18.987-35.499-18.987h-256c-13.909 0.085-27.307 6.741-35.499 18.987l-72.661 109.013h-147.84c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v469.333c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h768c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496zM938.667 810.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-768c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-469.333c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c14.763 0 27.733-7.467 35.499-18.987l72.661-109.013h210.347l72.661 109.013c8.192 12.245 21.589 18.901 35.499 18.987h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165zM725.333 554.667c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464-112.299 23.936-150.869 62.464-62.464 91.989-62.464 150.869 23.936 112.299 62.464 150.869 91.989 62.464 150.869 62.464 112.299-23.936 150.869-62.464 62.464-91.989 62.464-150.869zM640 554.667c0 35.371-14.293 67.285-37.504 90.496s-55.125 37.504-90.496 37.504-67.285-14.293-90.496-37.504-37.504-55.125-37.504-90.496 14.293-67.285 37.504-90.496 55.125-37.504 90.496-37.504 67.285 14.293 90.496 37.504 37.504 55.125 37.504 90.496z'></path>
                          </svg>
                        </Fab>
                      )}
                    </>
                  )}
                </form>
              </div>
            )}

            {stage == "THIRD" && (
              <form className='depositmanager-confirmation' onSubmit={submitDeposit}>
                <span className='depositmanager-text02'>Confirm the amount</span>
                <span className='depositmanager-text03'>{numberWithCommas(amount_to_deposit) + " VT"}</span>
                <button type='submit' className='depositmanager-button1 button'>
                  DEPOSIT NOW
                </button>
              </form>
            )}

            {stage == "SUCCESS" && (
              <div className='depositmanager-success'>
                <span className='depositmanager-text04'>SUCCESS</span>
                <svg viewBox='0 0 1024 1024' className='depositmanager-icon'>
                  <path d='M864 128l-480 480-224-224-160 160 384 384 640-640z'></path>
                </svg>
                <Link to={"/entity/" + entity_uid} style={{ width: "100%" }}>
                  <button className='depositmanager-button2 button'>FINISH</button>
                </Link>
              </div>
            )}

            {stage == "ERROR" && (
              <div className='depositmanager-error'>
                <span className='depositmanager-text05'>
                  <span>ERROR</span>
                  <br></br>
                  <br></br>
                  <span> TRY AGAIN</span>
                </span>
                <svg viewBox='0 0 1024 1024' className='depositmanager-icon2'>
                  <path d='M874.040 149.96c-96.706-96.702-225.28-149.96-362.040-149.96s-265.334 53.258-362.040 149.96c-96.702 96.706-149.96 225.28-149.96 362.040s53.258 265.334 149.96 362.040c96.706 96.702 225.28 149.96 362.040 149.96s265.334-53.258 362.040-149.96c96.702-96.706 149.96-225.28 149.96-362.040s-53.258-265.334-149.96-362.040zM896 512c0 82.814-26.354 159.588-71.112 222.38l-535.266-535.268c62.792-44.758 139.564-71.112 222.378-71.112 211.738 0 384 172.262 384 384zM128 512c0-82.814 26.354-159.586 71.112-222.378l535.27 535.268c-62.794 44.756-139.568 71.11-222.382 71.11-211.738 0-384-172.262-384-384z'></path>
                </svg>
                <Link to={"/entity/" + entity_uid} style={{ width: "100%" }}>
                  <button className='depositmanager-button3 button'>RESTART</button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Depositmanager;
