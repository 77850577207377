import React from "react";
import { Link } from "react-router-dom";
import { truncate } from "../../services/utilities";

import "./historic_push_item.css";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const Historicpushitem = (props) => {
  let _d = new Date(props.data.expiration);
  let exp = "UNTIL: " + _d.toISOString().split("T")[0];

  return (
    <Link to={"/push/" + props.data.id} style={{ width: "100%", maxWidth: "600px" }}>
      <div className='historicpushitem-historicpushitem'>
        <div className='historicpushitem-container'></div>
        <div className='historicpushitem-container1'>
          <div className='historicpushitem-container2'>
            <div className='historicpushitem-container3'>
              <span className='historicpushitem-text'>{exp}</span>
            </div>
            <span className='historicpushitem-text1'>{truncate(props.data.description, 25)}</span>
            <span className='historicpushitem-text2'>{numberWithCommas(props.data.amount) + " VT"}</span>
            <span className='historicpushitem-text3'>   </span>
            <span className='historicpushitem-text4'>{"Distributed: " + numberWithCommas(props.data.distributed) + " VT"}</span>
          </div>
        </div>
        <div className='historicpushitem-container4'></div>
      </div>
    </Link>
  );
};

export default Historicpushitem;
