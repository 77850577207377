import React, { useState, useEffect } from "react";
import { useQuery } from "urql";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";
import { useWindowSize } from "react-use";

import { Helmet } from "react-helmet";

import storageService from "../../services/storage";

import Cbmcolorfulbar from "../components/cbmcolorfulbar";
import Mainaccount from "../components/mainaccount";
import Expenseaccount from "../components/expenseaccount";
import Profitaccount from "../components/profitaccount";
import Taxaccount from "../components/taxaccount";
import Cashaccount from "../components/cashaccount";

let access_point = storageService.getStorageData("access_point");

import Header from "../components/header";

import "./cbm_accounting.css";

const getacc = `query allArea{
    statsPageStats {
      fundsStatistics
      dailyFlow
      mainAccount
      taxAccount
      expenseAccount
      profitAccount
      safeAccount  
      bankAccount  
    }
    }
  `;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }
  render() {
    if (this.state.errorInfo) {
      return <div></div>;
    }
    return this.props.children;
  }
}

export function numerizeFloat(value) {
  return [value["timestamp"], +value["value"]];
}

const Cbmaccounting = (props) => {
  let dta = JSON.parse(storageService.getStorageData("fps")).fronPageStats;

  let bal = [[0, 0]];
  //bal = props.data.getMadruhamSummary.tsBalance;

  //bal.push({ timestamp: props.data.getMadruhamSummary.ts, value: props.data.getMadruhamSummary.balance });

  bal = bal.map(numerizeFloat);

  let tmp = [];
  if (bal.length == 0) {
    tmp = [[1000, 0.1]];
  } else {
    tmp = bal;
  }
  let apex_data = [{ data: tmp }];

  let { width, height } = useWindowSize();
  if (width > 565) {
    width = 565;
  } else {
    width = width - 35;
  }

  let apex_options = {
    colors: ["#0293cdff"],
    yaxis: {
      labels: {
        show: true,
        formatter: function (val) {
          return ~~val;
        },
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    chart: {
      id: "area-datetime",
      type: "area",
      animations: {
        enabled: false,
        dynamicAnimation: {
          enabled: false,
        },
      },
      zoom: {
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
      tooltip: {
        enabled: false,
        show: false,
      },
    },

    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
      show: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0.0,
        stops: [0, 100],
        gradientToColors: ["#0293cdff"],
      },
    },
  };

  let _F = [];
  let _G = [];

  const [result, reexecuteQuery] = useQuery({
    query: getacc,
    requestPolicy: "cache-and-network",
  });

  const { data, fetching, error } = result;

  if (data) {
    _F.push(
      <div className='cbmaccounting-container2'>
        <Mainaccount data={data.statsPageStats.mainAccount}></Mainaccount>
        <Expenseaccount data={data.statsPageStats.expenseAccount}></Expenseaccount>
        <Profitaccount data={data.statsPageStats.profitAccount}></Profitaccount>
        <Taxaccount data={data.statsPageStats.taxAccount}></Taxaccount>
      </div>
    );
  }

  if (data) {
    _G.push(
      <div className='cbmaccounting-container2'>
        <Cashaccount data={data.statsPageStats.safeAccount} widgetType='SAFE'></Cashaccount>
        <Cashaccount data={data.statsPageStats.bankAccount} widgetType='BANK'></Cashaccount>
      </div>
    );
  }

  return (
    <div className='cbmaccounting-container'>
      <Helmet>
        <title>Accounting - CBM-UI</title>
        <meta property='og:title' content='Accounting - CBM-UI' />
      </Helmet>
      <Header></Header>
      <div className='cbmaccounting-body'>
        <div className='cbmaccounting-maincontainer'>
          {dta.isHealthy == false && (
            <div className='pushscreen-systemwarning'>
              <span className='pushscreen-text'>FINANCIAL SYSTEM IS DOWN! CONTACT POST OFFICE IT.</span>
            </div>
          )}

          <Cbmcolorfulbar rootClassName='cbmcolorfulbar-root-class-name2'></Cbmcolorfulbar>

          <div className='cbmaccounting-container1'>
            <ErrorBoundary>
              <Chart options={apex_options} series={apex_data} type='area' height='100%' width={width} />
            </ErrorBoundary>
          </div>
          <span className='cbmaccounting-text1'>DIGITAL</span>
          {_F}
          <span className='cbmaccounting-text2'>CASH</span>
          {_G}
        </div>
      </div>
    </div>
  );
};

export default Cbmaccounting;
