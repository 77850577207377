import React from "react";

import "./ledger_listitem.css";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const Ledgerlistitem = (props) => {
  return (
    <div className='ledgerlistitem-ledgerlistitem'>
      <span className='ledgerlistitem-text'>{props.name}</span>
      <span className='ledgerlistitem-text1'>{props.balance}</span>
      <span className='ledgerlistitem-text2'>{props.account_no}</span>
    </div>
  );
};

export default Ledgerlistitem;
