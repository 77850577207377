import React from "react";
import { Link } from "react-router-dom";

import "./cbmcolorfulbar.css";
import storageService from "../../services/storage";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const Cbmcolorfulbar = (props) => {
  let dta = null;

  if (props.data == undefined) {
    dta = JSON.parse(storageService.getStorageData("fps")).fronPageStats;
  } else {
    dta = props.data;
  }

  return (
    <div className={`cbmcolorfulbar-cbmcolorfulbar`}>
      <div className='cbmcolorfulbar-topbar'>
        <a href='/' className='cbmcolorfulbar-link'>
          <div className='cbmcolorfulbar-container'>
            <span className='cbmcolorfulbar-text'>{dta.noOfPushes}</span>
            <svg viewBox='0 0 1024 1024' className='cbmcolorfulbar-icon'>
              <path
                d='M725.333 298.667h153.003l-302.336 302.336-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0l-320 320c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l289.835-289.835 183.168 183.168c16.683 16.683 43.691 16.683 60.331 0l332.501-332.501v153.003c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-5.803-1.152-11.307-3.243-16.341s-5.163-9.728-9.216-13.781c-0.043-0.043-0.043-0.043-0.085-0.085-3.925-3.925-8.619-7.083-13.781-9.216-5.035-2.091-10.539-3.243-16.341-3.243h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z'
                className=''></path>
            </svg>
          </div>
        </a>
        <a href='/users' className='cbmcolorfulbar-link1'>
          <div className='cbmcolorfulbar-container1'>
            <span className='cbmcolorfulbar-text1'>{dta.didCount}</span>
            <svg viewBox='0 0 1024 1024' className='cbmcolorfulbar-icon2'>
              <path
                d='M768 896v-85.333c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464h-341.333c-58.88 0-112.299 23.936-150.869 62.464s-62.464 91.989-62.464 150.869v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-85.333c0-35.371 14.293-67.285 37.504-90.496s55.125-37.504 90.496-37.504h341.333c35.371 0 67.285 14.293 90.496 37.504s37.504 55.125 37.504 90.496v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667zM597.333 298.667c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464-112.299 23.936-150.869 62.464-62.464 91.989-62.464 150.869 23.936 112.299 62.464 150.869 91.989 62.464 150.869 62.464 112.299-23.936 150.869-62.464 62.464-91.989 62.464-150.869zM512 298.667c0 35.371-14.293 67.285-37.504 90.496s-55.125 37.504-90.496 37.504-67.285-14.293-90.496-37.504-37.504-55.125-37.504-90.496 14.293-67.285 37.504-90.496 55.125-37.504 90.496-37.504 67.285 14.293 90.496 37.504 37.504 55.125 37.504 90.496zM1024 896v-85.333c-0.043-53.12-19.499-101.76-51.883-139.136-27.819-32.128-65.195-55.936-107.904-67.243-22.784-6.016-46.123 7.552-52.139 30.336s7.552 46.123 30.336 52.139c25.899 6.869 48.469 21.248 65.195 40.619 19.413 22.443 31.019 51.456 31.061 83.285v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667zM672.085 174.891c34.261 8.789 61.653 30.507 78.379 58.752s22.656 62.72 13.867 96.981c-7.509 29.312-24.533 53.589-47.147 70.485-13.397 10.027-28.8 17.493-45.355 21.803-22.784 5.973-36.437 29.312-30.421 52.096s29.312 36.437 52.096 30.421c27.179-7.125 52.565-19.413 74.795-36.011 37.675-28.16 66.219-68.821 78.72-117.675 14.592-57.045 4.693-114.731-23.125-161.621s-73.6-83.328-130.645-97.963c-22.827-5.845-46.080 7.936-51.925 30.763s7.936 46.080 30.763 51.925z'
                className=''></path>
            </svg>
          </div>
        </a>
        <a href='/departments' className='cbmcolorfulbar-link2'>
          <div className='cbmcolorfulbar-container2'>
            <span className='cbmcolorfulbar-text2'>{dta.storeCount}</span>
            <svg viewBox='0 0 1024 1024' className='cbmcolorfulbar-icon4'>
              <path
                d='M101.803 350.336c-10.069 7.851-16.469 20.011-16.469 33.664v469.333c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h597.333c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-469.333c-0.043-12.8-5.717-25.301-16.469-33.664l-384-298.667c-15.275-11.733-36.736-12.16-52.395 0zM682.667 896v-384c0-23.552-19.115-42.667-42.667-42.667h-256c-23.552 0-42.667 19.115-42.667 42.667v384h-128c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-448.469l341.333-265.472 341.333 265.472v448.469c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501zM426.667 896v-341.333h170.667v341.333z'
                className=''></path>
            </svg>
          </div>
        </a>
        <a href='/banking' className='cbmcolorfulbar-link3'>
          <div className='cbmcolorfulbar-container3'>
            <span className='cbmcolorfulbar-text3'>{numberWithCommas(dta.transactedValue) + " VT"}</span>
            <svg viewBox='0 0 1024 1024' className='cbmcolorfulbar-icon6'>
              <path
                d='M469.333 469.333h-64c-29.483 0-56.064-11.904-75.435-31.232s-31.232-45.952-31.232-75.435 11.904-56.064 31.232-75.435 45.952-31.232 75.435-31.232h64zM554.667 554.667h64c29.483 0 56.064 11.904 75.435 31.232s31.232 45.952 31.232 75.435-11.904 56.064-31.232 75.435-45.952 31.232-75.435 31.232h-64zM725.333 170.667h-170.667v-128c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v128h-64c-52.992 0-101.077 21.547-135.765 56.235s-56.235 82.773-56.235 135.765 21.547 101.077 56.235 135.765 82.773 56.235 135.765 56.235h64v213.333h-213.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h213.333v128c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-128h64c52.992 0 101.077-21.547 135.765-56.235s56.235-82.773 56.235-135.765-21.547-101.077-56.235-135.765-82.773-56.235-135.765-56.235h-64v-213.333h170.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667z'
                className=''></path>
            </svg>
          </div>
        </a>
      </div>

      <div className='cbmcolorfulbar-container04'>
        <Link to={"/cash_holders"}>
          <svg viewBox='0 0 1024 1024' className='cbmcolorfulbar-icon08'>
            <path
              d='M480 64c-265.096 0-480 214.904-480 480 0 265.098 214.904 480 480 480 265.098 0 480-214.902 480-480 0-265.096-214.902-480-480-480zM480 928c-212.078 0-384-171.922-384-384s171.922-384 384-384c212.078 0 384 171.922 384 384s-171.922 384-384 384zM512 512v-128h128v-64h-128v-64h-64v64h-128v256h128v128h-128v64h128v64h64v-64h128.002l-0.002-256h-128zM448 512h-64v-128h64v128zM576.002 704h-64.002v-128h64.002v128z'
              className=''></path>
          </svg>
        </Link>

        <Link to={"/cbm_accounting"}>
          <svg viewBox='0 0 1024 1024' className='cbmcolorfulbar-icon10'>
            <path
              d='M448 576v-448c-247.424 0-448 200.576-448 448s200.576 448 448 448 448-200.576 448-448c0-72.034-17.028-140.084-47.236-200.382l-400.764 200.382zM912.764 247.618c-73.552-146.816-225.374-247.618-400.764-247.618v448l400.764-200.382z'
              className=''></path>
          </svg>
        </Link>

        <Link to={"/cbm_settings"}>
          <svg viewBox='0 0 1024 1024' className='cbmcolorfulbar-icon12'>
            <path
              d='M933.79 610.25c-53.726-93.054-21.416-212.304 72.152-266.488l-100.626-174.292c-28.75 16.854-62.176 26.518-97.846 26.518-107.536 0-194.708-87.746-194.708-195.99h-201.258c0.266 33.41-8.074 67.282-25.958 98.252-53.724 93.056-173.156 124.702-266.862 70.758l-100.624 174.292c28.97 16.472 54.050 40.588 71.886 71.478 53.638 92.908 21.512 211.92-71.708 266.224l100.626 174.292c28.65-16.696 61.916-26.254 97.4-26.254 107.196 0 194.144 87.192 194.7 194.958h201.254c-0.086-33.074 8.272-66.57 25.966-97.218 53.636-92.906 172.776-124.594 266.414-71.012l100.626-174.29c-28.78-16.466-53.692-40.498-71.434-71.228zM512 719.332c-114.508 0-207.336-92.824-207.336-207.334 0-114.508 92.826-207.334 207.336-207.334 114.508 0 207.332 92.826 207.332 207.334-0.002 114.51-92.824 207.334-207.332 207.334z'
              className=''></path>
          </svg>
        </Link>

        <Link to={"/wallet/" + dta.cashAccount} className='cbmcolorfulbar-text04'>
          <span className='cbmcolorfulbar-text04'>{numberWithCommas(dta.myCashBalance) + " VT"}</span>
        </Link>
        <Link to={"/wallet/" + dta.cashAccount} className='cbmcolorfulbar-icon10'>
          <svg viewBox='0 0 1024 1024' className='cbmcolorfulbar-icon10'>
            <path d='M366 708l196-196-196-196 60-60 256 256-256 256z' className=''></path>
          </svg>
        </Link>
      </div>

      <div className='cbmcolorfulbar-bottombar'>
        <a href='/cbm_groups' className='cbmcolorfulbar-link1'>
          <div className='cbmcolorfulbar-container4'>
            <span className='cbmcolorfulbar-text4'>0</span>
          </div>
        </a>

        <div className='cbmcolorfulbar-container5'>
          <span className='cbmcolorfulbar-text5'>0</span>
        </div>

        <a href='/cbm_good_loans' className='cbmcolorfulbar-link1'>
          <div className='cbmcolorfulbar-container6'>
            <span className='cbmcolorfulbar-text6'>{dta.goodLoans}</span>
          </div>
        </a>
        <a href='/cbm_slow_loans' className='cbmcolorfulbar-link1'>
          <div className='cbmcolorfulbar-container7'>
            <span className='cbmcolorfulbar-text7'>{dta.slowLoans}</span>
          </div>
        </a>
        <a href='/cbm_bad_loans' className='cbmcolorfulbar-link1'>
          <div className='cbmcolorfulbar-container8'>
            <span className='cbmcolorfulbar-text8'>{dta.badLoans}</span>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Cbmcolorfulbar;
