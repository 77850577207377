import React from "react";
import { Link } from "react-router-dom";

import "./grouplistitem.css";

const Grouplistitem = (props) => {
  let _admin = "no admin set";
  try {
    _admin = props.data.administrator[0].name;
  } catch {}

  let _members = " Members";

  try {
    _members = props.data.member.length + _members;
  } catch {
    _members = "0" + _members;
  }

  return (
    <Link to={"/edit_group/" + props.data.id} style={{ width: "100%" }}>
      <div className='grouplistitem-grouplistitem'>
        <div className='grouplistitem-container'>
          <span className='grouplistitem-text'>{props.data.name}</span>
          <svg viewBox='0 0 1024 1024' className='grouplistitem-icon'>
            <path d='M250 176l92-90 426 426-426 426-92-90 338-336z'></path>
          </svg>
        </div>
        <span className='grouplistitem-text1'>{_admin}</span>
        <span className='grouplistitem-text2'>{_members}</span>
      </div>
    </Link>
  );
};

export default Grouplistitem;
