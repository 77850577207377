import React from "react";
import { useQuery } from "urql";
import { Helmet } from "react-helmet";
import storageService from "../../services/storage";
import Header from "../components/header";
import Newpush from "../components/newpush";

import "./finance.css";

const pool_query = `
    query allaidPush {

        allIsland(pageLength:1000){
            id
            name
        }

    }
  `;

const Finance = (props) => {
  let access_point = storageService.getStorageData("access_point");

  const [result, reexecuteQuery] = useQuery({
    url: access_point,
    query: pool_query,
    requestPolicy: "cache-and-network",
  });

  const { data, fetching, error } = result;

  let _F = [];

  if (data) {
    _F.push(<Newpush data={data} key='np01'></Newpush>);
  }

  return (
    <div className='finance-container'>
      <Helmet>
        <title>Finance - Vanuatu Post</title>
        <meta
          name='description'
          content='the business ready database, featuring field level streaming, IoT support, GraphQL, fine grained access control, in-memory graph database backend'
        />
        <meta property='og:title' content='Finanace - Vanuatu Post' />
        <meta
          property='og:description'
          content='the business ready database, featuring field level streaming, IoT support, GraphQL, fine grained access control, in-memory graph database backend'
        />
      </Helmet>
      <Header rootClassName='header-root-class-name15'></Header>

      <div className='finance-contentframe'>
        <div className='finance-contentcontainer'>
          <div className='finance-homepanel'>
            <div className='finance-leftpanel'>{_F}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Finance;
