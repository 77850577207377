import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Fab from "@mui/material/Fab";
import { useNavigate } from "react-router-dom";
import { Camera } from "react-camera-pro";
import { useMutation } from "urql";
import uuid from "react-uuid";
import { GeneratePassword } from "js-generate-password";
import storageService from "../../services/storage";
import { ToastContainer, toast } from "react-toastify";
import { dataURItoBlob } from "../../services/utilities";

import { blobToWebP } from "webp-converter-browser";

import "./kyc_edit_item.css";
import "react-toastify/dist/ReactToastify.css";

const SAVE_personalDetail = `
        mutation savedIDPersonalDetail($uid:ID!, $name:String!, $dob_year:Int!, $dob_month:Int! $dob_day:Int!, $gender:String!, $householdHead:Boolean!) {
          savedIDPersonalDetail(data:[{id:$uid, name:$name, dobYear:$dob_year, dobMonth:$dob_month, dobDay:$dob_day, gender:$gender, householdHead:$householdHead, status:"APPROVED"}]) {
            id
          }
        }
      `;

const RECORD_EMAIL = `
        mutation registerEmail($email:String!, $entity:String!) {
          registerEmail(email:$email, entity:$entity) {
            id
          }
        }
      `;

const RECORD_PHONE = `
        mutation registerPhone($phone:String!, $entity:String!) {
          registerPhone(phone:$phone, entity:$entity) {
            id
          }
        }
      `;

const SAVE_enhancedDetails = `
        mutation savedIDEnhancedDetails($uid:ID!, $school_level:String!, $in_relationship:String!, $single_parent:Boolean!, $no_of_kids:Int!, $work_status:String!, $workplace:String!, $have_garden:Boolean!, $handicapped:String!, $relative_handicapped:String!, $occupation:ID!) {
          savedIDEnhancedDetails(data:[{id:$uid, schoolLevel:$school_level, relationshipStatus:$in_relationship, singleParent:$single_parent, noOfKids:$no_of_kids, workStatus:$work_status, workplace:$workplace, haveGarden:$have_garden, haveHandicap:$handicapped, relativeHasHandicap:$relative_handicapped, occupation:{id:$occupation},status:"APPROVED"}]) {
            id
          }
        }
      `;

const SAVE_PASSWORD = `
        mutation changeIDPassword($did_uid:ID!, $password:String!) {
          changeIDPassword(id:$did_uid, password:$password) {
            message
            uid
          }
        }
      `;

let db_name = "";
try {
  db_name = storageService.getStorageData("access_point").split("/")[2].split(".")[0];
} catch {}

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const zeroPad = (num, places) => String(num).padStart(places, "0");

const KYCEditItem = (props) => {
  let history = useNavigate();

  let SAVE_PROFILE = `
        mutation saveAdminFile($uid:ID!, $mime_type:String!, $path:String!) {
          saveAdminFile(data:[{id:$uid, mimeType:$mime_type, path:$path, profileBacklink:{id:"<profile_uid>"}, digitalIdBacklink:{id:"<did_uid>"}}]) {
            id
          }
        }
      `;

  try {
    SAVE_PROFILE = SAVE_PROFILE.replace("<profile_uid>", props.data.entity.profiles[0].id);
  } catch {}
  SAVE_PROFILE = SAVE_PROFILE.replace("<did_uid>", props.data.id);

  let SAVE_contactDetail = `
        mutation savedIDContactDetail($uid:ID!, $location:String!) {
          savedIDContactDetail(data:[{id:$uid, location:$location, island:{id: "<island>"}, status:"APPROVED"}]) {
            id
          }
        }
      `;

  const [fabon, setfabon] = useState(true);
  const [fixeropen, setfixeropen] = useState(false);
  const [cameradirection, setcameradirection] = useState("environment");
  const [legacyfile, setlegacyfile] = useState("");

  const camera = useRef(null);
  const [image, setImage] = useState(null);
  const [showprogress, setShowProgress] = useState(false);

  const [result_pd, save_pd] = useMutation(SAVE_personalDetail);
  const [rec_em, save_rec_em] = useMutation(RECORD_EMAIL);
  const [rec_pho, save_rec_pho] = useMutation(RECORD_PHONE);
  const [result_ed, save_ed] = useMutation(SAVE_enhancedDetails);
  const [result_prof, save_profile] = useMutation(SAVE_PROFILE);
  const [result_pasa, save_password] = useMutation(SAVE_PASSWORD);

  const kycstatus = props.status;

  let id_status = props.id_s;
  let personaldetails = props.pd_s;
  let contactdetails = props.cd_s;
  let enhanceddetails = props.ed_s;

  let tmp_reasoning = "";
  if (props.status == "APPROVED") {
    try {
      tmp_reasoning = props.data.personalDetail.name;
    } catch {
      tmp_reasoning = "You can start editing KYC data";
    }
  } else {
    tmp_reasoning = props.reasoning;
  }
  const reasoning = tmp_reasoning;
  const [page_to_show, setPage_To_Show] = useState(props.page_to_show);

  const [name, setName] = useState(props.data.personalDetail.name);
  let tmp_date =
    zeroPad(props.data.personalDetail.dobYear, 4) +
    "-" +
    zeroPad(props.data.personalDetail.dobMonth, 2) +
    "-" +
    zeroPad(props.data.personalDetail.dobDay, 2);
  const [dob, setDOB] = useState(tmp_date);
  const [gender, setGender] = useState(props.data.personalDetail.gender);

  let tmp_phone_01 = "";
  try {
    tmp_phone_01 = props.data.entity.phone[0].phone;
    tmp_phone_01 = tmp_phone_01.split("+678")[1];
  } catch {}
  const [phone01, setPhone01] = useState(tmp_phone_01);

  let tmp_phone_02 = "";
  try {
    tmp_phone_02 = props.data.entity.phone[1].phone;
    tmp_phone_02 = tmp_phone_02.split("+678")[1];
  } catch {}
  const [phone02, setPhone02] = useState(tmp_phone_02);

  let tmp_email = "";
  try {
    tmp_email = props.data.entity.email[0].email;
  } catch {}
  const [email, setEmail] = useState(tmp_email);

  let tmp_sorted_islands = [];

  props.islands.map((island) => {
    tmp_sorted_islands.push(island.name);
  });

  tmp_sorted_islands = tmp_sorted_islands.sort();

  let sorted_islands = [];
  let efate_id = "";
  tmp_sorted_islands.map((island) => {
    let tmp_i_s = {};
    props.islands.map((isl) => {
      if (island == isl.name) {
        tmp_i_s = { id: isl.id, name: isl.name };
      }
      if (efate_id == "") {
        if (isl.name == "Efate") {
          efate_id = isl.id;
        }
      }
    });
    sorted_islands.push(tmp_i_s);
  });

  let tmp_island = efate_id;
  try {
    tmp_island = props.data.contactDetail.island.id;
  } catch {}
  const [island, setIsland] = useState(tmp_island);
  const [location, setLocation] = useState(props.data.contactDetail.location);

  SAVE_contactDetail = SAVE_contactDetail.replace("<island>", island);
  const [result_cd, save_cd] = useMutation(SAVE_contactDetail);

  const [school, setSchool] = useState(props.data.enhancedDetails.schoolLevel);
  let tmp_relationship = props.data.enhancedDetails.inRelationship;

  const [relationship, setRelationship] = useState(tmp_relationship);

  let tmp_rel_status = "SINGLE";
  try {
    tmp_rel_status = props.data.enhancedDetails.relationshipStatus;
  } catch {}
  const [relationshipStatus, setRelationshipStatus] = useState(tmp_rel_status);

  let tmp_single = props.data.enhancedDetails.singleParent;

  const [singleparent, setSingleParent] = useState(tmp_single);

  const [no_of_kids, setNoOfKids] = useState(props.data.enhancedDetails.noOfKids);
  const [workstatus, setWorkStatus] = useState(props.data.enhancedDetails.workStatus);
  const [workplace, setWorkplace] = useState(props.data.enhancedDetails.workplace);
  let tmp_garden = props.data.enhancedDetails.haveGarden;

  let tmp_hh = false;
  try {
    tmp_hh = props.data.personalDetail.householdHead;
  } catch {}

  if (tmp_hh == undefined) {
    tmp_hh = false;
  }

  let tmp_occup = "";
  try {
    tmp_occup = props.data.enhancedDetails.occupation.id;
  } catch {}
  const [occupation, setOccupation] = useState(tmp_occup);

  let occup_data = [];

  props.occupations.map((occ) => {
    occup_data.push({ value: occ.id, label: occ.occupation });
  });

  const [havegarden, setHaveGarden] = useState(tmp_garden);

  const [householdHead, setHouseholdHead] = useState(tmp_hh);

  let tmp_handicapped = props.data.enhancedDetails.handicapped;

  const [handicapped, setHandicapped] = useState(tmp_handicapped);

  let tmp_han_status = "NO";
  try {
    if (props.data.enhancedDetails.haveHandicap) {
      tmp_han_status = props.data.enhancedDetails.haveHandicap;
    }
  } catch {}
  const [haveHandicap, setHaveHandicap] = useState(tmp_han_status);

  let tmp_relhan_status = "NO";
  try {
    if (props.data.enhancedDetails.relativeHasHandicap) {
      tmp_relhan_status = props.data.enhancedDetails.relativeHasHandicap;
    }
  } catch {}
  const [relativeHasHandicap, setrelativeHasHandicaR] = useState(tmp_relhan_status);

  if (props.page_to_show == "PASSWORD_SELECTOR") {
  } else {
    try {
      storageService.removeStorageData("tmpp_" + props.data.id);
    } catch {}
  }

  let password_user = storageService.getStorageData("tmpp_" + props.data.id);
  if (password_user) {
  } else {
    password_user = "";
  }
  const generate_password = () => {
    const new_password = GeneratePassword({
      length: 8,
      symbols: false,
      uppercase: false,
      minLengthLowercase: 2,
      minLengthNumbers: 5,
    });
    password_user = new_password;
    save_password({
      did_uid: props.data.id,
      password: new_password,
    }).then((result) => {
      if (result.error) {
        toast.error("Some mistake happen, try again please.");
        console.error("Oh no!", result.error);
      } else {
        storageService.setStorageData("tmpp_" + props.data.id, new_password);
        //history.push("/edit_details/" + props.data.id_no.id + "/PASSWORD_SELECTOR");
      }
    });
  };

  const swap_rel = () => {
    if (relationship == true) {
      setRelationship(false);
    } else {
      setRelationship(true);
    }
  };

  const swap_singl = () => {
    if (singleparent == true) {
      setSingleParent(false);
    } else {
      setSingleParent(true);
    }
  };

  const swap_gard = () => {
    if (havegarden == true) {
      setHaveGarden(false);
    } else {
      setHaveGarden(true);
    }
  };

  const swap_hh = () => {
    if (householdHead == true) {
      setHouseholdHead(false);
    } else {
      setHouseholdHead(true);
    }
  };

  const swap_handi = () => {
    if (handicapped == true) {
      setHandicapped(false);
    } else {
      setHandicapped(true);
    }
  };

  const [pwd_method, setPWD_method] = useState("ON_SCREEN");

  let avatar_img = "/playground_assets/image_placeholder.png";
  try {
    if (props.path == "") {
    } else {
      avatar_img = process.env.REACT_APP_STORAGE_READ_HOST + "/" + props.data.entity.profiles[0].avatar.path;
    }
  } catch {
    try {
      if (props.path == "") {
      } else {
        avatar_img = process.env.REACT_APP_STORAGE_READ_HOST + "/" + props.path;
      }
    } catch {}
  }

  const switch_personal = (event) => {
    event.preventDefault();
    setPage_To_Show("PERSONAL_DETAIL");
  };

  // saving function of the personal details tab
  const mutate_personalDetails = (event) => {
    event.preventDefault();

    let _dob_year = parseInt(dob.split("-")[0], 10);
    let _dob_month = parseInt(dob.split("-")[1], 10);
    let _dob_day = parseInt(dob.split("-")[2], 10);
    let _hh = householdHead;

    if (props.data.personalDetail.id == "") {
    } else {
      save_pd({
        uid: props.data.personalDetail.id,
        name: name,
        dob_year: _dob_year,
        dob_month: _dob_month,
        dob_day: _dob_day,
        gender: gender,
        householdHead: _hh,
      }).then((result) => {
        if (result.error) {
          toast.error("Some mistake happen, try again please.");
          console.error("Oh no!", result.error);
        } else {
          history("/edit_details/" + props.data.idNo.id + "/CONTACT_DETAIL");
          setPage_To_Show("CONTACT_DETAIL");
        }
      });
    }
  };

  // saving function of the contact details tab
  const mutate_contactDetails = (event) => {
    event.preventDefault();

    if (tmp_email == email.trim()) {
    } else {
      if (email.trim() == "") {
      } else {
        if (props.data.entity.id == "") {
        } else {
          save_rec_em({
            email: email.trim(),
            entity: props.data.entity.id,
          }).then((result) => {
            if (result.error) {
              toast.error("Some mistake happen while recording the email");
              console.error("Oh no!", result.error);
            } else {
            }
          });
        }
      }
    }

    if (tmp_phone_01 == phone01.trim()) {
    } else {
      if (phone01.trim() == "") {
      } else {
        if (props.data.entity.id == "") {
        } else {
          save_rec_pho({
            phone: "+678" + phone01.trim(),
            entity: props.data.entity.id,
          }).then((result) => {
            if (result.error) {
              toast.error("Some mistake happen while recording the phone01");
              console.error("Oh no!", result.error);
            } else {
            }
          });
        }
      }
    }

    if (tmp_phone_02 == phone02.trim()) {
    } else {
      if (phone02.trim() == "") {
      } else {
        if (props.data.entity.id == "") {
        } else {
          save_rec_pho({
            phone: "+678" + phone02.trim(),
            entity: props.data.entity.id,
          }).then((result) => {
            if (result.error) {
              toast.error("Some mistake happen while recording the phone02");
              console.error("Oh no!", result.error);
            } else {
            }
          });
        }
      }
    }

    if (location == "") {
      toast.error("Must fill out the location field");
    } else {
      if (props.data.contactDetail.id == "") {
      } else {
        save_cd({
          uid: props.data.contactDetail.id,
          location: location,
        }).then((result) => {
          if (result.error) {
            toast.error("Some mistake happen, try again please.");
            console.error("Oh no!", result.error);
          } else {
            history("/edit_details/" + props.data.idNo.id + "/ENHANCED_DETAILS");
            setPage_To_Show("ENHANCED_DETAILS");
          }
        });
      }
    }
  };

  // saving function of the enhanced details tab
  const mutate_enhancedDetails = (event) => {
    event.preventDefault();

    let _in_relationship = relationshipStatus;

    let _single_parent = singleparent;

    let _have_garden = havegarden;

    let _handicapped = haveHandicap;

    let _relative_handicapped = relativeHasHandicap;

    let _occupation = occupation;

    if (props.data.contactDetail.id == "") {
    } else {
      save_ed({
        uid: props.data.enhancedDetails.id,
        school_level: school,
        in_relationship: _in_relationship,
        single_parent: _single_parent,
        no_of_kids: +no_of_kids,
        work_status: workstatus,
        workplace: workplace,
        have_garden: _have_garden,
        handicapped: _handicapped,
        relative_handicapped: _relative_handicapped,
        occupation: _occupation,
      }).then((result) => {
        if (result.error) {
          toast.error("Some mistake happen, try again please.");
          console.error("Oh no!", result.error);
        } else {
          history("/edit_details/" + props.data.idNo.id + "/PROFILE_PICTURE");
          setPage_To_Show("PROFILE_PICTURE");
        }
      });
    }
  };

  //selecting password panel
  const mutate_change_password = (event) => {
    event.preventDefault();
    setPage_To_Show("PROFILE_PICTURE");
  };

  const goback_to_password = (event) => {
    event.preventDefault();
    setPage_To_Show("PASSWORD_SELECTOR");
  };

  const goback_to_personal = (event) => {
    event.preventDefault();
    setPage_To_Show("PERSONAL_DETAILS");
  };

  const goback_to_contact = (event) => {
    event.preventDefault();
    setPage_To_Show("CONTCT_DETAILS");
  };

  const goback_to_enhanced = (event) => {
    event.preventDefault();
    setPage_To_Show("ENHANCED_DETAILS");
  };

  const goback_to_home = (event) => {
    event.preventDefault();
    history("/kyc_info/" + props.data.id);
  };

  const switchfixer = () => {
    if (fixeropen == true) {
      setfixeropen(false);
      setfabon(true);
    } else {
      setfixeropen(true);
      setfabon(false);
    }
  };

  const switchcamera = () => {
    if (cameradirection == "environment") {
      setcameradirection("user");
      camera.current.switchCamera();
    } else {
      setcameradirection("environment");
      camera.current.switchCamera();
    }
  };

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      setfixeropen(false);
      setImage(imageDataUrl);
    }
  };

  return (
    <div className='kycedititem-kycedititem'>
      <ToastContainer
        position='bottom-center'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />

      {page_to_show != "PROFILE_PICTURE" && (
        <div className='kycedititem-k-cheader'>
          <img alt='image' src={avatar_img} className='kycedititem-image' />
          <div className='kycedititem-container'>
            <div className='kycedititem-container01'>
              <span className='kycedititem-text'>{reasoning}</span>
              <div className='kycedititem-container02'>
                {kycstatus == "RECEIVED" && <span className='kycedititem-waiting'>WAITING...</span>}
                {kycstatus == "PROCESSING" && <span className='kycedititem-processing'>PROCESSING...</span>}
                {kycstatus == "APPROVED" && <span className='kycedititem-registered'>IMAGE APPROVED</span>}
                {kycstatus == "REJECTED" && <span className='kycedititem-invalid'>INVALID</span>}
              </div>
              <div className='kycedititem-container03'>
                {kycstatus != "APPROVED" && kycstatus != "PROCESSING" && kycstatus != "REJECTED" && (
                  <svg viewBox='0 0 1024 1024' className='kycedititem-imagegrey'>
                    <path d='M362 576l-148 192h596l-192-256-148 192zM896 810q0 34-26 60t-60 26h-596q-34 0-60-26t-26-60v-596q0-34 26-60t60-26h596q34 0 60 26t26 60v596z'></path>
                  </svg>
                )}
                {kycstatus == "APPROVED" && (
                  <svg viewBox='0 0 1024 1024' className='kycedititem-imagegreen'>
                    <path d='M362 576l-148 192h596l-192-256-148 192zM896 810q0 34-26 60t-60 26h-596q-34 0-60-26t-26-60v-596q0-34 26-60t60-26h596q34 0 60 26t26 60v596z'></path>
                  </svg>
                )}{" "}
                {kycstatus == "PROCESSING" && (
                  <svg viewBox='0 0 1024 1024' className='kycedititem-imageorange'>
                    <path d='M362 576l-148 192h596l-192-256-148 192zM896 810q0 34-26 60t-60 26h-596q-34 0-60-26t-26-60v-596q0-34 26-60t60-26h596q34 0 60 26t26 60v596z'></path>
                  </svg>
                )}
                {kycstatus == "REJECTED" && (
                  <svg viewBox='0 0 1024 1024' className='kycedititem-imagered'>
                    <path d='M362 576l-148 192h596l-192-256-148 192zM896 810q0 34-26 60t-60 26h-596q-34 0-60-26t-26-60v-596q0-34 26-60t60-26h596q34 0 60 26t26 60v596z'></path>
                  </svg>
                )}
                {personaldetails != "APPROVED" && personaldetails != "RECEIVED" && personaldetails != "REJECTED" && (
                  <svg viewBox='0 0 1024 1024' className='kycedititem-detgrey'>
                    <path d='M512 298v-84h-384v84h384zM512 470v-86h-384v86h384zM896 128q34 0 60 26t26 60v596q0 34-26 60t-60 26h-768q-34 0-60-26t-26-60v-596q0-34 26-60t60-26h768z'></path>
                  </svg>
                )}
                {personaldetails == "APPROVED" && (
                  <svg viewBox='0 0 1024 1024' className='kycedititem-detgreen'>
                    <path d='M512 298v-84h-384v84h384zM512 470v-86h-384v86h384zM896 128q34 0 60 26t26 60v596q0 34-26 60t-60 26h-768q-34 0-60-26t-26-60v-596q0-34 26-60t60-26h768z'></path>
                  </svg>
                )}
                {personaldetails == "RECEIVED" && (
                  <svg viewBox='0 0 1024 1024' className='kycedititem-detorange'>
                    <path d='M512 298v-84h-384v84h384zM512 470v-86h-384v86h384zM896 128q34 0 60 26t26 60v596q0 34-26 60t-60 26h-768q-34 0-60-26t-26-60v-596q0-34 26-60t60-26h768z'></path>
                  </svg>
                )}
                {personaldetails == "REJECTED" && (
                  <svg viewBox='0 0 1024 1024' className='kycedititem-detred'>
                    <path d='M512 298v-84h-384v84h384zM512 470v-86h-384v86h384zM896 128q34 0 60 26t26 60v596q0 34-26 60t-60 26h-768q-34 0-60-26t-26-60v-596q0-34 26-60t60-26h768z'></path>
                  </svg>
                )}
                {contactdetails != "REJECTED" && contactdetails != "RECEIVED" && contactdetails != "APPROVED" && (
                  <svg viewBox='0 0 1024 1024' className='kycedititem-contgrey'>
                    <path d='M762 598q-16-42-16-86t16-86h70l64-84-84-86q-36 28-71 78t-47 92-12 86 12 86 47 92 71 78l84-86-64-84h-70zM598 768v-42q0-58-88-95t-168-37-168 37-88 95v42h512zM342 256q-52 0-90 38t-38 90 38 90 90 38 90-38 38-90-38-90-90-38zM938 128q34 0 60 26t26 60v596q0 34-26 60t-60 26h-852q-34 0-60-26t-26-60v-596q0-34 26-60t60-26h852z'></path>
                  </svg>
                )}
                {contactdetails == "APPROVED" && (
                  <svg viewBox='0 0 1024 1024' className='kycedititem-contgreen'>
                    <path d='M762 598q-16-42-16-86t16-86h70l64-84-84-86q-36 28-71 78t-47 92-12 86 12 86 47 92 71 78l84-86-64-84h-70zM598 768v-42q0-58-88-95t-168-37-168 37-88 95v42h512zM342 256q-52 0-90 38t-38 90 38 90 90 38 90-38 38-90-38-90-90-38zM938 128q34 0 60 26t26 60v596q0 34-26 60t-60 26h-852q-34 0-60-26t-26-60v-596q0-34 26-60t60-26h852z'></path>
                  </svg>
                )}
                {contactdetails == "RECEIVED" && (
                  <svg viewBox='0 0 1024 1024' className='kycedititem-contorange'>
                    <path d='M762 598q-16-42-16-86t16-86h70l64-84-84-86q-36 28-71 78t-47 92-12 86 12 86 47 92 71 78l84-86-64-84h-70zM598 768v-42q0-58-88-95t-168-37-168 37-88 95v42h512zM342 256q-52 0-90 38t-38 90 38 90 90 38 90-38 38-90-38-90-90-38zM938 128q34 0 60 26t26 60v596q0 34-26 60t-60 26h-852q-34 0-60-26t-26-60v-596q0-34 26-60t60-26h852z'></path>
                  </svg>
                )}
                {contactdetails == "REJECTED" && (
                  <svg viewBox='0 0 1024 1024' className='kycedititem-contred'>
                    <path d='M762 598q-16-42-16-86t16-86h70l64-84-84-86q-36 28-71 78t-47 92-12 86 12 86 47 92 71 78l84-86-64-84h-70zM598 768v-42q0-58-88-95t-168-37-168 37-88 95v42h512zM342 256q-52 0-90 38t-38 90 38 90 90 38 90-38 38-90-38-90-90-38zM938 128q34 0 60 26t26 60v596q0 34-26 60t-60 26h-852q-34 0-60-26t-26-60v-596q0-34 26-60t60-26h852z'></path>
                  </svg>
                )}
                {enhanceddetails != "REJECTED" && enhanceddetails != "RECEIVED" && enhanceddetails != "APPROVED" && (
                  <svg viewBox='0 0 1024 1024' className='kycedititem-enhgrey'>
                    <path d='M896 810v-128h-768v128h768zM896 128q34 0 60 26t26 60v596q0 34-26 60t-60 26h-768q-34 0-60-26t-26-60v-596q0-34 26-60t60-26h768z'></path>
                  </svg>
                )}
                {enhanceddetails == "APPROVED" && (
                  <svg viewBox='0 0 1024 1024' className='kycedititem-enhgreen'>
                    <path d='M896 810v-128h-768v128h768zM896 128q34 0 60 26t26 60v596q0 34-26 60t-60 26h-768q-34 0-60-26t-26-60v-596q0-34 26-60t60-26h768z'></path>
                  </svg>
                )}
                {enhanceddetails == "RECEIVED" && (
                  <svg viewBox='0 0 1024 1024' className='kycedititem-enhorange'>
                    <path d='M896 810v-128h-768v128h768zM896 128q34 0 60 26t26 60v596q0 34-26 60t-60 26h-768q-34 0-60-26t-26-60v-596q0-34 26-60t60-26h768z'></path>
                  </svg>
                )}
                {enhanceddetails == "REJECTED" && (
                  <svg viewBox='0 0 1024 1024' className='kycedititem-enhred'>
                    <path d='M896 810v-128h-768v128h768zM896 128q34 0 60 26t26 60v596q0 34-26 60t-60 26h-768q-34 0-60-26t-26-60v-596q0-34 26-60t60-26h768z'></path>
                  </svg>
                )}
              </div>

              {props.data.idNo.status == "APPROVED" &&
              props.data.personalDetail.status == "APPROVED" &&
              props.data.contactDetail.status == "APPROVED" &&
              props.data.enhancedDetails.status == "APPROVED" ? (
                <div className='kycedititem-multibut'>
                  <button
                    className='kycedititem-buttonpwd button'
                    type='button'
                    onClick={() => {
                      history("/edit_details/" + props.data.idNo.id + "/PASSWORD_SELECTOR");
                    }}>
                    <span className='kycedititem-text01'>PWD</span>
                    <svg viewBox='0 0 1024 1024' className='kycedititem-icon'>
                      <path d='M1002.132 314.242l-101.106-101.104c-24.792-24.794-65.37-65.368-90.162-90.164l-101.106-101.104c-24.792-24.794-68.954-29.166-98.13-9.716l-276.438 184.292c-29.176 19.452-40.218 61.028-24.536 92.39l70.486 140.974c2.154 4.306 4.646 8.896 7.39 13.66l-356.53 356.53-32 224h192v-64h128v-128h128v-128h128v-71.186c6.396 3.812 12.534 7.216 18.192 10.044l140.97 70.488c31.366 15.682 72.94 4.638 92.39-24.538l184.294-276.44c19.454-29.172 15.078-73.33-9.714-98.126zM150.628 854.626l-45.254-45.254 311.572-311.57 45.254 45.254-311.572 311.57zM917.020 423.764l-45.256 45.256c-12.446 12.444-32.808 12.444-45.254 0l-271.53-271.53c-12.446-12.444-12.446-32.81 0-45.254l45.256-45.256c12.446-12.444 32.808-12.444 45.254 0l271.53 271.53c12.446 12.444 12.446 32.81 0 45.254z'></path>
                    </svg>
                  </button>

                  <button type='button' className='kycedititem-button07 button' onClick={goback_to_home}>
                    Cancel
                  </button>

                  <button className='kycedititem-button button' type='button' onClick={switch_personal}>
                    <span className='kycedititem-text01'>Edit</span>
                    <svg viewBox='0 0 1024 1024' className='kycedititem-icon'>
                      <path d='M864 0c88.364 0 160 71.634 160 160 0 36.020-11.91 69.258-32 96l-64 64-224-224 64-64c26.742-20.090 59.978-32 96-32zM64 736l-64 288 288-64 592-592-224-224-592 592zM715.578 363.578l-448 448-55.156-55.156 448-448 55.156 55.156z'></path>
                    </svg>
                  </button>
                </div>
              ) : (
                <>
                  {props.status == "APPROVED" && (
                    <button className='kycedititem-button01 button' type='button' onClick={switch_personal}>
                      <span className='kycedititem-text02'>Start</span>
                      <svg viewBox='0 0 1024 1024' className='kycedititem-icon02'>
                        <path d='M342 214l468 298-468 298v-596z'></path>
                      </svg>
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {kycstatus != "APPROVED" && kycstatus != "REJECTED" && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {page_to_show == "PASSWORDCHANGER" && (
        <div className='kycedititem-passwordchange'>
          <span className='kycedititem-text03'>Change Password</span>
          <div className='kycedititem-container04'>
            <span className='kycedititem-text04'>Change Password and show it to the client on screen</span>
            <button className='kycedititem-button02 button'>SCREEN</button>
          </div>
          <div className='kycedititem-container05'>
            <span className='kycedititem-text05'>Send new password by PHONE</span>
            <button className='kycedititem-button03 button'>PHONE</button>
          </div>
          <div className='kycedititem-container06'>
            <span className='kycedititem-text06'>Send new password by EMAIL</span>
            <button className='kycedititem-button04 button'>SCREEN</button>
          </div>
        </div>
      )}

      <div className='kycedititem-detailedkycform'>
        {page_to_show == "PERSONAL_DETAIL" && (
          <form className='kycedititem-page01' onSubmit={mutate_personalDetails} id='pd_form'>
            <input
              type='text'
              required
              autoComplete='name'
              placeholder='name'
              className='kycedititem-textinput input'
              pattern="^(?!_$)(?!['-._])(?!.*[_.-]{2})[a-zA-Z0-9 '_.-]+(?<!['.-])$"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <div className='kycedititem-container07'>
              <span className='kycedititem-text11'>Date of Birth</span>

              <input
                type='date'
                required
                autoComplete='bday'
                className='kycedititem-textinput1 input'
                pattern='\d{4}-\d{2}-\d{2}'
                value={dob}
                onChange={(e) => {
                  setDOB(e.target.value);
                }}
              />
            </div>
            <select
              className='kycedititem-select'
              value={gender}
              onChange={(e) => {
                setGender(e.target.value);
              }}>
              <option value='MAN'>MAN</option>
              <option value='WOMAN'>WOMAN</option>
            </select>

            <div className='kycedititem-container17'>
              <span className='kycedititem-text15'>is household head</span>
              <div className='kycedititem-container18'>
                <input type='checkbox' checked={householdHead} onChange={swap_hh} />
              </div>
            </div>

            <div className='kycedititem-container08'>
              <button type='button' className='kycedititem-button07 button' onClick={goback_to_home}>
                Cancel
              </button>
              <div className='kycedititem-container09'>
                <svg viewBox='0 0 1024 1024' className='kycedititem-icon04'>
                  <path d='M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z'></path>
                </svg>
                <svg viewBox='0 0 1024 1024' className='kycedititem-icon06'>
                  <path d='M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z'></path>
                </svg>
                <svg viewBox='0 0 1024 1024' className='kycedititem-icon08'>
                  <path d='M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z'></path>
                </svg>
                <svg viewBox='0 0 1024 1024' className='kycedititem-icon10'>
                  <path d='M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z'></path>
                </svg>
                <svg viewBox='0 0 1024 1024' className='kycedititem-icon12'>
                  <path d='M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z'></path>
                </svg>
              </div>
              <button type='submit' className='kycedititem-button08 button'>
                NEXT &gt;
              </button>
            </div>
          </form>
        )}
        {page_to_show == "CONTACT_DETAIL" && (
          <form className='kycedititem-page02' onSubmit={mutate_contactDetails} id='cd_form'>
            <input
              type='tel'
              maxLength='7'
              minLength='7'
              autoComplete='tel'
              placeholder='phone01'
              className='kycedititem-textinput2 input'
              pattern='^[57][0-9]{6}$'
              value={phone01}
              onChange={(e) => {
                setPhone01(e.target.value);
              }}
            />
            <input
              type='tel'
              maxLength='7'
              minLength='7'
              autoComplete='tel'
              placeholder='phone02'
              className='kycedititem-textinput3 input'
              pattern='^[57][0-9]{6}$'
              value={phone02}
              onChange={(e) => {
                setPhone02(e.target.value);
              }}
            />
            <input
              type='email'
              autoComplete='email'
              placeholder='email'
              className='kycedititem-textinput4 input'
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <select
              className='kycedititem-select1'
              value={island}
              onChange={(e) => {
                setIsland(e.target.value);
              }}>
              {sorted_islands.map((island) => {
                return (
                  <option value={island.id} key={island.id}>
                    {island.name}
                  </option>
                );
              })}
            </select>
            <textarea
              placeholder='describe where you live'
              className='kycedititem-textarea textarea'
              value={location}
              onChange={(e) => {
                setLocation(e.target.value);
              }}></textarea>
            <div className='kycedititem-container10'>
              <button type='button' className='kycedititem-button09 button' onClick={goback_to_personal}>
                &lt; Back
              </button>
              <div className='kycedititem-container11'>
                <svg viewBox='0 0 1024 1024' className='kycedititem-icon14'>
                  <path d='M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z'></path>
                </svg>
                <svg viewBox='0 0 1024 1024' className='kycedititem-icon16'>
                  <path d='M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z'></path>
                </svg>
                <svg viewBox='0 0 1024 1024' className='kycedititem-icon18'>
                  <path d='M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z'></path>
                </svg>
                <svg viewBox='0 0 1024 1024' className='kycedititem-icon20'>
                  <path d='M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z'></path>
                </svg>
                <svg viewBox='0 0 1024 1024' className='kycedititem-icon22'>
                  <path d='M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z'></path>
                </svg>
              </div>
              <button type='submit' className='kycedititem-button10 button'>
                NEXT &gt;
              </button>
            </div>
          </form>
        )}
        {page_to_show == "ENHANCED_DETAILS" && (
          <form className='kycedititem-page03' onSubmit={mutate_enhancedDetails} id='ed_form'>
            <select
              className='kycedititem-select2'
              value={school}
              onChange={(e) => {
                setSchool(e.target.value);
              }}>
              <option value='NO_SCHOOL'>NO_SCHOOL</option>
              <option value='PRIMARY'>PRIMARY</option>
              <option value='SECONDARRY'>SECONDARRY</option>
              <option value='HIGHSCHOOL'>HIGHSCHOOL</option>
              <option value='DIPLOMA'>DIPLOMA</option>
              <option value='POST_GRADUATE'>POST_GRADUATE</option>
            </select>

            <select
              className='kycedititem-select2'
              value={relationshipStatus}
              onChange={(e) => {
                setRelationshipStatus(e.target.value);
              }}>
              <option value='SINGLE'>Single</option>
              <option value='DE-FACTO'>De-Facto</option>
              <option value='MARRIED'>Married</option>
              <option value='DIVORCED'>Divorced</option>
              <option value='WIDOWED'>Widowed</option>
            </select>

            <div className='kycedititem-container14'>
              <span className='kycedititem-text13'>single parent</span>
              <div className='kycedititem-container15'>
                <input type='checkbox' checked={singleparent} onChange={swap_singl} />
              </div>
            </div>
            <div className='kycedititem-container16'>
              <span className='kycedititem-text14'>no. of kids</span>
              <input
                type='number'
                max='100'
                min='0'
                step='1'
                placeholder='0'
                className='kycedititem-textinput5 input'
                value={no_of_kids}
                onChange={(e) => {
                  setNoOfKids(e.target.value);
                }}
              />
            </div>
            <select
              className='kycedititem-select3'
              value={workstatus}
              onChange={(e) => {
                setWorkStatus(e.target.value);
              }}>
              <option value='NO_WORK'>NO_WORK</option>
              <option value='GOVERNMENT'>GOVERNMENT</option>
              <option value='PRIVATE'>PRIVATE</option>
            </select>

            <select
              className='kycedititem-select1'
              value={occupation}
              onChange={(e) => {
                setOccupation(e.target.value);
              }}>
              {props.occupations.map((occup) => {
                return (
                  <option value={occup.id} key={occup.id}>
                    {occup.occupation}
                  </option>
                );
              })}
            </select>

            <input
              type='text'
              autoComplete='organization'
              placeholder='workplace'
              className='kycedititem-textinput6 input'
              value={workplace}
              onChange={(e) => {
                setWorkplace(e.target.value);
              }}
            />
            <div className='kycedititem-container17'>
              <span className='kycedititem-text15'>have a garden</span>
              <div className='kycedititem-container18'>
                <input type='checkbox' checked={havegarden} onChange={swap_gard} />
              </div>
            </div>

            <div className='kycedititem-container17'>
              <span className='kycedititem-text15'>living with disability?</span>
            </div>

            <select
              className='kycedititem-select2'
              value={haveHandicap}
              onChange={(e) => {
                setHaveHandicap(e.target.value);
              }}>
              <option value='NO'>NO Disabilities</option>
              <option value='VISION'>Vision</option>
              <option value='HEARING'>Hearing</option>
              <option value='MOBILITY'>Mobility</option>
              <option value='COGNITION'>Cognition</option>
              <option value='SELFCARE'>Self-Care</option>
              <option value='COMMUNICATION'>Communication</option>
            </select>

            <div className='kycedititem-container17'>
              <span className='kycedititem-text15'>someone in your house living with disability?</span>
            </div>

            <select
              className='kycedititem-select2'
              value={relativeHasHandicap}
              onChange={(e) => {
                setrelativeHasHandicaR(e.target.value);
              }}>
              <option value='NO'>NO in Family Disabilities</option>
              <option value='VISION'>Vision</option>
              <option value='HEARING'>Hearing</option>
              <option value='MOBILITY'>Mobility</option>
              <option value='COGNITION'>Cognition</option>
              <option value='SELFCARE'>Self-Care</option>
              <option value='COMMUNICATION'>Communication</option>
            </select>

            <div className='kycedititem-container21'>
              <button type='button' className='kycedititem-button11 button' onClick={goback_to_contact}>
                &lt; Back
              </button>
              <div className='kycedititem-container22'>
                <svg viewBox='0 0 1024 1024' className='kycedititem-icon24'>
                  <path d='M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z'></path>
                </svg>
                <svg viewBox='0 0 1024 1024' className='kycedititem-icon26'>
                  <path d='M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z'></path>
                </svg>
                <svg viewBox='0 0 1024 1024' className='kycedititem-icon28'>
                  <path d='M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z'></path>
                </svg>
                <svg viewBox='0 0 1024 1024' className='kycedititem-icon30'>
                  <path d='M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z'></path>
                </svg>
                <svg viewBox='0 0 1024 1024' className='kycedititem-icon32'>
                  <path d='M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z'></path>
                </svg>
              </div>
              <button type='submit' className='kycedititem-button12 button'>
                NEXT &gt;
              </button>
            </div>
          </form>
        )}
        {page_to_show == "PASSWORD_SELECTOR" && (
          <div className='kycedititem-page04' id='ps_form'>
            <div className='kycedititem-showpassword'>
              <span className='kycedititem-text07'>Generate Password</span>
              {password_user == "" ? (
                <button className='kycedititem-button15 button' onClick={generate_password}>
                  GENERATE
                </button>
              ) : (
                <span className='kycedititem-text08'>{password_user}</span>
              )}
              <span> </span>
            </div>
            <div className='kycedititem-container23'>
              <button type='button' className='kycedititem-button13 button' onClick={goback_to_home}>
                Cancel
              </button>
              <div className='kycedititem-container24'>
                <svg viewBox='0 0 1024 1024' className='kycedititem-icon34'>
                  <path d='M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z'></path>
                </svg>
                <svg viewBox='0 0 1024 1024' className='kycedititem-icon36'>
                  <path d='M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z'></path>
                </svg>
                <svg viewBox='0 0 1024 1024' className='kycedititem-icon38'>
                  <path d='M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z'></path>
                </svg>
                <svg viewBox='0 0 1024 1024' className='kycedititem-icon42'>
                  <path d='M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z'></path>
                </svg>
                <svg viewBox='0 0 1024 1024' className='kycedititem-icon40'>
                  <path d='M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z'></path>
                </svg>
              </div>
              <button type='button' className='kycedititem-button14 button' onClick={goback_to_home}>
                Finish
              </button>
            </div>
          </div>
        )}

        {(kycstatus == "RECEIVED") | (kycstatus == "PROCESSING") ? (
          <div className='kycedititem-page04' id='ps_form'>
            <div className='kycedititem-showpassword'>
              <button
                className='kycedititem-button15 button'
                onClick={() => {
                  window.location.reload();
                }}>
                REFRESH
              </button>
            </div>
            <div className='kycedititem-container23'></div>
          </div>
        ) : (
          <></>
        )}

        {page_to_show == "PROFILE_PICTURE" && (
          <form className='kycedititem-page05' id='pp_form'>
            <span className='kycedititem-text18'>Take a profile picture of the applicant</span>
            {image ? (
              <div>
                <img src={image} className='uploadid-uploadid' style={{ width: "100%", height: "auto" }} />
                {showprogress && (
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress />
                  </Box>
                )}
              </div>
            ) : (
              <Camera ref={camera} facingMode={cameradirection} aspectRatio={1 / 1} className='uploadid-uploadid' />
            )}

            <div className='registar-camerafixer'>
              <div className='registar-headlinecamerafixer' onClick={switchfixer}>
                <span className='registar-camerafixertext'>Fix Camera issues</span>
                {fixeropen == false && (
                  <svg viewBox='0 0 1024 1024' className='registar-openicon'>
                    <path d='M366 708l196-196-196-196 60-60 256 256-256 256z'></path>
                  </svg>
                )}
                {fixeropen == true && (
                  <svg viewBox='0 0 1024 1024' className='registar-closeicon'>
                    <path d='M316 658l-60-60 256-256 256 256-60 60-196-196z'></path>
                  </svg>
                )}
              </div>
              {fixeropen == true && (
                <div className='registar-fixcameracollapsible'>
                  <div className='registar-fixcamerarefreshcontainer'>
                    <span className='registar-fixcamerarefreshtext'>1, Refresh the page -&gt;</span>
                    <button
                      className='registar-refreshfixcamera button'
                      onClick={() => {
                        window.location.reload();
                      }}>
                      REFRESH
                    </button>
                  </div>
                  <div className='registar-fixcameraswitchcontainer'>
                    <span className='registar-fixcameraswitchtext'>2, Try to Switch the Camera Direction -&gt;</span>
                    <button className='registar-switchfixedcamera button' onClick={switchcamera}>
                      SWITCH
                    </button>
                  </div>

                  <div className='registar-fixcamerauploadcontainer'>
                    <span className='registar-fixcamerauploadtext'>3, Select an Image</span>
                    <div className='registar-fixcamerauploadform'>
                      <input
                        type='file'
                        placeholder='select a picture'
                        className='registar-fixcamerauploadinput input'
                        accept='image/*'
                        onChange={onFileChange}
                      />
                      <svg viewBox='0 0 1024 1024' className='registar-uploadfixcamera'>
                        <path d='M362 214l214 298-214 298h-192l214-298-214-298h192zM662 214l212 298-212 298h-192l212-298-212-298h192z'></path>
                      </svg>
                    </div>
                  </div>

                  <div className='registar-fixcameraexplanationcontainer'>
                    <span className='registar-fixcameraexplanationtext'>
                      If your camera is permanently not switching on or blocked, you may forget to authorize Camera Access when you first
                      used the application. Please go to your Browsers SETTINGS -&gt; Site Settings -&gt; Camera to authorize it, than
                      refresh the page.
                    </span>
                  </div>
                </div>
              )}
            </div>

            {image ? (
              <div className='kycedititem-container25'>
                <button
                  type='button'
                  className='kycedititem-button15 button'
                  onClick={() => {
                    if (showprogress == false) {
                      setImage(null);
                    }
                  }}>
                  New Picture
                </button>

                <button
                  type='button'
                  className='kycedititem-button16 button'
                  onClick={async () => {
                    if (showprogress == false) {
                      var cf = dataURItoBlob(image);

                      setShowProgress(true);

                      const webpBlob = await blobToWebP(cf);

                      const options = {
                        method: "PUT",
                        headers: {
                          AccessKey: process.env.REACT_APP_STORAGE_ACCESSKEY,
                          "content-type": "application/octet-stream",
                        },
                        body: webpBlob,
                      };

                      const img_uid = uuid();
                      const pathprepare = "/" + db_name + "/Profile/" + props.data.entity.profiles[0].id + "/avatar/" + img_uid;

                      const uriimg = process.env.REACT_APP_STORAGE_BASE_HOST + pathprepare + ".webp";

                      fetch(uriimg, options)
                        .then((response) => response.json())
                        .then((response) => {
                          save_profile({ uid: img_uid, mime_type: "image/webp", path: pathprepare + ".webp" }).then((result) => {
                            setShowProgress(false);

                            try {
                              history("/edit_details/" + props.data.idNo.id + "/PASSWORD_SELECTOR");
                            } catch {
                              toast.error("Some error happened..");
                            }
                          });
                        })
                        .catch((err) => {
                          console.error(err);
                          toast.error("Upload error..");
                        });
                    }
                  }}>
                  Submit Picture
                </button>
              </div>
            ) : (
              <>
                {fabon == true && (
                  <Fab
                    color='primary'
                    aria-label='camera'
                    size='large'
                    style={{ position: "fixed", bottom: "50px" }}
                    onClick={() => {
                      let current_photo = camera.current.takePhoto();
                      setImage(current_photo);
                    }}>
                    <svg viewBox='0 0 1024 1024' className='floatinccameraicon'>
                      <path d='M1024 810.667v-469.333c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-147.84l-72.661-109.013c-7.765-11.52-20.736-18.987-35.499-18.987h-256c-13.909 0.085-27.307 6.741-35.499 18.987l-72.661 109.013h-147.84c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v469.333c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h768c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496zM938.667 810.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-768c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-469.333c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c14.763 0 27.733-7.467 35.499-18.987l72.661-109.013h210.347l72.661 109.013c8.192 12.245 21.589 18.901 35.499 18.987h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165zM725.333 554.667c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464-112.299 23.936-150.869 62.464-62.464 91.989-62.464 150.869 23.936 112.299 62.464 150.869 91.989 62.464 150.869 62.464 112.299-23.936 150.869-62.464 62.464-91.989 62.464-150.869zM640 554.667c0 35.371-14.293 67.285-37.504 90.496s-55.125 37.504-90.496 37.504-67.285-14.293-90.496-37.504-37.504-55.125-37.504-90.496 14.293-67.285 37.504-90.496 55.125-37.504 90.496-37.504 67.285 14.293 90.496 37.504 37.504 55.125 37.504 90.496z'></path>
                    </svg>
                  </Fab>
                )}
              </>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default KYCEditItem;
