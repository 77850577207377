import React from "react";

import ImageGallery from "react-image-gallery";
import { useNavigate } from "react-router-dom";

import "./kyc_content.css";

const KycContent = (props) => {
  let history = useNavigate();

  let hde = "FALSE";

  try {
    if (props.hide) {
      hde = props.hide;
    }
  } catch {}

  console.log(hde);

  const images = [];

  let id_image = "";
  try {
    if (props.data.getdID.idImage.path == "") {
    } else {
      id_image = process.env.REACT_APP_STORAGE_READ_HOST + "/" + props.data.getdID.idImage[0].path;
    }
  } catch {}

  images.push({ original: id_image, thumbnail: id_image, originalWidth: "600px", thumbnailWidth: "100px" });

  let profile_image = "";
  try {
    if (props.data.getdID.entity.profiles[0].avatar.path == "") {
    } else {
      profile_image = process.env.REACT_APP_STORAGE_READ_HOST + "/" + props.data.getdID.entity.profiles[0].avatar.path;
    }
  } catch {}

  let emails = "";
  let email_count = 0;

  try {
    props.data.getdID.entity.email.map((email) => {
      if (email_count == 0) {
        emails = email.email;
        email_count = email_count + 1;
      } else {
        emails = emails + ", " + email.email;
        email_count = email_count + 1;
      }
    });
  } catch {}

  try {
    props.data.getdID.entity.confirmedEmail.map((email) => {
      if (email_count == 0) {
        emails = email.email;
        email_count = email_count + 1;
      } else {
        emails = emails + ", " + email.email;
        email_count = email_count + 1;
      }
    });
  } catch {}

  if (email_count == 0) {
    emails = " ";
  }

  let phones = "";
  let phone_count = 0;

  try {
    props.data.getdID.entity.phone.map((phone) => {
      if (phone_count == 0) {
        phones = phone.phone;
        phone_count = phone_count + 1;
      } else {
        phones = phones + ", " + phone.phone;
        phone_count = phone_count + 1;
      }
    });
  } catch {}

  try {
    props.data.getdID.entity.confirmedPhone.map((phone) => {
      if (phone_count == 0) {
        phones = phone.phone;
        phone_count = phone_count + 1;
      } else {
        phones = phones + ", " + phone.phone;
        phone_count = phone_count + 1;
      }
    });
  } catch {}

  if (phone_count == 0) {
    phones = " ";
  }

  let tmp_image;
  ("");

  try {
    props.data.getdID.additionalImage.map((thisimage) => {
      tmp_image = process.env.REACT_APP_STORAGE_READ_HOST + "/" + thisimage.path;
      images.push({ original: tmp_image, thumbnail: tmp_image, originalWidth: "600px", thumbnailWidth: "100px" });
    });
  } catch {}

  const openeditor = () => {
    history("/edit_details/" + props.data.getdID.idNo.id + "/NONE");
  };

  return (
    <div className='kyc-content-kyc-content'>
      <div className='kyc-content-activecontainer'>
        <div className='kyc-content-activepushitem'>
          <div className='kyc-content-container'>
            <div className='kyc-content-container1'>
              <div className='kyc-content-container2'></div>
              <div className='kyc-content-container3'>
                {(props.data.getdID.personalDetail.householdHead == true) | (props.data.getdID.personalDetail.householdHead == null) ? (
                  <svg viewBox='0 0 1024 1024' className='kyc-content-icon'>
                    <path d='M1024 590.444l-512-397.426-512 397.428v-162.038l512-397.426 512 397.428zM896 576v384h-256v-256h-256v256h-256v-384l384-288z'></path>
                  </svg>
                ) : (
                  <></>
                )}
                <img src={profile_image} alt='image' className='kyc-content-image1' />
                <div className='kyc-content-container4'>
                  <span className='kyc-content-text1'>{props.data.getdID.personalDetail.name}</span>
                  <span className='kyc-content-text2'>{"idNo: " + props.data.getdID.idNo.idNo}</span>
                  <span className='kyc-content-text3'>{phones}</span>
                  <span className='kyc-content-text4'>{emails}</span>
                  <span className='kyc-content-text5'>
                    {"DOB: " +
                      props.data.getdID.personalDetail.dobYear +
                      "-" +
                      props.data.getdID.personalDetail.dobMonth +
                      "-" +
                      props.data.getdID.personalDetail.dobDay}
                  </span>
                  <span className='kyc-content-text6'>{props.data.getdID.contactDetail.island.name}</span>
                  <span className='kyc-content-text7'>{props.data.getdID.contactDetail.location}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {hde == "FALSE" && (
        <>
          <div className='kyc-content-historiccontainer'></div>
          <div className='kyc-content-container5'>
            <button type='button' className='kyc-content-button button' onClick={openeditor}>
              EDIT KYC
            </button>
          </div>

          <div className='kyc-content-imagelibrary'>
            <ImageGallery items={images} showPlayButton={false} additionalClass='kyc-content-imagelibrary' thumbnailPosition='left' />
          </div>
        </>
      )}
    </div>
  );
};

export default KycContent;
