import React, { useState, useEffect } from "react";
import { useQuery } from "urql";

import EditMemeberAdd from "../components/edit_memeber_add";

import "./search_and_add.css";

const search_profile = `
    query graphSearchProfileWithDigitalID($name:String!) {
      graphSearchProfileWithDigitalID(name:$name) {
        id
        name
        entity {
            id
        }
        avatar {
          id
          path
        }      
      }
    }
  `;

const Searchandadd = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [searchInputInput, setSearchInputInput] = useState("");

  var _usersearch = [];

  const slength = () => {
    if (searchInput.trim().length < 3) {
      return true;
    } else {
      return false;
    }
  };

  const shouldPause = slength() === true;
  const [result, reexecuteQuery] = useQuery({
    query: search_profile,
    variables: { name: searchInput.trim() },
    requestPolicy: "cache-and-network",
    pause: shouldPause,
  });
  const { data, fetching, error } = result;

  if (error) {
    _usersearch = [];
    _usersearch.push();
  }

  if (fetching) {
    _usersearch = [];
    _usersearch.push();
  }

  if (data) {
    try {
      _usersearch = [];
      _usersearch.push();

      data.graphSearchProfileWithDigitalID.map((emp) => {
        _usersearch.push(
          <EditMemeberAdd
            name={emp.name}
            avatar={emp.avatar}
            key={emp.id}
            profile_id={emp.id}
            mutation={null}
            reexecute={reexecuteQuery}
            group_uid={props.group_uid}
            widget_type={props.widget_type}></EditMemeberAdd>
        );
      });
    } catch {}
  } else {
    _usersearch = [];
  }

  useEffect(() => {
    _usersearch = [];

    if (searchInput.trim().length > 2) {
      reexecuteQuery();
    } else {
      _usersearch = null;
    }
  }, [searchInput]);

  return (
    <div className='searchandadd-searchandadd'>
      <span className='searchandadd-text'>{props.widget_type}</span>
      <input
        type='text'
        placeholder='search for administrators'
        className='searchandadd-textinput input'
        value={searchInputInput}
        onChange={(e) => {
          setSearchInput(e.target.value.trim());
          setSearchInputInput(e.target.value);
        }}
      />
      <div className='searchandadd-searchadminresult'>{_usersearch}</div>
    </div>
  );
};

export default Searchandadd;
