import React, { useState } from "react";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";
import { useWindowSize } from "react-use";
import { useQuery, useMutation } from "urql";

import { getNumberUnit } from "../../services/utilities";

import "./mycashlistitem.css";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function numerizeFloat(value) {
  return [value["timestamp"], +value["value"]];
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }
  render() {
    if (this.state.errorInfo) {
      return <div></div>;
    }
    return this.props.children;
  }
}

let markgql = `
        mutation submitMark($missing:Int!) {
          submitMark(missing: $missing)
        }
      `;

const Mycashlistitem = (props) => {
  const [box, setBox] = useState(false);
  const [mar_result, submit_mark] = useMutation(markgql);
  const [missing_value, set_missing_value] = useState(null);

  let avatar_img = "";
  try {
    if (props.data.avatar == "") {
    } else {
      avatar_img = process.env.REACT_APP_STORAGE_READ_HOST + "/" + props.data.avatar;
    }
  } catch {}

  const switch_box = () => {
    if (box == true) {
      setBox(false);
    } else {
      setBox(true);
    }
  };

  let bal = [[100000, 0]];
  bal = props.data.ts_balance;

  bal.push({ timestamp: props.data.ts, value: props.data.balance });

  bal = bal.map(numerizeFloat);

  let tmp = [];
  if (bal.length == 0) {
    tmp = [[1000, 0.1]];
  } else {
    tmp = bal;
  }
  let apex_data = [{ data: tmp }];

  let { width, height } = useWindowSize();
  if (width > 545) {
    width = 545;
  } else {
    width = width - 55;
  }

  let apex_options = {
    colors: ["#0293cdff"],
    yaxis: {
      labels: {
        show: true,
        formatter: function (val) {
          return getNumberUnit(~~val);
        },
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    chart: {
      id: "area-datetime",
      type: "area",
      animations: {
        enabled: false,
        dynamicAnimation: {
          enabled: false,
        },
      },
      zoom: {
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
      tooltip: {
        enabled: false,
        show: false,
      },
    },

    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
      show: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0.0,
        stops: [0, 100],
        gradientToColors: ["#0293cdff"],
      },
    },
  };

  const mark_no_issues = () => {
    submit_mark({ missing: 0 });
    window.location.reload();
  };

  const mark_issue_missing = () => {
    if (missing_value == null) {
      submit_mark({ missing: 0 });
    } else {
      submit_mark({ missing: -missing_value });
    }
    window.location.reload();
  };

  const mark_issue_over = () => {
    if (missing_value == null) {
      submit_mark({ missing: 0 });
    } else {
      submit_mark({ missing: ~~missing_value });
    }
    window.location.reload();
  };

  return (
    <div className={`mycashlistitem-entitylistitem`}>
      <div className='mycashlistitem-container'>
        <ErrorBoundary>
          <Chart options={apex_options} series={apex_data} type='area' height='100%' width={width} style={{ maxHeight: "150px" }} />
        </ErrorBoundary>
        <Link to={"/wallet/" + props.data.accountNo}>
          <svg viewBox='0 0 1024 1024' className='mycashlistitem-icon'>
            <path d='M512 170l342 342-342 342-60-60 238-240h-520v-84h520l-238-240z' className=''></path>
          </svg>
        </Link>
      </div>
      <div className='mycashlistitem-container1'>
        <Avatar src={avatar_img} size='50' name={props.data.name} className='mycashlistitem-image' />

        <div className='mycashlistitem-container2'>
          <span className='mycashlistitem-text'>{props.data.name}</span>
          <span className='mycashlistitem-text1'>{numberWithCommas(props.data.balance) + " VT"}</span>
          <span className='mycashlistitem-text2'>{numberWithCommas(props.data.missing) + " VT"}</span>
        </div>
        <svg viewBox='0 0 1024 1024' className='mycashlistitem-icon2' onClick={switch_box}>
          <path
            d='M554 554v-256h-84v256h84zM512 738q22 0 39-17t17-39-17-38-39-16-39 16-17 38 17 39 39 17zM672 128l224 224v320l-224 224h-320l-224-224v-320l224-224h320z'
            className=''></path>
        </svg>
      </div>
      {box && (
        <div className='mycashlistitem-container3'>
          <div className='mycashlistitem-container4'>
            <button type='button' className='mycashlistitem-button button' onClick={mark_no_issues}>
              MY COUNT IS PERFECT
            </button>
          </div>
          <input
            type='number'
            min='1'
            step='1'
            required
            placeholder='report missing or above'
            className='mycashlistitem-textinput input'
            value={missing_value}
            onChange={(e) => {
              set_missing_value(e.target.value);
            }}
          />
          <div className='mycashlistitem-container5'>
            <button type='button' className='mycashlistitem-button1 button' onClick={mark_issue_missing}>
              <span className=''>
                <span className=''>MISSING</span>
                <br className=''></br>
              </span>
            </button>
            <button type='submit' className='mycashlistitem-button2 button' onClick={mark_issue_over}>
              OVER
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Mycashlistitem;
