import React, { useState } from "react";

import { Helmet } from "react-helmet";
import { useQuery, useMutation } from "urql";

import Header from "../components/header";
import Banktransferlist from "../components/banktransferlist";
import Cbmcolorfulbar from "../components/cbmcolorfulbar";
import storageService from "../../services/storage";

import "./banking.css";

const generate_mut = `
    mutation generateBankTransferList {
        generateBankTransferList
    }
  `;

const main_query_banking = `
    query allbankWithdrawal {
        allbankWithdrawal(filter: {status: "PROCESSED"}) {
                id
                export
                status
                timestamp
                bouncedTransactions {
                    id
                }
        }

    }
  `;

const Banking = (props) => {
  const [generate, setGenerate] = useState(true);

  const [mutresult, domutate] = useMutation(generate_mut);

  let dta = JSON.parse(storageService.getStorageData("fps")).fronPageStats;

  const [result, reexecuteQuery] = useQuery({
    query: main_query_banking,
    requestPolicy: "cache-and-network",
  });

  let _F = [];

  const { data, fetching, error } = result;

  if (data) {
    data.allbankWithdrawal.map((bw) => {
      _F.push(<Banktransferlist data={bw} key={bw.id} reexecute={reexecuteQuery}></Banktransferlist>);
    });
  }

  async function do_generate() {
    domutate();
    setGenerate(false);
    reexecuteQuery();
  }

  return (
    <div className='banking-container'>
      <Helmet>
        <title>Finance - Vanuatu Post</title>
        <meta property='og:title' content='Vanuatu post - Finance' />
      </Helmet>
      <Header rootClassName='header-root-class-name27'></Header>
      <div className='banking-contentframe'>
        <div className='banking-contentcontainer'>
          {dta.isHealthy == false && (
            <div className='pushscreen-systemwarning'>
              <span className='pushscreen-text'>FINANCIAL SYSTEM IS DOWN! CONTACT POST OFFICE IT.</span>
            </div>
          )}

          <Cbmcolorfulbar rootClassName='cbmcolorfulbar-root-class-name2'></Cbmcolorfulbar>

          <div className='bankingscreen-container1'>
            <span className='bankingscreen-text1'>Bank withdrawal list</span>
            <button type='button' className='bankingscreen-button button' onClick={do_generate}>
              Generate
            </button>
          </div>
          <div className='bankingscreen-searchcontent'>
            <div className='bankingscreen-activecontainer'>{_F}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banking;
