import React, { useState } from "react";
import { useQuery, useMutation } from "urql";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import "./business_settings.css";
import storageService from "../../services/storage";
import uuid from "react-uuid";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LedgerViewMini from "./ledger_view_mini";

let access_point = storageService.getStorageData("access_point");

const islaQuery = `query allIsland{
    allIsland(pageLength:100){
        id
        name
    }
    }
  `;

const bizRegMutation = `mutation vanuatuPostBusinessRegistration($business_id:ID!, $business_name:String!, $business_tin:String!, $business_type:String!, $business_island:ID!, $business_location:String!, $bank_id:ID!, $bank_name:String!, $bank_branch:String!, $bank_holder:String!, $bank_no:String!, $bank_sortcode:String!){
    vanuatuPostBusinessRegistration(departmentDetails:{ id:$business_id, name:$business_name, public:false,tinNo:$business_tin, businessType:$business_type, island:{id:$business_island}, location:$business_location }, bankDetails:{id:$bank_id, bankName:$bank_name, bankBranch:$bank_branch, accountHolderName:$bank_holder, accountNo:$bank_no, sortCode:$bank_sortcode}){
        message
        myDepartments
    }
    }
  `;

const BusinessSettings = (props) => {
  let history = useNavigate();

  let _business_id = uuid();
  let _business_name = "";
  let _business_tin = "";
  let _business_type = "";
  let _business_island = "";
  let _business_location = "";

  let _business_bank_id = uuid();
  let _business_bank_name = "NBV";
  let _business_bank_branch = "";
  let _business_bank_account_holder = "";
  let _business_bank_account_no = "";
  let _business_bank_sort_code = "";

  const [resultRegi, regBiz] = useMutation(bizRegMutation);

  const [result, reexecuteQuery] = useQuery({
    url: access_point,
    query: islaQuery,
    requestPolicy: "cache-and-network",
  });

  const { data, fetching, error } = result;

  let tmp_sorted_islands = [];
  let efate_id = "";
  let sorted_islands = [];

  if (data) {
    data.allIsland.map((island) => {
      tmp_sorted_islands.push(island.name);
    });

    tmp_sorted_islands = tmp_sorted_islands.sort();

    tmp_sorted_islands.map((island) => {
      let tmp_i_s = {};
      data.allIsland.map((isl) => {
        if (island == isl.name) {
          tmp_i_s = { id: isl.id, name: isl.name };
        }
        if (efate_id == "") {
          if (isl.name == "Efate") {
            efate_id = isl.id;
          }
        }
      });
      sorted_islands.push(tmp_i_s);
    });
  }

  _business_island = efate_id;

  if (props.data != null) {
    _business_id = props.data.id;
    _business_name = props.data.name;
    _business_tin = props.data.tinNo;
    try {
      _business_island = props.data.island.id;
    } catch {}
    _business_type = props.data.businessType;
    _business_location = props.data.location;
    try {
      _business_bank_id = props.data.bankDetails[0].id;
      _business_bank_name = props.data.bankDetails[0].bankName;
      _business_bank_branch = props.data.bankDetails[0].bankBranch;
      _business_bank_account_holder = props.data.bankDetails[0].accountHolderName;
      _business_bank_account_no = props.data.bankDetails[0].accountNo;
      _business_bank_sort_code = props.data.bankDetails[0].sortCode;
    } catch {}
  }

  const [business_name, set_business_name] = useState(_business_name);
  const [business_tin, set_business_tin] = useState(_business_tin);
  const [business_type, set_business_type] = useState(_business_type);
  const [business_island, set_business_island] = useState(_business_island);
  const [business_location, set_business_location] = useState(_business_location);
  const [business_bank_name, set_business_bank_name] = useState(_business_bank_name);
  const [business_bank_branch, set_business_bank_branch] = useState(_business_bank_branch);
  const [business_bank_account_holder, set_business_bank_account_holder] = useState(_business_bank_account_holder);
  const [business_bank_account_no, set_business_bank_account_no] = useState(_business_bank_account_no);
  const [business_bank_sort_code, set_business_bank_sort_code] = useState(_business_bank_sort_code);

  const regMutate = (event) => {
    event.preventDefault();
    regBiz({
      business_id: _business_id.trim(),
      business_name: business_name.trim(),
      business_tin: business_tin.trim(),
      business_type: business_type.trim(),
      business_location: business_location.trim(),
      business_island: business_island.trim(),
      bank_id: _business_bank_id.trim(),
      bank_name: business_bank_name.trim(),
      bank_branch: business_bank_branch.trim(),
      bank_holder: business_bank_account_holder.trim(),
      bank_no: business_bank_account_no.trim(),
      bank_sortcode: business_bank_sort_code.trim(),
    }).then((result) => {
      if (result.error) {
        console.error("Oh no!", result.error);
        toast.error("Some mistake happened..");
      } else {
        try {
          //props.reexecute();

          storageService.setStorageData("app_deps", JSON.stringify(result["data"]["vanuatuPostBusinessRegistration"]["myDepartments"]));
          try {
            storageService.setStorageData("working_dep", result["data"]["vanuatuPostBusinessRegistration"]["myDepartments"][0]["id"]);
            storageService.setStorageData("active_department", result["data"]["vanuatuPostBusinessRegistration"]["myDepartments"][0]["id"]);
          } catch {}

          history("/");
        } catch {
          toast.error("Some mistake happened..");
        }
      }
    });
  };

  return (
    <div className='businesssettings-businesssettings'>
      <ToastContainer
        position='bottom-left'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
      <Link to={"/department/" + props.data.id} key={"x-" + props.data.id} style={{ width: "100%" }}>
        <LedgerViewMini data={props.alldata} set_page={null} page={0}></LedgerViewMini>
      </Link>

      <form className='businesssettings-form' onSubmit={regMutate}>
        <div className='businesssettings-container'></div>
        <div className='ledgerview-balancestrip'>
          <span className='ledgerview-ledgerbalanetext'>Settings</span>
        </div>
        <input
          type='text'
          id='bname'
          name='bname'
          required
          placeholder='business name'
          minLength={4}
          pattern="^(?!_$)(?!['-._])(?!.*[_.-]{2})[a-zA-Z0-9 '_.-]+(?<!['.-])$"
          className='businesssettings-textinput input'
          value={business_name}
          onChange={(e) => {
            set_business_name(e.target.value);
          }}
        />
        <input
          type='text'
          id='tin'
          name='tin'
          required
          placeholder='TIN Number'
          minLength={4}
          className='businesssettings-textinput1 input'
          value={business_tin}
          onChange={(e) => {
            set_business_tin(e.target.value);
          }}
        />
        <select
          className='businesssettings-select'
          value={business_type}
          onChange={(e) => {
            set_business_type(e.target.value);
          }}>
          <option value='GENERICISLAND'>Generic Island Store</option>
          <option value='SUPMARKET'>Supermarket</option>
          <option value='CONVENIENCE'>Convenience Store</option>
          <option value='HARDWARE'>Hardware Store</option>
          <option value='PETROLSTATION'>Petrol Station</option>
          <option value='PHARMACY'>Pharmacy</option>
          <option value='CLOTHS'>Cloths Store</option>
          <option value='BOTTLE'>Bottle Shop</option>
          <option value='TRANSPORT'>Transportation</option>
        </select>

        <select
          className='businesssettings-select1'
          value={business_island}
          onChange={(e) => {
            set_business_island(e.target.value);
          }}>
          {sorted_islands.map((island) => {
            return (
              <option value={island.id} key={island.id}>
                {island.name}
              </option>
            );
          })}
        </select>

        <textarea
          placeholder='describe where you located'
          className='businesssettings-textarea textarea'
          value={business_location}
          onChange={(e) => {
            set_business_location(e.target.value);
          }}></textarea>
        <select
          required
          className='businesssettings-select2'
          value={business_bank_name}
          onChange={(e) => {
            set_business_bank_name(e.target.value);
          }}>
          <option value='NBV'>NBV</option>
          <option value='BRED'>BRED</option>
          <option value='BSP'>BSP</option>
          <option value='ANZ'>ANZ</option>
          <option value='WANFUTENG'>WANFUTENG</option>
        </select>
        <input
          type='text'
          id='bankbr'
          name='banbr'
          required
          placeholder='bank branch'
          className='businesssettings-textinput2 input'
          value={business_bank_branch}
          onChange={(e) => {
            set_business_bank_branch(e.target.value);
          }}
        />
        <input
          type='text'
          id='ahn'
          name='ahn'
          required
          placeholder='account holder name'
          className='businesssettings-textinput3 input'
          value={business_bank_account_holder}
          onChange={(e) => {
            set_business_bank_account_holder(e.target.value);
          }}
        />
        <input
          type='text'
          id='acno'
          name='acno'
          required
          placeholder='account number'
          className='businesssettings-textinput4 input'
          value={business_bank_account_no}
          onChange={(e) => {
            set_business_bank_account_no(e.target.value);
          }}
        />
        <input
          type='text'
          id='sortcode'
          name='sortcode'
          placeholder='sort code if apply'
          className='businesssettings-textinput5 input'
          value={business_bank_sort_code}
          onChange={(e) => {
            set_business_bank_sort_code(e.target.value);
          }}
        />
        <div className='businesssettings-container2'>
          <button type='submit' className='businesssettings-button button'>
            SAVE
          </button>
        </div>
      </form>
    </div>
  );
};

export default BusinessSettings;
