import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "urql";
import uuid from "react-uuid";

import "./cashaccount.css";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const trans_uid = uuid();

const transgql = `
        mutation submitBusinessCashTransfer($otherAccount:String!, $direction:String!, $amount:Int!, $transactionId:String!) {
          submitBusinessCashTransfer(otherAccount: $otherAccount, direction: $direction, amount: $amount, transactionId: $transactionId)
        }
      `;

const Cashaccount = (props) => {
  const [transfervalue, set_transfervalue] = useState(null);

  const [success, setSuccess] = useState(null);

  const [transferOn, setTransferOn] = useState(false);

  const [ct_result, cash_transfer] = useMutation(transgql);

  const switch_transfer = () => {
    if (transferOn == true) {
      setTransferOn(false);
    } else {
      setTransferOn(true);
    }
  };

  const add_amount = () => {
    if (transfervalue !== null) {
      if (transfervalue > 0) {
        cash_transfer({ amount: ~~transfervalue, otherAccount: props.widgetType, direction: "ADD", transactionId: trans_uid });
        window.location.reload();
      }
    }
  };

  const remove_amount = () => {
    if (transfervalue !== null) {
      if (transfervalue > 0) {
        cash_transfer({ amount: ~~transfervalue, otherAccount: props.widgetType, direction: "REMOVE", transactionId: trans_uid });
        window.location.reload();
      }
    }
  };

  return (
    <div className='cashaccount-cashaccount'>
      <div className='cashaccount-container'>
        <span className='cashaccount-text'>{props.widgetType}</span>
        <span className='cashaccount-text1'>{numberWithCommas(props.data.balance) + " VT"}</span>
      </div>
      <div className='cashaccount-container1'>
        <svg viewBox='0 0 1024 1024' className='cashaccount-icon' onClick={switch_transfer}>
          <path d='M86 682l170-170v128h640v86h-640v128zM938 342l-170 170v-128h-640v-86h640v-128z'></path>
        </svg>
        <div className='cashaccount-container2'></div>
        <Link to={"/wallet/" + props.data.accountNo}>
          <svg viewBox='0 0 1024 1024' className='cashaccount-icon2'>
            <path d='M366 708l196-196-196-196 60-60 256 256-256 256z'></path>
          </svg>
        </Link>
      </div>
      {transferOn == true ? (
        <div className='cashaccount-transfer-form'>
          <div className='cashaccount-container3'>
            <input
              type='number'
              min='1'
              step='1'
              required
              placeholder='amount'
              className='cashaccount-textinput input'
              value={transfervalue}
              onChange={(e) => {
                set_transfervalue(e.target.value);
              }}
            />
            <div className='cashaccount-container4'>
              <button className='cashaccount-button button' onClick={add_amount}>
                ADD
              </button>
              <button className='cashaccount-button1 button' onClick={remove_amount}>
                REMOVE
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {success == true && (
        <div className='cashaccount-container5'>
          <span className='cashaccount-text2'>SUCCESS</span>
        </div>
      )}
      {success == false && (
        <div className='cashaccount-container6'>
          <span className='cashaccount-text3'>FAILED</span>
        </div>
      )}
    </div>
  );
};

export default Cashaccount;
