import React from "react";
import { useQuery } from "urql";
import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import Header from "../components/header";
import KYCEditItem from "../components/kyc_edit_item";

import "./review_details.css";

const postcheck = `
        query load_INnumber_and_islands($id:ID!) {
          allIsland(pageLength:100, sortBy:"name", order:"ASC") {
            id
            name
          }
          allOccupation(pageLength:200, sortBy:"occupation", order:"ASC") {
            id
            occupation
          }           
          getdIDno(id:$id) {
            id
            idNo
            status
            reasoning
            digitalId {
                id
                entity {
                    id
                    profiles {
                        id
                        avatar {
                            id
                            path
                        }                        
                    }

                    email{
                      id
                      email
                    }      
                    confirmedEmail {
                      id
                      email
                    }      
                    phone{
                      id
                      phone
                    }      
                    confirmedPhone{
                      id
                      phone
                    } 


                }
                idImage {
                    id
                    path
                    status
                    reasoning
                }
                idNo {
                    id
                    status
                    idNo
                }
                personalDetail {
                    id
                    name
                    dobYear
                    dobMonth
                    dobDay
                    gender
                    status
                    householdHead
                }
                contactDetail {
                    id
                    location
                    island {
                        id
                        name
                    } 
                    status                   
                }
                enhancedDetails {
                    id
                    schoolLevel
                    inRelationship
                    relationshipStatus
                    singleParent
                    noOfKids
                    workStatus
                    workplace
                    haveGarden
                    haveHandicap
                    relativeHasHandicap
                    status
                    occupation {
                      id
                      occupation
                    }                    
                }

            }
          }
        }
      `;

const EditDetails = (props) => {
  let history = useNavigate();
  const input_uid = props.match.params.input_uid;
  const page_to_show = props.match.params.page_to_show;

  const [result, reexecuteQuery] = useQuery({
    query: postcheck,
    requestPolicy: "cache-and-network",
    variables: { id: input_uid },
  });

  const { data, fetching, error } = result;

  try {
    if (data.getdIDno.digitalId.idImage[0].status != "APPROVED" && data.getdIDno.digitalId.idImage[0].status != "REJECTED") {
      useLazyPolling(result, reexecuteQuery, 3000);
    }
  } catch {}

  const KYS_loader = () => {
    if (error) {
      history("/error");
    }

    if (fetching) {
      return (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      );
    }

    try {
      if (data) {
        if (data.getdIDno == null) {
          history("/error");
        } else {
          let tmp_id = "WAITING";
          let tmp_pd = "WAITING";
          let tmp_cd = "WAITING";
          let tmp_ed = "WAITING";

          try {
            tmp_id = data.getdIDno.digitalId.idNo.status;
          } catch {}

          try {
            tmp_pd = data.getdIDno.digitalId.personalDetail.status;
          } catch {}

          try {
            tmp_cd = data.getdIDno.digitalId.contactDetail.status;
          } catch {}

          try {
            tmp_ed = data.getdIDno.digitalId.enhancedDetails.status;
          } catch {}

          return (
            <KYCEditItem
              data={data.getdIDno.digitalId}
              islands={data.allIsland}
              occupations={data.allOccupation}
              path={data.getdIDno.digitalId.idImage[0].path}
              status={data.getdIDno.digitalId.idImage[0].status}
              id_s={tmp_id}
              pd_s={tmp_pd}
              cd_s={tmp_cd}
              ed_s={tmp_ed}
              page_to_show={page_to_show}
              reasoning={data.getdIDno.digitalId.idImage[0].reasoning}></KYCEditItem>
          );
        }
      }
    } catch {
      history("/error");
    }
  };
  return (
    <div className='reviewdetails-container'>
      <Helmet>
        <title>Vanuatu post - Registar</title>
        <meta property='og:title' content='Vanuatu post - Registar' />
      </Helmet>
      <Header rootClassName='header-root-class-name14'></Header>
      <div className='reviewdetails-contentframe'>
        <div className='reviewdetails-contentcontainer'>
          <div className='reviewdetails-homepanel'>
            <KYS_loader></KYS_loader>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDetails;
