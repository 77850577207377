import React from "react";
import { useQuery } from "urql";
import { Link } from "react-router-dom";

import UserListIteWwithArrow from "../components/user_listitem_with_arrow";

const lastregistered = `
    query latestRegisteredDIDs {
      latestRegisteredDIDs {
        id
        entity {
            id
            profiles {
                id
                name
                avatar {
                    id
                    path
                }
            }
        }

      }
    }
  `;

const LastRegistered = (props) => {
  let _usersearch = [];

  const [result, reexecuteQuery] = useQuery({
    query: lastregistered,
    requestPolicy: "cache-and-network",
  });

  const { data, fetching, error } = result;

  if (error) {
    _usersearch.push();
  }

  if (fetching) {
    _usersearch.push();
  }

  if (data == null) {
  } else {
    data.latestRegisteredDIDs.map((emp) => {
      try {
        _usersearch.push(
          <Link to={"/entity/" + emp.entity.id} key={"x-" + emp.id} style={{ width: "100%" }}>
            <UserListIteWwithArrow
              rootClassName='avatarlistitemwithadder-root-class-name'
              user_name={emp.entity.profiles[0].name}
              avatar={emp.entity.profiles[0].avatar}
              key={emp.id}></UserListIteWwithArrow>
          </Link>
        );
      } catch {}
    });
  }

  return <div style={{ width: "100%" }}>{_usersearch}</div>;
};

export default LastRegistered;
