import React from "react";

import { Helmet } from "react-helmet";

import Header from "../components/header";
import NewUserRegister from "../components/new_user_register";

import "./add_user.css";

const AddUser = (props) => {
  return (
    <div className='adduser-container'>
      <Helmet>
        <title>add user - bravhurDB</title>
        <meta
          name='description'
          content='the business ready database, featuring field level streaming, IoT support, GraphQL, fine grained access control, in-memory graph database backend'
        />
        <meta property='og:title' content='add user - bravhurDB' />
        <meta
          property='og:description'
          content='the business ready database, featuring field level streaming, IoT support, GraphQL, fine grained access control, in-memory graph database backend'
        />
      </Helmet>
      <Header rootClassName='header-root-class-name10'></Header>
      <div className='adduser-contentframe'>
        <div className='adduser-contentcontainer'>
          <div className='adduser-homepanel'>
            <div className='adduser-leftpanel'>
              <span className='adduser-leftpaneltext'>Add New User</span>
              <NewUserRegister rootClassName='newuserregister-root-class-name'></NewUserRegister>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
