import React, { useState, useEffect } from "react";
import { useQuery } from "urql";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import storageService from "../../services/storage";

import Cbmcolorfulbar from "../components/cbmcolorfulbar";

import Header from "../components/header";

import Accountingsettings from "../components/accounting_settings";
import GeneralSettings from "../components/general_settings";

import "./cbm_settings.css";

const settingsquery = `query getSettings{
    getLendingSettings(id:"lending-settings-0000"){
      id
      interestPercentage
      weeklyFee
      initialLendingAmount
      maximumLendingAmount
      useLimits
      loanTerm
      investmentToLimit
      stopEnforcedLoans
      enforceAfterTerms
      enforcmentCostPercentage
    }
    getAccountingSettings(id:"accounting-settings-0000") {
      id
      texAmount
      costPercentage
      investorProfitMaximum
      reInvestmentPercentage
    }
    }
  `;

const Cbmsettings = (props) => {
  let dta = JSON.parse(storageService.getStorageData("fps")).fronPageStats;

  const [result, reexecuteQuery] = useQuery({
    query: settingsquery,
    requestPolicy: "cache-and-network",
  });

  let _F = [];

  const { data, fetching, error } = result;

  if (data) {
    _F.push(
      <>
        <GeneralSettings data={data} key='gs01'></GeneralSettings>
        <Accountingsettings data={data} key='as01'></Accountingsettings>
      </>
    );
  }
  if (error) {
    _F.push(
      <>
        <GeneralSettings
          data={{
            getLendingSettings: {
              interestPercentage: 20,
              weeklyFee: 100,
              initialLendingAmount: 5000,
              maximumLendingAmount: 250000,
              useLimits: true,
              loanTerm: 14,
              investmentToLimit: 4,
              stopEnforcedLoans: true,
              enforceAfterTerms: 4,
              enforcmentCostPercentage: 20,
            },
            getAccountingSettings: { texAmount: 5, costPercentage: 15, investorProfitMaximum: 5.5, reInvestmentPercentage: 50 },
          }}
          key='gs01'></GeneralSettings>
        <Accountingsettings
          data={{
            getLendingSettings: {
              interestPercentage: 20,
              weeklyFee: 100,
              initialLendingAmount: 5000,
              maximumLendingAmount: 250000,
              useLimits: true,
              loanTerm: 14,
              investmentToLimit: 4,
              stopEnforcedLoans: true,
              enforceAfterTerms: 4,
              enforcmentCostPercentage: 20,
            },
            getAccountingSettings: { texAmount: 5, costPercentage: 15, investorProfitMaximum: 5.5, reInvestmentPercentage: 50 },
          }}
          key='as02'></Accountingsettings>
      </>
    );
  }

  return (
    <div className='cbmsettings-container'>
      <Helmet>
        <title>Settings - CBM-UI</title>
        <meta property='og:title' content='Settings - CBM-UI' />
      </Helmet>
      <Header></Header>
      <div className='cbmsettings-body'>
        <div className='cbmsettings-maincontainer'>
          {dta.isHealthy == false && (
            <div className='pushscreen-systemwarning'>
              <span className='pushscreen-text'>FINANCIAL SYSTEM IS DOWN! CONTACT POST OFFICE IT.</span>
            </div>
          )}

          <Cbmcolorfulbar rootClassName='cbmcolorfulbar-root-class-name2'></Cbmcolorfulbar>

          {_F}
        </div>
      </div>
    </div>
  );
};

export default Cbmsettings;
