import React from "react";
import Avatar from "react-avatar";

import "./useritem_add.css";

const UseritemAdd = (props) => {
  let avatar_img = "";
  try {
    if (props.avatar.path == "") {
    } else {
      avatar_img = process.env.REACT_APP_STORAGE_READ_HOST + "/" + props.avatar.path;
    }
  } catch {}

  const set_entity = () => {
    props.setEntity(props.entity);
    props.setData(props.data);
  };

  return (
    <div className='useritem-add-useritem-add'>
      <Avatar src={avatar_img} size='50' name={props.user_name} className='useritem-add-image' />

      <span className='useritem-add-text'>{props.user_name}</span>
      <svg viewBox='0 0 804.5714285714286 1024' className='useritem-add-icon' onClick={set_entity}>
        <path d='M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z'></path>
      </svg>
    </div>
  );
};

export default UseritemAdd;
