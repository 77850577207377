import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "urql";

import { GeneratePassword } from "js-generate-password";
import storageService from "../../services/storage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PropTypes from "prop-types";

import "./change_password.css";

let access_point = storageService.getStorageData("access_point");
let db_name = "";
try {
  db_name = storageService.getStorageData("access_point").split("/")[2].split(".")[0];
} catch {}

const CHANGE = `
        mutation changePassword($id: ID!, $password: String!) {
          changePassword(id:$id, password: $password) {
            message
          }
        }
      `;

const ChangePassword = (props) => {
  let history = useNavigate();

  const [generatedPassword, setGeneratedPassord] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);

  const [name, setName] = useState("");

  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");

  const [resultPWD, changePasswd] = useMutation(CHANGE);

  async function pwd_mtch() {
    if (password.trim() == passwordRepeat.trim()) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  }

  async function generatePwd(event) {
    event.preventDefault();
    const password = GeneratePassword({
      length: 10,
      symbols: false,
      minLengthLowercase: 1,
      minLengthUppercase: 1,
      minLengthNumbers: 1,
    });
    setGeneratedPassord(password);
    setPassword(password);
    setPasswordRepeat(password);
    pwd_mtch();
  }

  const hp = () => {};

  const pwdMutate = (event) => {
    event.preventDefault();

    if (password.trim() == passwordRepeat.trim()) {
      changePasswd({
        id: props.user_uid,
        password: password.trim(),
      }).then((result) => {
        if (result.error) {
          toast.error("Some problems happened...");
          console.error("Oh no!", result.error);
        }
        if (result.data) {
          if (result.data.changePassword.message == "OK.") {
            history("/entity/" + props.entity_uid);
          } else {
            toast.error("Some errors happened.");
            console.log(result);
          }
        }
      });
    } else {
    }
  };

  const cancel = () => {
    history.goBack();
  };

  useEffect(() => {
    pwd_mtch();
  }, [password, passwordRepeat]);

  return (
    <div className={`changepassword-appparametereditor ${props.rootClassName} `}>
      <ToastContainer
        position='bottom-left'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
      <div className='changepassword-form'>
        <form className='changepassword-registrationform' onSubmit={pwdMutate}>
          <input
            type='password'
            id='password'
            name='password'
            required
            minLength='8'
            placeholder='password'
            autoComplete='off'
            className='changepassword-textinput input'
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <input
            type='password'
            id='confirm_password'
            name='confirm_password'
            required
            minLength='8'
            placeholder='confirm password'
            autoComplete='off'
            className='changepassword-textinput1 input'
            value={passwordRepeat}
            onChange={(e) => {
              setPasswordRepeat(e.target.value);
            }}
          />
          <div className='changepassword-container'>
            <button type='button' className='changepassword-button button' onClick={generatePwd}>
              Generate Password
            </button>
            <span className='changepassword-text'>{generatedPassword}</span>
          </div>
          <div className='changepassword-container1'>
            <button type='button' className='changepassword-button1 button' onClick={cancel}>
              Cancel
            </button>
            <button type='submit' className='changepassword-button2 button'>
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

ChangePassword.defaultProps = {
  rootClassName: "",
};

ChangePassword.propTypes = {
  rootClassName: PropTypes.string,
};

export default ChangePassword;
