import React from "react";
import Avatar from "react-avatar";
import uuid from "react-uuid";
import { useMutation, useQuery } from "urql";

import "./edit_member_add.css";

const savesettings = `mutation saveGroup($id:ID!, $adm_id:ID!){
    saveGroup(data:[{id:$id, administrator:{id:$adm_id}}]){
        id
    }
    }
  `;

const savememeber = `mutation registerCbmMemeber($groupId:ID!, $profileId:ID!){
    registerCbmMemeber(groupId:$groupId, profileId:$profileId)
    }
  `;

const EditMemeberAdd = (props) => {
  const [resultMut, domutate] = useMutation(savesettings);
  const [result02Mut, domutate02] = useMutation(savememeber);

  let avatar_img = "";
  try {
    if (props.avatar.path == "") {
    } else {
      avatar_img = process.env.REACT_APP_STORAGE_READ_HOST + "/" + props.avatar.path;
    }
  } catch {}

  const execute = () => {
    if (props.widget_type == "ADMINISTRATORS") {
      domutate({ id: props.group_uid, adm_id: props.profile_id });
    } else {
      domutate02({ groupId: props.group_uid, profileId: props.profile_id });
    }

    window.location.reload();
  };

  return (
    <div className={`entitymemeberadd-entitylistitem`}>
      <Avatar src={avatar_img} size='50' name={props.name} className='entitymemeberadd-image' />

      <div className='entitymemeberadd-container'>
        <span className='entitymemeberadd-text'>{props.name}</span>
      </div>
      <svg viewBox='0 0 1024 1024' className='entitymemeberadd-icon' onClick={execute}>
        <path d='M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z' className=''></path>
      </svg>
    </div>
  );
};

export default EditMemeberAdd;
