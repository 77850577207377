import React from "react";
import storageService from "../../services/storage";
import { useQuery } from "urql";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "../components/header";
import BusinessSettings from "../components/business_settings";
import "./settings.css";

let access_point = storageService.getStorageData("access_point");

const dep_details = `
    query getDepartment($id:ID!) {

      getMadruhamSummary:getDepartmentVPLSummary(transactionDirection:"ALL", departmentId:$id){
        madruhamAccount {
            id
            name
            accountNo
        }  
        balance
        ts
        tsBalance
        ledgerItems
      }
        
      getDepartment(id:$id) {
        id   
        name
        tinNo
        businessType
        location
        island {
          id
          name
        }
        bankDetails{
          id
          bankName
          bankBranch
          accountHolderName
          accountNo
          sortCode
        }

      }
    }
  `;

const DepartmentSettings = (props) => {
  let { dep_uid } = useParams();

  const [result, reexecuteQuery] = useQuery({
    url: access_point,
    query: dep_details,
    variables: { id: dep_uid },
    requestPolicy: "cache-and-network",
  });

  let tmp_f = null;

  const { data, fetching, error } = result;

  if (error) {
    tmp_f = <div></div>;
    toast.error("Some error happened..");
  }

  if (fetching) {
    tmp_f = (
      <div>
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </div>
    );
  }

  if (data != null) {
    if (data.getDepartment != null) {
      tmp_f = (
        <BusinessSettings
          data={data.getDepartment}
          alldata={data}
          reexecute={reexecuteQuery}
          style={{ maxWidth: "600px" }}></BusinessSettings>
      );
    }
  }

  return (
    <div className='settings-b-container'>
      <ToastContainer
        position='bottom-left'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
      <Helmet>
        <title>Settings - Vanuatu Post</title>
        <meta property='og:title' content='Vanuatu post - Business' />
      </Helmet>
      <Header rootClassName='header-root-class-name18'></Header>

      <div className='settings-b-contentframe'>
        <div className='settings-b-contentcontainer'>
          <div className='settings-b-homepanel'>{tmp_f}</div>
        </div>
      </div>
    </div>
  );
};

export default DepartmentSettings;
