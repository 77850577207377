import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import Header from "../components/header";
import ChangePasswordDID from "../components/change_password_did";
import "./password_change.css";

const PasswordChangeDID = (props) => {
  let { user_uid, entity_uid } = useParams();

  return (
    <div className='passwordchange-container'>
      <Helmet>
        <title>password change - bravhurDB</title>
        <meta
          name='description'
          content='the business ready database, featuring field level streaming, IoT support, GraphQL, fine grained access control, in-memory graph database backend'
        />
        <meta property='og:title' content='password change - bravhurDB' />
        <meta
          property='og:description'
          content='the business ready database, featuring field level streaming, IoT support, GraphQL, fine grained access control, in-memory graph database backend'
        />
      </Helmet>
      <Header rootClassName='header-root-class-name12'></Header>
      <div className='passwordchange-contentframe'>
        <div className='passwordchange-contentcontainer'>
          <div className='passwordchange-homepanel'>
            <div className='passwordchange-leftpanel'>
              <span className='passwordchange-leftpaneltext'>Change DigitalID Password</span>
              <ChangePasswordDID
                rootClassName='changepassword-root-class-name'
                did_uid={did_uid}
                entity_uid={entity_uid}></ChangePasswordDID>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordChangeDID;
