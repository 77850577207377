import React from "react";

import "./count_negative.css";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const Countnegative = (props) => {
  let _d = new Date(props.data.timestamp * 1000);

  return (
    <div className='countnegative-ledgeritemnegative'>
      <span className='countnegative-typetext'>REPORTED MISSING</span>
      <span className='countnegative-amounttext'>{numberWithCommas(props.data.amount) + " VT"}</span>
      <div className='countnegative-bottomcont'>
        <span className='countnegative-bytext'>{props.data.transacted_by}</span>
        <span className='countnegative-datetext'>{_d.toISOString().replace("T", " ")}</span>
      </div>
    </div>
  );
};

export default Countnegative;
