import React from "react";

import "./count_correct.css";

const Countcorrect = (props) => {
  let _d = new Date(props.data.timestamp * 1000);

  return (
    <div className='countcorrect-ledgeritempositive'>
      <span className='countcorrect-typetext'>COUNT REPORTED CORRECT</span>
      <div className='countcorrect-bottomcont'>
        <span className='countcorrect-bytext'>{props.data.transacted_by}</span>
        <span className='countcorrect-datetext'>{_d.toISOString().replace("T", " ")}</span>
      </div>
    </div>
  );
};

export default Countcorrect;
