import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "urql";

import { GeneratePassword } from "js-generate-password";
import storageService from "../../services/storage";

import PropTypes from "prop-types";

import "./new_user_register.css";

const search_user = `
    query allUser($filter:String) {
      allUser(filter:{username:$filter}) {
        id
        username   
      }
    }
  `;

const REGISTER = `
        mutation register ($username: String!, $password: String!, $name:String!) {
          register(username:$username, password: $password,name: $name) {
            message
            accessToken
            refreshToken
            profiles {
              id
              name
              entity {
                id
              }
              avatar {
                id
                path
              }
            }
          }
        }
      `;

let access_point = storageService.getStorageData("access_point");
let db_name = "";
try {
  db_name = storageService.getStorageData("access_point").split("/")[2].split(".")[0];
} catch {}
const NewUserRegister = (props) => {
  let history = useNavigate();

  const [usernameExists, setUsernameExists] = useState(false);
  const [generatedPassword, setGeneratedPassord] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);

  const [name, setName] = useState("");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");

  const shouldPause = username.trim().length < 3;
  const [result, reexecuteQuery] = useQuery({
    url: access_point,
    query: search_user,
    variables: { filter: username.trim() },
    requestPolicy: "cache-and-network",
    pause: shouldPause,
  });

  const [resultResister, register] = useMutation(REGISTER);

  const { data, fetching, error } = result;

  async function pwd_mtch() {
    if (password.trim() == passwordRepeat.trim()) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  }

  async function generatePwd(event) {
    event.preventDefault();
    const password = GeneratePassword({
      length: 10,
      symbols: false,
      minLengthLowercase: 1,
      minLengthUppercase: 1,
      minLengthNumbers: 1,
    });
    setGeneratedPassord(password);
    setPassword(password);
    setPasswordRepeat(password);
    pwd_mtch();
  }

  const regMutate = (event) => {
    event.preventDefault();

    if (password.trim() == passwordRepeat.trim()) {
      register({
        name: name.trim(),
        username: username.trim(),
        password: password.trim(),
      }).then((result) => {
        if (result.error) {
          console.error("Oh no!", result.error);
        } else {
          if (result.data != null) {
            history("/entity/" + result.data.register.profiles[0].entity.id);
          }
        }
      });
    } else {
    }
  };

  useEffect(() => {
    setUsernameExists(false);
    reexecuteQuery();

    try {
      data.allUser.map((emp) => {
        setUsernameExists(true);
      });
    } catch {}
  }, [username]);

  useEffect(() => {
    pwd_mtch();
  }, [password, passwordRepeat]);

  return (
    <div className={`newuserregister-appparametereditor ${props.rootClassName} `}>
      <div className='newuserregister-form'>
        <form className='newuserregister-registrationform' onSubmit={regMutate}>
          <input
            type='text'
            name='name'
            required
            placeholder='name'
            id='name'
            className='newuserregister-textinput input'
            minLength='3'
            pattern="^(?!_$)(?!['-._])(?!.*[_.-]{2})[a-zA-Z0-9 '_.-]+(?<!['.-])$"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          {usernameExists == true && <span className='newuserregister-text'>username exists</span>}
          <input
            type='text'
            name='nu-newuser'
            required
            minLength='3'
            pattern='^(?!_$)(?![-._])(?!.*[_.-]{2})[a-zA-Z0-9_.-]+(?<![.-])$'
            placeholder='username for the new user'
            id='nu-newuser'
            className='newuserregister-textinput1 input'
            autoComplete='off'
            value={username}
            onChange={(e) => {
              setUsername(e.target.value.trim());
              //UserSearch(e);
            }}
          />
          {passwordMatch == false && <span className='newuserregister-text'>password does not match</span>}
          <input
            type='password'
            name='password-newuser'
            required
            placeholder='password'
            id='password-newuser'
            className='newuserregister-textinput2 input'
            minLength='8'
            autoComplete='off'
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <input
            type='password'
            name='confirm_password-newuser'
            required
            placeholder='confirm password'
            id='confirm_password-newuser'
            minLength='8'
            autoComplete='off'
            className='newuserregister-textinput3 input'
            value={passwordRepeat}
            onChange={(e) => {
              setPasswordRepeat(e.target.value);
            }}
          />
          <div className='newuserregister-container'>
            <button type='button' className='newuserregister-button button' onClick={generatePwd}>
              Generate Password
            </button>

            <span className='newuserregister-text1'>{generatedPassword}</span>
          </div>
          <div className='newuserregister-container1'>
            <button type='submit' className='newuserregister-button1 button'>
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

NewUserRegister.defaultProps = {
  rootClassName: "",
};

NewUserRegister.propTypes = {
  rootClassName: PropTypes.string,
};

export default NewUserRegister;
