import React from "react";

import PropTypes from "prop-types";

import "./bankstrip.css";

const Bankstrip = (props) => {
  return (
    <div className='bankstrip-bankstrip'>
      <span className='bankstrip-text'>{props.bank_name}</span>
      <span className='bankstrip-text1'>{props.sum}</span>
    </div>
  );
};

Bankstrip.defaultProps = {
  bank_name: "BRED",
  sum: "38.456.789 VT",
};

Bankstrip.propTypes = {
  bank_name: PropTypes.string,
  sum: PropTypes.string,
};

export default Bankstrip;
