import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "urql";
import uuid from "react-uuid";

import "./taxaccount.css";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const trans_uid = uuid();

const SUBMIT_TRANSFER = `
        mutation submitBusinessTransfer($fromAccount:String!, $toAccount:String!, $amount:Int!, $transactionId:String!) {
          submitBusinessTransfer(fromAccount:$fromAccount, toAccount:$toAccount, amount: $amount, transactionId: $transactionId)
        }
      `;

const Taxaccount = (props) => {
  const [success, setSuccess] = useState(null);

  const [transferOn, setTransferOn] = useState(false);

  const [transfervalue, set_transfervalue] = useState(null);

  const [transoption, set_transoption] = useState("NONE");

  const [result_tra, submit_transfer] = useMutation(SUBMIT_TRANSFER);

  const switch_transfer = () => {
    if (transferOn == true) {
      setTransferOn(false);
    } else {
      setTransferOn(true);
    }
  };

  const business_trans = (event) => {
    event.preventDefault();

    if (transfervalue !== null) {
      if (transfervalue > 0) {
        submit_transfer({ fromAccount: "TAX", toAccount: transoption, amount: ~~transfervalue, transactionId: trans_uid });
        window.location.reload();
      }
    }
  };

  return (
    <div className='taxaccount-taxaccount'>
      <div className='taxaccount-container'>
        <span className='taxaccount-text'>TAX</span>
        <span className='taxaccount-text1'>{numberWithCommas(props.data.balance) + " VT"}</span>
      </div>
      <div className='taxaccount-container1'>
        <svg viewBox='0 0 1024 1024' className='taxaccount-icon' onClick={switch_transfer}>
          <path d='M86 682l170-170v128h640v86h-640v128zM938 342l-170 170v-128h-640v-86h640v-128z'></path>
        </svg>
        <div className='taxaccount-container2'></div>
        <Link to={"/wallet/" + props.data.accountNo}>
          <svg viewBox='0 0 1024 1024' className='taxaccount-icon2'>
            <path d='M366 708l196-196-196-196 60-60 256 256-256 256z'></path>
          </svg>
        </Link>
      </div>

      {transferOn == true ? (
        <form className='taxaccount-transfer-form' onSubmit={business_trans}>
          <select
            className='taxaccount-select'
            value={transoption}
            onChange={(e) => {
              set_transoption(e.target.value);
            }}>
            <option value='NONE'>Please select an Account</option>
            <option value='MAIN'>Main</option>
            <option value='PROFIT'>Profit</option>
            <option value='EXPENSE'>Expense</option>
          </select>
          <div className='taxaccount-container3'>
            <input
              type='number'
              min='1'
              step='1'
              required
              placeholder='amount'
              className='taxaccount-textinput input'
              value={transfervalue}
              onChange={(e) => {
                set_transfervalue(e.target.value);
              }}
            />
            {transoption !== "NONE" && (
              <button type='submit' className='taxaccount-button button'>
                TRANSFER
              </button>
            )}
          </div>
        </form>
      ) : (
        <></>
      )}

      {success == true && (
        <div className='taxaccount-container4'>
          <span className='taxaccount-text2'>SUCCESS</span>
        </div>
      )}

      {success == false && (
        <div className='taxaccount-container5'>
          <span className='taxaccount-text3'>FAILED</span>
        </div>
      )}
    </div>
  );
};

export default Taxaccount;
