import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import "./credential_listitem.css";

const CredentialListItem = (props) => {
  let history = useNavigate();

  const [credFirst, setCredFirst] = useState(false);
  const [credSecond, setCredSecond] = useState(false);

  const swapCred = () => {
    if (props.type == "user") {
      history("/password_change/" + props.data.id + "/" + props.entity_uid);
    } else if (props.type == "did") {
      history("/password_change_did/" + props.data.id + "/" + props.entity_uid);
    } else {
      if (credFirst == true) {
        setCredFirst(false);
      } else {
        setCredFirst(true);
      }
    }
  };

  return (
    <div className={`credentialitem-container ${props.rootClassName} `}>
      <div className='credentialitem-main'>
        {props.type == "user" && (
          <svg viewBox='0 0 1024 1024' className='credentialitem-credential'>
            <path
              d='M870.286 765.143c-14.857-106.857-58.286-201.714-155.429-214.857-50.286 54.857-122.857 89.714-202.857 89.714s-152.571-34.857-202.857-89.714c-97.143 13.143-140.571 108-155.429 214.857 79.429 112 210.286 185.714 358.286 185.714s278.857-73.714 358.286-185.714zM731.429 365.714c0-121.143-98.286-219.429-219.429-219.429s-219.429 98.286-219.429 219.429 98.286 219.429 219.429 219.429 219.429-98.286 219.429-219.429zM1024 512c0 281.714-228.571 512-512 512-282.857 0-512-229.714-512-512 0-282.857 229.143-512 512-512s512 229.143 512 512z'
              className=''></path>
          </svg>
        )}

        {props.type == "did" && (
          <svg viewBox='0 0 1024 1024' className='credentialitem-finger'>
            <path
              d='M636 938h-6q-92-24-158-90-38-38-65-103t-27-119q0-52 38-89t92-37 93 37 39 89q0 34 25 58t63 24 64-24 26-58q0-120-91-206t-219-86q-92 0-168 47t-114 125q-24 50-24 120 0 80 28 154 6 20-14 26t-26-12q-32-82-32-168 0-78 30-138 42-90 129-144t191-54q146 0 249 99t103 237q0 52-39 88t-93 36-92-36-38-88q0-34-26-59t-64-25-63 25-25 59q0 112 80 192 56 56 140 78 18 2 14 26-4 16-20 16zM530 626q0 74 55 128t137 54q4 0 18-2t23-2 18 3 11 13q4 22-18 26-24 4-52 4-80 0-132-38-102-70-102-186 0-22 22-22 20 0 20 22zM416 930q-8 0-14-6-54-54-86-114-46-80-46-184 0-94 71-162t171-68 171 68 71 162q0 20-22 20t-22-20q0-78-58-133t-140-55-140 55-58 133q0 96 38 164 26 46 80 104 16 14 0 30-6 6-16 6zM150 414q-22 0-22-20 0-4 4-12 64-92 160-140 100-52 220-52t220 52q98 48 160 138 4 8 4 12 0 14-16 20t-24-8q-60-82-144-124-92-46-200-47t-200 47q-90 46-146 126-6 8-16 8zM760 190q-8 0-10-2-118-60-238-60-130 0-238 60-10 6-20 0t-10-18q0-14 10-20 116-64 258-64 130 0 258 64 18 10 8 28-8 12-18 12z'
              className=''></path>
          </svg>
        )}

        {props.type == "email" && (
          <svg viewBox='0 0 1024 1024' className='credentialitem-icon01'>
            <path
              d='M512 640q52 0 90-38t38-90-38-90-90-38-90 38-38 90 38 90 90 38zM512 86q176 0 301 125t125 301v62q0 64-43 108t-105 44q-78 0-126-64-64 64-152 64t-151-63-63-151 63-151 151-63 151 63 63 151v62q0 26 19 46t45 20 45-20 19-46v-62q0-140-101-241t-241-101-241 101-101 241 101 241 241 101h214v84h-214q-176 0-301-125t-125-301 125-301 301-125z'
              className=''></path>
          </svg>
        )}
        {props.type == "phone" && (
          <svg viewBox='0 0 1024 1024' className='credentialitem-icon03'>
            <path
              d='M282 460q96 186 282 282l94-94q20-20 44-10 72 24 152 24 18 0 30 12t12 30v150q0 18-12 30t-30 12q-300 0-513-213t-213-513q0-18 12-30t30-12h150q18 0 30 12t12 30q0 80 24 152 8 26-10 44z'
              className=''></path>
          </svg>
        )}
        <div className='credentialitem-container1'></div>
        {props.verified == false ? (
          <svg viewBox='0 0 1024 1024' className='credentialitem-icon05'>
            <path
              d='M726 666l-154-154 154-154-60-60-154 154-154-154-60 60 154 154-154 154 60 60 154-154 154 154zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z'
              className=''></path>
          </svg>
        ) : (
          <svg viewBox='0 0 1024 1024' className='credentialitem-icon07'>
            <path
              d='M733.714 419.429c0-9.714-3.429-19.429-10.286-26.286l-52-51.429c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-233.143 232.571-129.143-129.143c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-52 51.429c-6.857 6.857-10.286 16.571-10.286 26.286s3.429 18.857 10.286 25.714l206.857 206.857c6.857 6.857 16.571 10.857 25.714 10.857 9.714 0 19.429-4 26.286-10.857l310.286-310.286c6.857-6.857 10.286-16 10.286-25.714zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z'
              className=''></path>
          </svg>
        )}
        <div className='credentialitem-container2'>
          <span className='credentialitem-text'>{props.name}</span>
        </div>

        {props.type == "did" && (
          <Link to={"/kyc_info/" + props.data.id} style={{ width: "56px", "self-justify": "center" }} className='credentialitem-finger'>
            <svg viewBox='0 0 1024 1024' className='credentialitem-finger'>
              <path
                d='M636 938h-6q-92-24-158-90-38-38-65-103t-27-119q0-52 38-89t92-37 93 37 39 89q0 34 25 58t63 24 64-24 26-58q0-120-91-206t-219-86q-92 0-168 47t-114 125q-24 50-24 120 0 80 28 154 6 20-14 26t-26-12q-32-82-32-168 0-78 30-138 42-90 129-144t191-54q146 0 249 99t103 237q0 52-39 88t-93 36-92-36-38-88q0-34-26-59t-64-25-63 25-25 59q0 112 80 192 56 56 140 78 18 2 14 26-4 16-20 16zM530 626q0 74 55 128t137 54q4 0 18-2t23-2 18 3 11 13q4 22-18 26-24 4-52 4-80 0-132-38-102-70-102-186 0-22 22-22 20 0 20 22zM416 930q-8 0-14-6-54-54-86-114-46-80-46-184 0-94 71-162t171-68 171 68 71 162q0 20-22 20t-22-20q0-78-58-133t-140-55-140 55-58 133q0 96 38 164 26 46 80 104 16 14 0 30-6 6-16 6zM150 414q-22 0-22-20 0-4 4-12 64-92 160-140 100-52 220-52t220 52q98 48 160 138 4 8 4 12 0 14-16 20t-24-8q-60-82-144-124-92-46-200-47t-200 47q-90 46-146 126-6 8-16 8zM760 190q-8 0-10-2-118-60-238-60-130 0-238 60-10 6-20 0t-10-18q0-14 10-20 116-64 258-64 130 0 258 64 18 10 8 28-8 12-18 12z'
                className=''></path>
            </svg>
            <div style={{ width: "12px" }}></div>
          </Link>
        )}

        {props.editable && (
          <svg viewBox='0 0 1024 1024' className='credentialitem-icon09' onClick={swapCred}>
            <path
              d='M919.936 226.901l-122.837-122.837c-12.501-12.501-28.885-18.731-45.269-18.731-16.427 0-32.768 6.229-45.269 18.731l-151.893 151.936h-384c-23.552 0-42.667 19.072-42.667 42.667v554.667c0 23.595 19.115 42.667 42.667 42.667h554.667c23.552 0 42.667-19.072 42.667-42.667v-384l151.936-151.936c12.501-12.501 18.731-28.885 18.731-45.269s-6.229-32.725-18.731-45.227zM490.667 626.005l-92.672-92.672 268.501-268.501 92.672 92.672-268.501 268.501zM381.397 568.875l74.923 73.728-72.32-2.603-2.603-71.125zM682.667 810.667h-469.333v-469.333h256l-135.68 135.68c-12.501 12.501-20.395 34.645-26.837 54.997-6.827 21.333-8.149 45.056-8.149 62.72v130.603h130.603c17.664 0 47.275-4.267 67.029-12.373 19.797-8.107 38.229-14.805 50.688-27.307l135.68-130.987v256zM789.333 327.339l-92.672-92.672 55.168-55.168 92.629 92.672-55.125 55.168z'
              className=''></path>
          </svg>
        )}
      </div>
      {credFirst == true && (
        <div className='credentialitem-menu'>
          <svg viewBox='0 0 1024 1024' className='credentialitem-icon11'>
            <path
              d='M810 170v86h-596v-86h148l44-42h212l44 42h148zM256 810v-512h512v512q0 34-26 60t-60 26h-340q-34 0-60-26t-26-60z'
              className=''></path>
          </svg>
          <div className='credentialitem-container3'></div>
          <button className='credentialitem-button button'>Verify</button>
          <button className='credentialitem-button1 button'>Force</button>
          <button className='credentialitem-button2 button'>un-Verify</button>
        </div>
      )}
      {credSecond == true && (
        <div className='credentialitem-menu1'>
          <input type='text' placeholder='confirmation code' className='credentialitem-textinput input' />
          <button className='credentialitem-button3 button'>Submit</button>
        </div>
      )}
    </div>
  );
};

CredentialListItem.defaultProps = {
  rootClassName: "",
};

CredentialListItem.propTypes = {
  rootClassName: PropTypes.string,
};

export default CredentialListItem;
