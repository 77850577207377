import React from "react";
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import "./profile_listitem.css";

const ProfileListItem = (props) => {
  let avatar_img = "";
  let history = useNavigate();

  try {
    avatar_img = process.env.REACT_APP_STORAGE_READ_HOST + "/" + props.avatar.path;
  } catch {}

  const load_profile = () => {
    history("/edit_profile/" + props.ent_uid + "/" + props.profile_uid);
  };

  return (
    <div className={`profilelistitem-container ${props.rootClassName} `}>
      <Avatar src={avatar_img} size='35' name={props.name} className='profilelistitem-image' round={true} />

      <div className='profilelistitem-container1'>
        <span className='profilelistitem-text'>{props.name}</span>
      </div>
      <svg viewBox='0 0 1024 1024' className='profilelistitem-icon' onClick={load_profile}>
        <path
          d='M919.936 226.901l-122.837-122.837c-12.501-12.501-28.885-18.731-45.269-18.731-16.427 0-32.768 6.229-45.269 18.731l-151.893 151.936h-384c-23.552 0-42.667 19.072-42.667 42.667v554.667c0 23.595 19.115 42.667 42.667 42.667h554.667c23.552 0 42.667-19.072 42.667-42.667v-384l151.936-151.936c12.501-12.501 18.731-28.885 18.731-45.269s-6.229-32.725-18.731-45.227zM490.667 626.005l-92.672-92.672 268.501-268.501 92.672 92.672-268.501 268.501zM381.397 568.875l74.923 73.728-72.32-2.603-2.603-71.125zM682.667 810.667h-469.333v-469.333h256l-135.68 135.68c-12.501 12.501-20.395 34.645-26.837 54.997-6.827 21.333-8.149 45.056-8.149 62.72v130.603h130.603c17.664 0 47.275-4.267 67.029-12.373 19.797-8.107 38.229-14.805 50.688-27.307l135.68-130.987v256zM789.333 327.339l-92.672-92.672 55.168-55.168 92.629 92.672-55.125 55.168z'
          className=''></path>
      </svg>
    </div>
  );
};

ProfileListItem.defaultProps = {
  rootClassName: "",
};

ProfileListItem.propTypes = {
  rootClassName: PropTypes.string,
};

export default ProfileListItem;
