import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import storageService from "../../services/storage";

import { Helmet } from "react-helmet";
import { createClient } from "urql";
import uuid from "react-uuid";
import "./login.css";

let device_uid = storageService.getStorageData("device_uid");

const PING = `
        mutation ping {
          ping {
            nonce
            initiated            
          }
        }
      `;

const CE = `
        mutation checkUserExistance($username:String!) {
          checkUserExistance(username: $username)
        }
      `;

const LOGIN = `
        mutation login ($username: String!, $password: String!, $nonce: String!) {
          login(username:$username, password: $password, nonce:$nonce) {
            message
            accessToken
            refreshToken
            myDepartments
            profiles {
              id
              name
              avatar {
                id
                path
              }
              entity {
                id
              }               
            }
          }
        }
      `;

const REGISTER = `
        mutation register ($username: String!, $password: String!, $name:String!) {
          register(username:$username, password: $password,name: $name) {
            message
            accessToken
            refreshToken
            myDepartments
            profiles {
              id
              name
              avatar {
                id
                path
              }
              entity {
                id
              }               
            }
          }
        }
      `;

const Login = (props) => {
  let navi = useNavigate();

  let header_tmp = {
    device_uid: device_uid,
    nonce: uuid(),
    app_uid: process.env.REACT_APP_APPLICATION,
    dep_uid: process.env.REACT_APP_DEPARTMENT,
  };

  storageService.removeStorageData("access_token");
  storageService.removeStorageData("refresh_token");
  storageService.removeStorageData("active_profile");
  storageService.removeStorageData("profiles");
  storageService.removeStorageData("working_dep");
  storageService.removeStorageData("active_department");
  storageService.removeStorageData("app_deps");

  const [login_username, setLogin_Username] = useState("");
  const [login_password, setLogin_Password] = useState("");

  const [passwordMatch, setPasswordMatch] = useState(true);
  const [reg_or_login, set_reg_or_login] = useState(false);
  const [userexists, setUserexists] = useState(false);

  const [register_username, setRegister_Username] = useState("");
  const [register_password, setRegister_Password] = useState("");
  const [register_password_repeat, setRegister_Password_Repeat] = useState("");
  const [register_name, setRegister_Name] = useState("");

  useEffect(() => {
    const tmp_client = createClient({
      url: process.env.REACT_APP_BRAVHUR_URL,
      fetchOptions: () => {
        return {
          headers: header_tmp,
        };
      },
    });

    tmp_client
      .mutation(CE, { username: register_username })
      .toPromise()
      .then((result) => {
        if (result["data"]["checkUserExistance"] == true) {
          setUserexists(true);
        } else {
          setUserexists(false);
        }
      })
      .catch((error) => {});
  }, [register_username]);

  useEffect(() => {
    const tmp_client = createClient({
      url: process.env.REACT_APP_BRAVHUR_URL,
      fetchOptions: () => {
        return {
          headers: header_tmp,
        };
      },
    });

    tmp_client
      .mutation(PING)
      .toPromise()
      .then((result) => {
        if (result["data"]["ping"]["nonce"] == true) {
          try {
            storageService.setStorageData("pingnonce", result["data"]["ping"]["nonce"]);
          } catch {
            toast.error("Security exception");
          }
        } else {
          try {
            storageService.setStorageData("pingnonce", result["data"]["ping"]["nonce"]);
          } catch {
            toast.error("Security exception");
          }
        }
      })
      .catch((error) => {
        toast.error("Can not reach your bravhurDB instance..");
      });
  }, []);

  async function submitLogin(event) {
    event.preventDefault();

    const pn = storageService.getStorageData("pingnonce");
    const tmp_client = createClient({
      url: process.env.REACT_APP_BRAVHUR_URL,
      fetchOptions: () => {
        return {
          headers: header_tmp,
        };
      },
    });

    tmp_client
      .mutation(LOGIN, { username: login_username, password: login_password, nonce: pn })
      .toPromise()
      .then((result) => {
        const { data, fetching, error } = result;

        if (error) {
          if (error["message"] == "[GraphQL] @login: Nonce error") {
            window.location.reload();
          }
          toast.error(error["message"]);
        } else {
          if (data) {
            console.log(data);
            if (data["login"] != null) {
              if (result["data"]["login"]["message"] == "OK.") {
                storageService.setStorageData("access_token", result["data"]["login"]["accessToken"]);
                storageService.setStorageData("refresh_token", result["data"]["login"]["refreshToken"]);
                storageService.setStorageData("active_profile", result["data"]["login"]["profiles"][0]["id"]);
                storageService.setStorageData("profiles", JSON.stringify(result["data"]["login"]["profiles"]));
                storageService.setStorageData("app_deps", JSON.stringify(result["data"]["login"]["myDepartments"]));
                try {
                  storageService.setStorageData("working_dep", result["data"]["login"]["myDepartments"][0]["id"]);
                  storageService.setStorageData("active_department", result["data"]["login"]["myDepartments"][0]["id"]);
                } catch {}
                navi("/");
                window.location.reload();
              } else {
              }
            }
          }
        }
      })
      .catch((error) => {
        toast.error("Some errors happened..");
      });
  }

  async function submitRegister(event) {
    event.preventDefault();

    if ((userexists == true) | (passwordMatch == false)) {
      toast.error("You can not register until username and password is not correct");
    } else {
      if (register_password == register_password_repeat) {
        const tmp_client = createClient({
          url: process.env.REACT_APP_BRAVHUR_URL,
          fetchOptions: () => {
            return {
              headers: header_tmp,
            };
          },
        });

        tmp_client
          .mutation(REGISTER, { username: register_username, password: register_password, name: register_name })
          .toPromise()
          .then((result) => {
            const { data, fetching, error } = result;

            if (error) {
              toast.error(error["message"]);
            } else {
              if (data) {
                if (data["register"] != null) {
                  if (result["data"]["register"]["message"] == "OK.") {
                    storageService.setStorageData("access_token", result["data"]["register"]["accessToken"]);
                    storageService.setStorageData("refresh_token", result["data"]["register"]["refreshToken"]);
                    storageService.setStorageData("active_profile", result["data"]["register"]["profiles"][0]["id"]);
                    storageService.setStorageData("profiles", JSON.stringify(result["data"]["register"]["profiles"]));
                    storageService.setStorageData("app_deps", JSON.stringify(result["data"]["register"]["myDepartments"]));
                    try {
                      storageService.setStorageData("working_dep", result["data"]["register"]["myDepartments"][0]["id"]);
                      storageService.setStorageData("active_department", result["data"]["register"]["myDepartments"][0]["id"]);
                    } catch {}
                    navi("/");
                    window.location.reload();
                  } else {
                  }
                }
              }
            }
          })
          .catch((error) => {
            toast.error("Some errors happened..");
          });
      } else {
        toast.error("Two password does not match");
      }
    }
  }

  const swap_state = () => {
    if (reg_or_login == true) {
      set_reg_or_login(false);
    } else {
      set_reg_or_login(true);
    }
  };

  async function pwd_mtch() {
    if (register_password.trim() == register_password_repeat.trim()) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  }

  useEffect(() => {
    pwd_mtch();
  }, [register_password, register_password_repeat]);

  return (
    <div className='login-container'>
      <ToastContainer
        position='bottom-left'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
      <Helmet>
        <title>Login - Vanuatu Post</title>
        <meta property='og:title' content='Vanuatu Post - Login' />
      </Helmet>
      <div className='login-bodycontainer'>
        <div className='login-brandingcontainer'>
          <div className='login-logotopspacer'></div>
          <img alt='image' src='playground_assets/logo2.jpg' className='login-bravhurlogo' />
          <strong className='login-bravhurtext'>
            <span className='login-text'>for</span>
            <br></br>
            <span>Finance</span>
            <br></br>
          </strong>
        </div>
        <div className='login-logincontainer'>
          <div className='login-logintopspacer'></div>
          {reg_or_login == false ? (
            <form className='login-loginform' onSubmit={submitLogin}>
              <h1 className='login-logintext'>Login to Vanuatu Post</h1>
              <input
                type='text'
                id='username'
                name='username'
                required
                placeholder='username'
                autoComplete='username'
                pattern='^(?!_$)(?![-._])(?!.*[_.-]{2})[a-zA-Z0-9_.-]{3,}(?<![.-])$'
                className='login-loginusernanmeinput input'
                value={login_username}
                onChange={(e) => {
                  setLogin_Username(e.target.value);
                }}
              />
              <input
                type='password'
                id='password'
                name='password'
                required
                minLength='8'
                placeholder='password'
                autoComplete='current-password'
                pattern="^[a-zA-Z0-9!#$%&'()*+,-.\/:;<=>?@\[\]\^_{|}~]{6,}$"
                className='login-loginpasswordinput input'
                value={login_password}
                onChange={(e) => {
                  setLogin_Password(e.target.value);
                }}
              />
              <span className='login-loginlegaltext'>by logging in you agree to our Terms of Service and Data Policy</span>
              <button type='submit' className='login-loginbutton button'>
                LOGIN
              </button>
            </form>
          ) : (
            <form className='login-registerform' onSubmit={submitRegister}>
              <h1 className='login-registertext'>Register for Vanuatu Post Access</h1>
              <input
                type='text'
                id='name'
                name='name'
                required
                placeholder='your name'
                autoComplete='name'
                pattern="^[A-Za-z][A-Za-z'-]{2,}([ A-Za-z][A-Za-z'-]{2,}){1,3}$"
                className='login-nameinput input'
                value={register_name}
                onChange={(e) => {
                  setRegister_Name(e.target.value);
                }}
              />

              {userexists == true && <span className='newuserregister-text_pwd_warn'>username already exists</span>}

              <input
                type='text'
                id='reg_username'
                name='username'
                required
                placeholder='username'
                autoComplete='username'
                pattern='^(?!_$)(?![-._])(?!.*[_.-]{2})[a-zA-Z0-9_.-]{3,}(?<![.-])$'
                className='login-regusernameinput input'
                value={register_username}
                onChange={(e) => {
                  setRegister_Username(e.target.value);
                }}
              />
              {passwordMatch == false && <span className='newuserregister-text_pwd_warn'>password does not match</span>}

              <input
                type='password'
                id='reg_password'
                name='password'
                required
                minLength='8'
                placeholder='password'
                autoComplete='new-password'
                pattern="^[a-zA-Z0-9!#$%&'()*+,-.\/:;<=>?@\[\]\^_{|}~]{6,}$"
                className='login-regpasswordinput input'
                value={register_password}
                onChange={(e) => {
                  setRegister_Password(e.target.value);
                }}
              />
              <input
                type='password'
                id='password_repeat'
                name='password_repeat'
                required
                minLength='8'
                placeholder='repeat your password'
                autoComplete='new-password'
                pattern="^[a-zA-Z0-9!#$%&'()*+,-.\/:;<=>?@\[\]\^_{|}~]{6,}$"
                className='login-repeatpasswordinput input'
                value={register_password_repeat}
                onChange={(e) => {
                  setRegister_Password_Repeat(e.target.value);
                }}
              />
              <span className='login-reglegaltext'>by registering you agree to our Terms of Service and Data Policy</span>
              <button type='submit' className='login-registerbutton button'>
                REGISTER
              </button>
              <span className='login-text4' onClick={swap_state}>
                go back to login
              </span>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
