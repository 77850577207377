import React from "react";
import { createRoot } from "react-dom/client";
import storageService from "./services/storage";
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import { Provider } from "urql";
import client from "./services/api";
import "./teleporthq/style.css";
import TeamEditor from "./teleporthq/pages/teameditor";
import FinanceManager from "./teleporthq/pages/manager";
import Login from "./teleporthq/pages/login";
import Finance from "./teleporthq/pages/finance";
import Wallet from "./teleporthq/pages/wallet";
import Banking from "./teleporthq/pages/banking";
import Users from "./teleporthq/pages/users";
import Entity from "./teleporthq/pages/entity";
import EditProfile from "./teleporthq/pages/edit_profile";
import AddUser from "./teleporthq/pages/add_user";
import PasswordChange from "./teleporthq/pages/password_change";
import PasswordChangeDID from "./teleporthq/pages/password_change_did";
import Cash from "./teleporthq/pages/cash";
import Departments from "./teleporthq/pages/departments";
import DepartmentVPL from "./teleporthq/pages/departmentVPL";
import Demographics from "./teleporthq/pages/demographics";
import Pushpage from "./teleporthq/pages/push_page";
import Kycinfo from "./teleporthq/pages/kyc_info";
import EditDetails from "./teleporthq/pages/edit_details";
import DepartmentSettings from "./teleporthq/pages/department_settings";
import Depositmanager from "./teleporthq/pages/deposit_manager";
import Withdrawalmanager from "./teleporthq/pages/withdrawal_manager";
import Transfermanager from "./teleporthq/pages/transfer_manager";
import CashHolders from "./teleporthq/pages/cash_holders";
import Cbmsettings from "./teleporthq/pages/cbm_settings";
import Cbmaccounting from "./teleporthq/pages/cbm_accounting";
import Cbmgroups from "./teleporthq/pages/cbm_groups";
import Cbmeditgroup from "./teleporthq/pages/cbm_edit_group";
import Cbmuserallloans from "./teleporthq/pages/cbm_user_all_loans";
import Cbmuserloan from "./teleporthq/pages/cbm_user_loan";
import Cbmgoodloans from "./teleporthq/pages/cbm_good_loans";
import Cbmslowloans from "./teleporthq/pages/cbm_slow_loans";
import Cbmbadloans from "./teleporthq/pages/cbm_bad_loans";

const isAuthenticated = () => {
  const token = storageService.getStorageData("access_token");
  if (token == null) {
    return false;
  } else {
    return true;
  }
};

const PrivateRouteNew = () => {
  return isAuthenticated() === true ? <Outlet /> : <Navigate to='/login' />;
};

const App = () => {
  return (
    <Provider value={client}>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<PrivateRouteNew />}>
            <Route exact path='/' element={<FinanceManager />} />
          </Route>

          <Route exact path='/team' element={<PrivateRouteNew />}>
            <Route exact path='/team' element={<TeamEditor />} />
          </Route>

          <Route exact path='/new_push' element={<PrivateRouteNew />}>
            <Route exact path='/new_push' element={<Finance />} />
          </Route>

          <Route exact path='/banking' element={<PrivateRouteNew />}>
            <Route exact path='/banking' element={<Banking />} />
          </Route>

          <Route exact path='/users' element={<PrivateRouteNew />}>
            <Route exact path='/users' element={<Users />} />
          </Route>

          <Route exact path='/add_user' element={<PrivateRouteNew />}>
            <Route exact path='/add_user' element={<AddUser />} />
          </Route>

          <Route exact path='/cash' element={<PrivateRouteNew />}>
            <Route exact path='/cash' element={<Cash />} />
          </Route>

          <Route exact path='/departments' element={<PrivateRouteNew />}>
            <Route exact path='/departments' element={<Departments />} />
          </Route>

          <Route exact path='/demographics/:island_uid' element={<PrivateRouteNew />}>
            <Route exact path='/demographics/:island_uid' element={<Demographics />} />
          </Route>

          <Route exact path='/demographics' element={<PrivateRouteNew />}>
            <Route exact path='/demographics' element={<Demographics />} />
          </Route>

          <Route exact path='/depositmanager/:entity_uid' element={<PrivateRouteNew />}>
            <Route exact path='/depositmanager/:entity_uid' element={<Depositmanager />} />
          </Route>

          <Route exact path='/withdrawalmanager/:entity_uid' element={<PrivateRouteNew />}>
            <Route exact path='/withdrawalmanager/:entity_uidd' element={<Withdrawalmanager />} />
          </Route>

          <Route exact path='/transfermanager/:entity_uid' element={<PrivateRouteNew />}>
            <Route exact path='/transfermanager/:entity_uidd' element={<Transfermanager />} />
          </Route>

          <Route exact path='/cash_holders' element={<PrivateRouteNew />}>
            <Route exact path='/cash_holders' element={<CashHolders />} />
          </Route>

          <Route exact path='/cbm_settings' element={<PrivateRouteNew />}>
            <Route exact path='/cbm_settings' element={<Cbmsettings />} />
          </Route>

          <Route exact path='/cbm_accounting' element={<PrivateRouteNew />}>
            <Route exact path='/cbm_accounting' element={<Cbmaccounting />} />
          </Route>

          <Route exact path='/cbm_groups' element={<PrivateRouteNew />}>
            <Route exact path='/cbm_groups' element={<Cbmgroups />} />
          </Route>

          <Route exact path='/cbm_good_loans' element={<PrivateRouteNew />}>
            <Route exact path='/cbm_good_loans' element={<Cbmgoodloans />} />
          </Route>

          <Route exact path='/cbm_slow_loans' element={<PrivateRouteNew />}>
            <Route exact path='/cbm_slow_loans' element={<Cbmslowloans />} />
          </Route>

          <Route exact path='/cbm_bad_loans' element={<PrivateRouteNew />}>
            <Route exact path='/cbm_bad_loans' element={<Cbmbadloans />} />
          </Route>

          <Route exact path='/entity/:entity_uid' element={<PrivateRouteNew />}>
            <Route exact path='/entity/:entity_uid' element={<Entity />} />
          </Route>

          <Route exact path='/push/:pushId' element={<PrivateRouteNew />}>
            <Route exact path='/push/:pushId' element={<Pushpage />} />
          </Route>

          <Route exact path='/kyc_info/:did_uid' element={<PrivateRouteNew />}>
            <Route exact path='/kyc_info/:did_uid' element={<Kycinfo />} />
          </Route>

          <Route exact path='/department_settings/:dep_uid' element={<PrivateRouteNew />}>
            <Route exact path='/department_settings/:dep_uid' element={<DepartmentSettings />} />
          </Route>

          <Route exact path='/edit_group/:group_uid' element={<PrivateRouteNew />}>
            <Route exact path='/edit_group/:group_uid' element={<Cbmeditgroup />} />
          </Route>

          <Route exact path='/cbm_user_all_loans/:entity_uid' element={<PrivateRouteNew />}>
            <Route exact path='/cbm_user_all_loans/:entity_uid' element={<Cbmuserallloans />} />
          </Route>

          <Route exact path='/cbm_user_loan/:contract_uid/:entity_uid/:account_no/:other_entity' element={<PrivateRouteNew />}>
            <Route exact path='/cbm_user_loan/:contract_uid/:entity_uid/:account_no/:other_entity' element={<Cbmuserloan />} />
          </Route>

          <Route exact path='/edit_profile/:ent_uid/:profile_uid' element={<PrivateRouteNew />}>
            <Route exact path='/edit_profile/:ent_uid/:profile_uid' element={<EditProfile />} />
          </Route>

          <Route exact path='/password_change/:user_uid/:entity_uid' element={<PrivateRouteNew />}>
            <Route exact path='/password_change/:user_uid/:entity_uid' element={<PasswordChange />} />
          </Route>

          <Route exact path='/password_change_did/:did_uid/:entity_uid' element={<PrivateRouteNew />}>
            <Route exact path='/password_change_did/:did_uid/:entity_uid' element={<PasswordChangeDID />} />
          </Route>

          <Route exact path='/wallet/:account_no' element={<PrivateRouteNew />}>
            <Route exact path='/wallet/:account_no' element={<Wallet />} />
          </Route>

          <Route exact path='/department/:department_id' element={<PrivateRouteNew />}>
            <Route exact path='/department/:department_id' element={<DepartmentVPL />} />
          </Route>

          <Route exact path='/edit_details/:input_uid/:page_to_show' element={<PrivateRouteNew />}>
            <Route exact path='/edit_details/:input_uid/:page_to_show' element={<EditDetails />} />
          </Route>

          <Route exact path='/login' element={<Login />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};

const container = document.getElementById("app");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab='home' />);
