import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation } from "urql";

import { Helmet } from "react-helmet";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Fab from "@mui/material/Fab";
import { Link } from "react-router-dom";
import { Camera } from "react-camera-pro";
import uuid from "react-uuid";
import storageService from "../../services/storage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import KycContent from "../components/kyc_content";

import { dataURItoBlob } from "../../services/utilities";

import { blobToWebP } from "webp-converter-browser";

import Header from "../components/header";
import Investmentcontainer from "../components/investment_container";
import UserLoanitem from "../components/user_loan_item";
import "./cbm_user_all_loans.css";

const entity_query = `
    query entityQuery($id:ID!) {

      investmentStatistics(entityUid:$id)

      getEntityLoans(entityUid:$id) {
        id
        files {
          id
          path
        }
        balance
        debtAccount {
          id
          accountNo
        }
        client {
          id
          client {
            id
            name
            entity {
              id
              digitalId {
                id
                contactDetail {
                  id
                  island {
                    id
                    name
                  }
                  location
                }
              }
              confirmedPhone {
                id
                phone
              }
              phone {
                id
                phone
              }
            }
          }
        }
        createdBy
        originalDeadline
        enforcmentCutoff
        contractStage
        contractAmount
        _Created__
      }

      getEntity(id:$id) {
        id   
        profiles {
            id
            name
            avatar {
                id
                path
            }
        }
        status
        user {
            id
            username
        }
        email {
          id
          email
        }
        confirmedEmail {
          id
          email
        }

        phone {
          id
          phone
        }
        confirmedPhone {
          id
          phone
        }        

        digitalId {
          id
          loginAllowed

                entity {
                    id
                    profiles {
                        id
                        avatar {
                            id
                            path
                        }                        
                    }

                    email{
                      id
                      email
                    }      
                    confirmedEmail {
                      id
                      email
                    }      
                    phone{
                      id
                      phone
                    }      
                    confirmedPhone{
                      id
                      phone
                    } 


                }
                idImage {
                    id
                    path
                    status
                    reasoning
                }
                idNo {
                    id
                    status
                    idNo
                }
                personalDetail {
                    id
                    name
                    dobYear
                    dobMonth
                    dobDay
                    gender
                    status
                    householdHead
                }
                contactDetail {
                    id
                    location
                    island {
                        id
                        name
                    } 
                    status                   
                }


        }
      }

    }
  `;

let SUBMIT_DEPOSIT = `
        mutation submitInvestment($entity:ID!, $amount:Int!, $transactionId:String!) {
          submitInvestment(entity: $entity, amount: $amount, transactionId: $transactionId)
        }
      `;

let SUBMIT_REQUEST = `
        mutation submitLoanRequest($entity:ID!, $amount:Int!, $imageId:ID!) {
          submitLoanRequest(entity: $entity, amount: $amount, imageId:$imageId)
        }
      `;

let db_name = "";
try {
  db_name = storageService.getStorageData("access_point").split("/")[2].split(".")[0];
} catch {}

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const zeroPad = (num, places) => String(num).padStart(places, "0");

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const trans_uid = uuid();

const Cbmuserallloans = (props) => {
  let SAVE_PROFILE = `
        mutation saveAdminFile($uid:ID!, $mime_type:String!, $path:String!) {
          saveAdminFile(data:[{id:$uid, mimeType:$mime_type, path:$path, digitalIdBacklink:{id:"<did_uid>"}}]) {
            id
          }
        }
      `;

  const [status, setStatus] = useState("NONE");

  const [fabon, setfabon] = useState(true);
  const [fixeropen, setfixeropen] = useState(false);
  const [cameradirection, setcameradirection] = useState("environment");
  const [legacyfile, setlegacyfile] = useState("");

  const camera = useRef(null);
  const [image, setImage] = useState(null);
  const [showprogress, setShowProgress] = useState(false);
  const [thisImage, setThisImage] = useState("");

  const [depositvalue, setDepositvalue] = useState(null);
  const [requestvalue, setRequestvalue] = useState(null);

  const [result_dep, submit_deposit] = useMutation(SUBMIT_DEPOSIT);
  const [result_prof, save_profile] = useMutation(SAVE_PROFILE);
  const [result_req, submit_request] = useMutation(SUBMIT_REQUEST);

  let entity_uid = props.match.params.entity_uid;

  let _F = [];
  let _G = [];
  let _E = [];
  let _M = [];

  const [result, reexecuteQuery] = useQuery({
    query: entity_query,
    variables: { id: entity_uid },
    requestPolicy: "cache-and-network",
  });
  const { data, fetching, error } = result;

  useEffect(() => {
    if (data) {
      if ((data.investmentStatistics.limit > 0) & (status == "NONE")) {
        setStatus("RECLOA");
      }
    }
  }, [data]);

  if (data) {
    SAVE_PROFILE = SAVE_PROFILE.replace("<did_uid>", data.getEntity.digitalId.id);

    let tmp_data = {};
    tmp_data["getdID"] = data.getEntity.digitalId;
    _F.push(<KycContent key='test01' data={tmp_data} hide={"TRUE"}></KycContent>);
    _G.push(
      <Investmentcontainer
        containerType='EDITOR'
        entity_uid={data.getEntity.id}
        data={data.investmentStatistics}
        setFee={setStatus}
        loans={data.getEntityLoans}
        fee={status}></Investmentcontainer>
    );
    data.getEntityLoans.map((loan) => {
      _E.push(
        <Link
          to={"/cbm_user_loan/" + loan.id + "/" + loan.client.client.entity.id + "/" + loan.debtAccount[0].accountNo + "/" + loan.createdBy}
          style={{ width: "100%" }}
          key={loan.id + "x"}>
          <UserLoanitem data={loan} key={loan.id}></UserLoanitem>
        </Link>
      );
    });
  }

  const deposit_first = (event) => {
    event.preventDefault();
    setStatus("REQSUBCONF");
  };

  const request_first = (event) => {
    event.preventDefault();
    setStatus("TAKEPIC");
  };

  const inv_dep = (event) => {
    event.preventDefault();

    if (depositvalue !== null) {
      if (depositvalue > 0) {
        submit_deposit({ amount: ~~depositvalue, entity: entity_uid, transactionId: trans_uid }).then((result_dep) => {
          if (result_dep.data !== null) {
            setStatus("SUCCESS");
          } else {
            setStatus("FAILED");
          }
        });
        //window.location.reload();
      }
    }
  };

  const loan_req = (event) => {
    event.preventDefault();

    if (requestvalue !== null) {
      if (requestvalue > 0) {
        submit_request({ amount: ~~requestvalue, entity: entity_uid, imageId: thisImage }).then((result_req) => {
          if (result_req.data !== null) {
            setStatus("SUCCESS");
          } else {
            setStatus("FAILED");
          }
        });
        //window.location.reload();
      }
    }
  };

  const refr = (event) => {
    event.preventDefault();
    window.location.reload();
  };

  const switchfixer = () => {
    if (fixeropen == true) {
      setfixeropen(false);
      setfabon(true);
    } else {
      setfixeropen(true);
      setfabon(false);
    }
  };

  const switchcamera = () => {
    if (cameradirection == "environment") {
      setcameradirection("user");
      camera.current.switchCamera();
    } else {
      setcameradirection("environment");
      camera.current.switchCamera();
    }
  };

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      setfixeropen(false);
      setImage(imageDataUrl);
    }
  };

  return (
    <div className='cbmuserallloans-container'>
      <Helmet>
        <title>Loan Manager - CBM-UI</title>
        <meta property='og:title' content='Loan Manager - CBM-UI' />
      </Helmet>
      <Header></Header>
      <div className='cbmuserallloans-body'>
        <div className='cbmuserallloans-maincontainer'>
          {(status !== "SUCCESS") & (status !== "FAILED") & (status !== "TAKEPIC") ? (
            <>
              {_F}
              {_G}
            </>
          ) : (
            <></>
          )}
          {status == "REQSUB" && (
            <form className='cbmuserallloans-paybackloan' onSubmit={deposit_first}>
              <span className='cbmuserallloans-text'>PAY Subscription FEE</span>
              <input
                type='number'
                min='1'
                step='1'
                required
                placeholder='amount'
                className='cbmuserallloans-textinput input'
                value={depositvalue}
                onChange={(e) => {
                  setDepositvalue(e.target.value);
                }}
              />
              <button type='submit' className='cbmuserallloans-button button'>
                -&gt; NEXT -&gt;
              </button>
            </form>
          )}
          {status == "REQSUBCONF" && (
            <form className='cbmuserallloans-confirmloan' onSubmit={inv_dep}>
              <span className='cbmuserallloans-text01'>Confirm the Deposit Amount</span>
              <span className='cbmuserallloans-text02'>{numberWithCommas(depositvalue) + " VT"}</span>
              <button type='submit' className='cbmuserallloans-button1 button'>
                DEPOSIT NOW
              </button>
            </form>
          )}
          {status == "RECLOA" && (
            <form className='cbmuserallloans-paybackloan1' onSubmit={request_first}>
              <span className='cbmuserallloans-text03'>REQUEST a loan</span>
              <span className='cbmuserallloans-text04'>clients LIMIT</span>
              <span className='cbmuserallloans-text05'>{numberWithCommas(data.investmentStatistics.limit) + " VT"}</span>
              <input
                type='number'
                min='1'
                step='1'
                required
                max={data.investmentStatistics.limit}
                placeholder='amount'
                className='cbmuserallloans-textinput1 input'
                value={requestvalue}
                onChange={(e) => {
                  setRequestvalue(e.target.value);
                }}
              />
              <div className='cbmuserallloans-container1' style={{ display: "none" }}>
                <div className='cbmuserallloans-container2'>
                  <span>ewfwoejwow.jpg</span>
                  <svg viewBox='0 0 1024 1024' className='cbmuserallloans-icon'>
                    <path d='M768 298.667v554.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-426.667c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-554.667zM725.333 213.333v-42.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-170.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v42.667h-170.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h42.667v554.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h426.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-554.667h42.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM384 213.333v-42.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v42.667zM384 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM554.667 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667z'></path>
                  </svg>
                </div>
                <div className='cbmuserallloans-container3'>
                  <span>ddktr.jpg</span>
                  <svg viewBox='0 0 1024 1024' className='cbmuserallloans-icon2'>
                    <path d='M768 298.667v554.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-426.667c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-554.667zM725.333 213.333v-42.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-170.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v42.667h-170.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h42.667v554.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h426.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-554.667h42.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM384 213.333v-42.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v42.667zM384 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM554.667 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667z'></path>
                  </svg>
                </div>
              </div>
              <span className='cbmuserallloans-text08' style={{ display: "none" }}>
                Attach Files +
              </span>
              <button type='submit' className='cbmuserallloans-button2 button'>
                -&gt; NEXT -&gt;
              </button>
              <span className='cbmuserallloans-text09'>
                {"Available Funds: " + numberWithCommas(data.investmentStatistics.fundsAvailable) + " VT"}
              </span>
            </form>
          )}
          {status == "TAKEPIC" && (
            <div className='depositmanager-takepicturebox'>
              <form className='kycedititem-page05' id='pp_form'>
                <span className='kycedititem-text18'>Take a profile picture of the Client</span>
                {image ? (
                  <div>
                    <img src={image} className='uploadid-uploadid' style={{ width: "100%", height: "auto" }} />
                    {showprogress && (
                      <Box sx={{ width: "100%" }}>
                        <LinearProgress />
                      </Box>
                    )}
                  </div>
                ) : (
                  <Camera ref={camera} facingMode={cameradirection} aspectRatio={1 / 1} className='uploadid-uploadid' />
                )}

                <div className='registar-camerafixer'>
                  <div className='registar-headlinecamerafixer' onClick={switchfixer}>
                    <span className='registar-camerafixertext'>Fix Camera issues</span>
                    {fixeropen == false && (
                      <svg viewBox='0 0 1024 1024' className='registar-openicon'>
                        <path d='M366 708l196-196-196-196 60-60 256 256-256 256z'></path>
                      </svg>
                    )}
                    {fixeropen == true && (
                      <svg viewBox='0 0 1024 1024' className='registar-closeicon'>
                        <path d='M316 658l-60-60 256-256 256 256-60 60-196-196z'></path>
                      </svg>
                    )}
                  </div>
                  {fixeropen == true && (
                    <div className='registar-fixcameracollapsible'>
                      <div className='registar-fixcamerarefreshcontainer'>
                        <span className='registar-fixcamerarefreshtext'>1, Refresh the page -&gt;</span>
                        <button
                          className='registar-refreshfixcamera button'
                          onClick={() => {
                            window.location.reload();
                          }}>
                          REFRESH
                        </button>
                      </div>
                      <div className='registar-fixcameraswitchcontainer'>
                        <span className='registar-fixcameraswitchtext'>2, Try to Switch the Camera Direction -&gt;</span>
                        <button className='registar-switchfixedcamera button' onClick={switchcamera}>
                          SWITCH
                        </button>
                      </div>

                      <div className='registar-fixcamerauploadcontainer'>
                        <span className='registar-fixcamerauploadtext'>3, Select an Image</span>
                        <div className='registar-fixcamerauploadform'>
                          <input
                            type='file'
                            placeholder='select a picture'
                            className='registar-fixcamerauploadinput input'
                            accept='image/*'
                            onChange={onFileChange}
                          />
                          <svg viewBox='0 0 1024 1024' className='registar-uploadfixcamera'>
                            <path d='M362 214l214 298-214 298h-192l214-298-214-298h192zM662 214l212 298-212 298h-192l212-298-212-298h192z'></path>
                          </svg>
                        </div>
                      </div>

                      <div className='registar-fixcameraexplanationcontainer'>
                        <span className='registar-fixcameraexplanationtext'>
                          If your camera is permanently not switching on or blocked, you may forget to authorize Camera Access when you
                          first used the application. Please go to your Browsers SETTINGS -&gt; Site Settings -&gt; Camera to authorize it,
                          than refresh the page.
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                {image ? (
                  <div className='kycedititem-container25'>
                    <button
                      type='button'
                      className='kycedititem-button15 button'
                      onClick={() => {
                        if (showprogress == false) {
                          setImage(null);
                        }
                      }}>
                      New Picture
                    </button>

                    <button
                      type='button'
                      className='kycedititem-button16 button'
                      onClick={async () => {
                        if (showprogress == false) {
                          var cf = dataURItoBlob(image);

                          setShowProgress(true);

                          const webpBlob = await blobToWebP(cf);

                          const options = {
                            method: "PUT",
                            headers: {
                              AccessKey: process.env.REACT_APP_STORAGE_ACCESSKEY,
                              "content-type": "application/octet-stream",
                            },
                            body: webpBlob,
                          };

                          const img_uid = uuid();

                          const pathprepare = "/" + db_name + "/Deposit/" + entity_uid + "/" + img_uid;

                          const uriimg = process.env.REACT_APP_STORAGE_BASE_HOST + pathprepare + ".webp";

                          setThisImage(img_uid);

                          fetch(uriimg, options)
                            .then((response) => response.json())
                            .then((response) => {
                              save_profile({ uid: img_uid, mime_type: "image/webp", path: pathprepare + ".webp" }).then((result) => {
                                setShowProgress(false);

                                try {
                                  setStatus("RECLOACONF");
                                } catch {
                                  toast.error("Some error happened..");
                                }
                              });
                            })
                            .catch((err) => {
                              console.error(err);
                              toast.error("Upload error..");
                            });
                        }
                      }}>
                      Submit Picture
                    </button>
                  </div>
                ) : (
                  <>
                    {fabon == true && (
                      <Fab
                        color='primary'
                        aria-label='camera'
                        size='large'
                        style={{ position: "fixed", bottom: "50px" }}
                        onClick={() => {
                          let current_photo = camera.current.takePhoto();
                          setImage(current_photo);
                        }}>
                        <svg viewBox='0 0 1024 1024' className='floatinccameraicon'>
                          <path d='M1024 810.667v-469.333c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-147.84l-72.661-109.013c-7.765-11.52-20.736-18.987-35.499-18.987h-256c-13.909 0.085-27.307 6.741-35.499 18.987l-72.661 109.013h-147.84c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v469.333c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h768c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496zM938.667 810.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-768c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-469.333c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c14.763 0 27.733-7.467 35.499-18.987l72.661-109.013h210.347l72.661 109.013c8.192 12.245 21.589 18.901 35.499 18.987h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165zM725.333 554.667c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464-112.299 23.936-150.869 62.464-62.464 91.989-62.464 150.869 23.936 112.299 62.464 150.869 91.989 62.464 150.869 62.464 112.299-23.936 150.869-62.464 62.464-91.989 62.464-150.869zM640 554.667c0 35.371-14.293 67.285-37.504 90.496s-55.125 37.504-90.496 37.504-67.285-14.293-90.496-37.504-37.504-55.125-37.504-90.496 14.293-67.285 37.504-90.496 55.125-37.504 90.496-37.504 67.285 14.293 90.496 37.504 37.504 55.125 37.504 90.496z'></path>
                        </svg>
                      </Fab>
                    )}
                  </>
                )}
              </form>
            </div>
          )}
          {status == "RECLOACONF" && (
            <form className='cbmuserallloans-confirmloan1' onSubmit={loan_req}>
              <span className='cbmuserallloans-text11'>Confirm the amount</span>
              <span className='cbmuserallloans-text12'>{numberWithCommas(requestvalue) + " VT"}</span>
              <button type='submit' className='cbmuserallloans-button3 button'>
                PAY OUT NOW
              </button>
            </form>
          )}
          {status == "SUCCESS" && (
            <form className='cbmuserallloans-successloan' onSubmit={refr}>
              <span className='cbmuserallloans-text13'>SUCCESS</span>
              <svg viewBox='0 0 1024 1024' className='cbmuserallloans-icon4'>
                <path d='M864 128l-480 480-224-224-160 160 384 384 640-640z'></path>
              </svg>
              <button type='submit' className='cbmuserallloans-button4 button'>
                FINISH
              </button>
            </form>
          )}
          {status == "FAILED" && (
            <form className='cbmuserallloans-errorloan' onSubmit={refr}>
              <span className='cbmuserallloans-text14'>
                <span>ERROR</span>
                <br></br>
                <br></br>
                <span> TRY AGAIN</span>
              </span>
              <svg viewBox='0 0 1024 1024' className='cbmuserallloans-icon6'>
                <path d='M874.040 149.96c-96.706-96.702-225.28-149.96-362.040-149.96s-265.334 53.258-362.040 149.96c-96.702 96.706-149.96 225.28-149.96 362.040s53.258 265.334 149.96 362.040c96.706 96.702 225.28 149.96 362.040 149.96s265.334-53.258 362.040-149.96c96.702-96.706 149.96-225.28 149.96-362.040s-53.258-265.334-149.96-362.040zM896 512c0 82.814-26.354 159.588-71.112 222.38l-535.266-535.268c62.792-44.758 139.564-71.112 222.378-71.112 211.738 0 384 172.262 384 384zM128 512c0-82.814 26.354-159.586 71.112-222.378l535.27 535.268c-62.794 44.756-139.568 71.11-222.382 71.11-211.738 0-384-172.262-384-384z'></path>
              </svg>
              <button type='submit' className='cbmuserallloans-button5 button'>
                RESTART
              </button>
            </form>
          )}
          {(status !== "SUCCESS") & (status !== "FAILED") & (status !== "TAKEPIC") ? (
            <div className='cbmuserallloans-all-loans'>{_E}</div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cbmuserallloans;
