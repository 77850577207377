import React, { useState } from "react";

import { Helmet } from "react-helmet";
import { useQuery } from "urql";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import Header from "../components/header";
import Demographicstatistics from "../components/demographicstatistics";
import "./demographics.css";

const demo_stats = `
    query demographicsStatistics($island:String) {
      allIsland(pageLength:150){
        id
        name
      }      
      demographicsStatistics(island:$island) {

        percentageOfWoman
        ageGroups
        schoolLevels
        relationshipStatuses
        percentageOfSingleParents
        avgNoOfKids
        percentageOfGardens
        selfdisabilities
        familydisabilities
      }
    }

  `;

const Demographics = (props) => {
  let history = useNavigate();
  let { island_uid } = useParams();

  const [isl, setIsl] = useState(island_uid);

  const [result, reexecuteQuery] = useQuery({
    query: demo_stats,
    variables: { island: isl },
    requestPolicy: "cache-and-network",
  });

  let _F = [];
  let _X = [];

  const { data, fetching, error } = result;

  _X.push(
    <option value='NONE' id='NONE' key='selisl'>
      Select Island to filter data
    </option>
  );

  try {
    data.allIsland.map((island) => {
      _X.push(
        <option value={island.id} id={island.id} key={island.id}>
          {island.name}
        </option>
      );
    });
  } catch {}

  if (data) {
    _F.push(<Demographicstatistics data={data.demographicsStatistics} key='demo'></Demographicstatistics>);
  }

  const clicked = (e) => {
    history("/demographics/" + e);
    window.location.reload();
  };

  return (
    <div className='demographics-container'>
      <Helmet>
        <title>Demographics - Vanuatu Post</title>
        <meta property='og:title' content='Vanuatu Post - Demographics' />
      </Helmet>
      <Header rootClassName='header-root-class-name28'></Header>
      <div className='demographics-contentframe'>
        <div className='demographics-contentcontainer'>
          <div className='demographics-homepanel'>
            <div className='demographics-islandselector'>
              <select
                className='demographics-island-select'
                value={isl}
                onChange={(e) => {
                  clicked(e.target.value);
                }}>
                {_X}
              </select>
              <svg viewBox='0 0 1024 1024' className='demographics-searchicon'>
                <path d='M992.262 871.396l-242.552-206.294c-25.074-22.566-51.89-32.926-73.552-31.926 57.256-67.068 91.842-154.078 91.842-249.176 0-212.078-171.922-384-384-384-212.076 0-384 171.922-384 384s171.922 384 384 384c95.098 0 182.108-34.586 249.176-91.844-1 21.662 9.36 48.478 31.926 73.552l206.294 242.552c35.322 39.246 93.022 42.554 128.22 7.356s31.892-92.898-7.354-128.22zM384 640c-141.384 0-256-114.616-256-256s114.616-256 256-256 256 114.616 256 256-114.614 256-256 256z'></path>
              </svg>
            </div>

            {_F}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Demographics;
