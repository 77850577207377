import React from "react";
import { useNavigate, Link } from "react-router-dom";

import "./main_account_item.css";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const MainAccountItem = (props) => {
  let history = useNavigate();

  const openledger = () => {
    history("/wallet/" + props.data.accountNo);
  };

  return (
    <div className='main-account-item-main-account-item'>
      <div className='main-account-item-container'>
        <span className='main-account-item-text'>MAIN</span>

        {props.visibility == "TRUE" ? (
          <span className='main-account-item-text1'>{numberWithCommas(props.data.balance) + " VT"}</span>
        ) : (
          <span
            className='main-account-item-text1'
            style={{
              color: "transparent",
              textShadow: "0 0 16px #0099ccff",
            }}>
            {numberWithCommas(props.data.balance) + " VT"}
          </span>
        )}
      </div>
      <div className='main-account-item-container1'>
        <Link to={"/depositmanager/" + props.entity_uid}>
          <svg viewBox='0 0 1024 1024' className='main-account-item-icon'>
            <path d='M853.333 640v170.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-597.333c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-170.667c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v170.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h597.333c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-170.667c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM554.667 537.003v-409.003c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v409.003l-140.501-140.501c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c3.925 3.925 8.619 7.083 13.824 9.259s10.795 3.243 16.341 3.243c10.923 0 21.845-4.181 30.165-12.501l213.333-213.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z'></path>
          </svg>
        </Link>
        <Link to={"/withdrawalmanager/" + props.entity_uid}>
          <svg viewBox='0 0 1024 1024' className='main-account-item-icon2'>
            <path d='M853.333 640v170.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-597.333c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-170.667c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v170.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h597.333c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-170.667c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM469.333 230.997v409.003c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-409.003l140.501 140.501c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-213.333-213.333c-4.096-4.096-8.789-7.168-13.824-9.259s-10.539-3.243-16.341-3.243c-5.547 0-11.136 1.067-16.341 3.243-5.035 2.091-9.728 5.163-13.824 9.259l-213.333 213.333c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0z'></path>
          </svg>
        </Link>
        <Link to={"/transfermanager/" + props.entity_uid}>
          <svg viewBox='0 0 1024 1024' className='main-account-item-icon4'>
            <path d='M979.755 96.811c1.835-6.443 2.133-13.397 0.64-20.309-1.024-4.821-2.901-9.557-5.589-13.867-2.731-4.352-6.187-8.107-10.155-11.179-4.992-3.84-10.624-6.4-16.469-7.723s-12.032-1.451-18.176-0.171c-1.792 0.384-3.627 0.896-5.376 1.493l-0.896 0.299-852.48 298.368c-10.752 3.755-19.925 11.776-24.917 22.955-9.557 21.547 0.128 46.763 21.675 56.32l369.024 164.011 164.011 369.024c4.608 10.368 13.355 18.901 24.875 22.955 22.229 7.765 46.592-3.925 54.357-26.197l298.368-852.437c0.427-1.152 0.811-2.304 1.152-3.499zM459.904 503.765l-258.901-115.029 575.275-201.387zM836.651 247.723l-201.387 575.275-115.029-258.901z'></path>
          </svg>
        </Link>

        <div className='main-account-item-container2'></div>
        <div className='main-account-item-container3'></div>
        <svg viewBox='0 0 1024 1024' className='main-account-item-icon6' onClick={openledger}>
          <path d='M414.165 798.165l256-256c16.683-16.683 16.683-43.691 0-60.331l-256-256c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0z'></path>
        </svg>
      </div>
    </div>
  );
};

export default MainAccountItem;
