import React, { useState } from "react";
import { useMutation } from "urql";

import "./accounting_settings.css";

const savesettings = `mutation saveAccountingSettings($texAmount:Float, $costPercentage:Float, $investorProfitMaximum:Float, $reInvestmentPercentage:Float){
    saveAccountingSettings(data:[{id:"accounting-settings-0000",texAmount:$texAmount, costPercentage:$costPercentage, investorProfitMaximum:$investorProfitMaximum, reInvestmentPercentage:$reInvestmentPercentage}]){
        id
    }
    }
  `;

const Accountingsettings = (props) => {
  const [result, domutate] = useMutation(savesettings);

  const [tex, setTax] = useState(props.data.getAccountingSettings.texAmount);
  const [cost, setCost] = useState(props.data.getAccountingSettings.costPercentage);
  const [maxInvestorProfit, setMaxInvestorProfit] = useState(props.data.getAccountingSettings.investorProfitMaximum);
  const [reinvest, setReInvest] = useState(props.data.getAccountingSettings.reInvestmentPercentage);

  async function savedata(event) {
    event.preventDefault();
    domutate({
      texAmount: +tex,
      costPercentage: +cost,
      investorProfitMaximum: +maxInvestorProfit,
      reInvestmentPercentage: +reinvest,
    });
    window.location.reload();
  }

  return (
    <div className='accountingsettings-container'>
      <form className='accountingsettings-accountingsettings' onSubmit={savedata}>
        <span className='accountingsettings-text'>Accounting Settings</span>
        <span className='accountingsettings-text1'>Tax Amount</span>
        <input
          type='number'
          max='100'
          min='0'
          step='0.1'
          value={tex}
          onChange={(e) => {
            setTax(e.target.value);
          }}
          required
          placeholder='tax percentage'
          className='accountingsettings-textinput input'
        />
        <span className='accountingsettings-text2'>Maximum Operational Cost Percentage</span>
        <input
          type='number'
          max='100'
          min='0'
          step='0.1'
          value={cost}
          onChange={(e) => {
            setCost(e.target.value);
          }}
          required
          placeholder='maximum cost percentage'
          className='accountingsettings-textinput1 input'
        />
        <span className='accountingsettings-text3'>Maximum Investor Profit Percentage</span>
        <input
          type='number'
          max='100'
          min='0'
          step='0.1'
          value={maxInvestorProfit}
          onChange={(e) => {
            setMaxInvestorProfit(e.target.value);
          }}
          required
          placeholder='maximum investor profit percentage'
          className='accountingsettings-textinput2 input'
        />
        <span className='accountingsettings-text4'>Re-Investment Percentage</span>
        <input
          type='number'
          min='1'
          step='1'
          value={reinvest}
          onChange={(e) => {
            setReInvest(e.target.value);
          }}
          required
          placeholder='re-investment percentage'
          className='accountingsettings-textinput3 input'
        />
        <div className='accountingsettings-container1'>
          <button type='submit' className='accountingsettings-button button'>
            SAVE
          </button>
        </div>
      </form>
    </div>
  );
};

export default Accountingsettings;
