import React from "react";

import "./ledgeritemnegative.css";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const Ledgeritemnegative = (props) => {
  let _d = new Date(props.data.timestamp * 1000);

  return (
    <div className='ledgeritemnegative-ledgeritemnegative'>
      <span className='ledgeritemnegative-typetext'>{props.data.transaction_type}</span>
      <span className='ledgeritemnegative-nametext'>{props.data.participant}</span>
      <span className='ledgeritemnegative-amounttext'>{numberWithCommas(props.data.amount) + " VT"}</span>
      <div className='ledgeritemnegative-bottomcont'>
        <span className='ledgeritemnegative-bytext'>{props.data.transacted_by}</span>
        <span className='ledgeritemnegative-datetext'>{_d.toISOString().replace("T", " ")}</span>
      </div>
    </div>
  );
};

export default Ledgeritemnegative;
