import React, { useState } from "react";

import { Helmet } from "react-helmet";
import { useQuery } from "urql";

import LedgerView from "../components/ledger_view";

import Header from "../components/header";

import "./wallet.css";

const contractCheck = `query getMadruhamSummary($page:Int, $accountNo:String!){
    getMadruhamSummary(accountNo:$accountNo, transactionDirection:"ALL", page:$page){
      madruhamAccount {
        id
        name
        accountNo
      }  
      balance
      ts
      tsBalance
      ledgerItems
    }
    }
  `;

const Wallet = (props) => {
  let account_no = props.match.params.account_no;

  const [page, setPage] = useState(0);

  const [result, reexecuteQuery] = useQuery({
    query: contractCheck,
    requestPolicy: "cache-and-network",
    variables: { page: page, accountNo: account_no },
  });

  const { data, fetching, error } = result;

  let _F = [];

  if (data) {
    _F.push(<LedgerView rootClassName='ledgerview-root-class-name' data={data} set_page={setPage} page={page}></LedgerView>);
  }

  return (
    <div className='wallet-container'>
      <Helmet>
        <title>Team - Vanuatu Post</title>
        <meta property='og:title' content='Vanuatu post - Business' />
      </Helmet>
      <Header rootClassName='header-root-class-name20'></Header>
      <div className='wallet-contentframe'>
        <div className='wallet-contentcontainer'>
          <div className='wallet-homepanel'>{_F}</div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
