import React, { useState } from "react";
import { useMutation } from "urql";

import storageService from "../../services/storage";
import ProfileListItem from "./profile_listitem";
import CredentialListItem from "./credential_listitem";
import { truncate } from "../../services/utilities";
import PersonalFinance from "./personal_finance";
import { Link } from "react-router-dom";

import Investmentcontainer from "./investment_container";

import "./entity_editor.css";

const RECORD_EMAIL = `
        mutation registerEmail($email: String!, $entity: String!) {
          registerEmail(email:$email, entity: $entity) {
            id
            email
          }
        }
      `;

const RECORD_PHONE = `
        mutation registerPhone($phone: String!, $entity: String!) {
          registerPhone(phone:$phone, entity: $entity) {
            id
            phone
          }
        }
      `;

const RECORD_username = `
        mutation saveUser($username: String!, $entity: ID!) {
          saveUser(data:[{username:$username, password:"notarealpassword",entity: [{id:$entity}]}]) {
            id
            username
          }
        }
      `;

const entity_status = `
        mutation saveUser($entity_id: ID!, $status: Boolean!) {
          saveEntity(data:[{id:$entity_id, status:$status}]) {
            id
          }
        }
      `;

const did_status = `
        mutation saveUser($did_id: ID!, $loginAllowed: Boolean!) {
          savedID(data:[{id:$did_id, loginAllowed:$loginAllowed}]) {
            id
          }
        }
      `;

let access_point = storageService.getStorageData("access_point");

const EntityEditor = (props) => {
  let _profiles = [];
  let _credential = [];
  let _devices = [];

  const [showCredentialAdder, setShowCredentialAdder] = useState(false);
  const [addemail, setAddEmail] = useState("");
  const [addphone, setAddPhone] = useState("");
  const [addusername, setAddUsername] = useState("");

  const [resultRegisterEmail, registerEmail] = useMutation(RECORD_EMAIL);
  const [resultRegisterPhone, registerPhone] = useMutation(RECORD_PHONE);
  const [resultRegisterUsername, registerUsername] = useMutation(RECORD_username);
  const [resultStatusChange, registerStatuschange] = useMutation(entity_status);
  const [resultDIDchange, registerDIDchange] = useMutation(did_status);

  let _balance = 0;

  let groupmeber = false;
  if (props.alldata.investmentStatistics.subscriptionValidity) {
    try {
      let tmp_inv = props.alldata.investmentStatistics.balance;
      groupmeber = true;
    } catch {}
  }
  props.balances.map((eb) => {
    _balance = _balance + eb.balance;
  });

  props.data.profiles.map((profile) => {
    _profiles.push(
      <ProfileListItem
        rootClassName='profilelistitem-root-class-name'
        key={profile.id}
        profile_uid={profile.id}
        name={profile.name}
        avatar={profile.avatar}
        data={profile}
        ent_uid={props.data.id}></ProfileListItem>
    );
  });

  try {
    _credential.push(
      <CredentialListItem
        rootClassName='credentialitem-root-class-name'
        data={props.data.digitalId}
        key={props.data.digitalId.id}
        name={truncate(props.data.digitalId.id, 12)}
        entity_uid={props.data.id}
        verified={props.data.digitalId.loginAllowed}
        type={"did"}
        editable={true}></CredentialListItem>
    );
  } catch {}

  try {
    _credential.push(
      <CredentialListItem
        rootClassName='credentialitem-root-class-name'
        data={props.data.user}
        key={props.data.user.id}
        name={props.data.user.username}
        entity_uid={props.data.id}
        verified={true}
        type={"user"}
        editable={true}></CredentialListItem>
    );
  } catch {}

  props.data.email.map((email) => {
    _credential.push(
      <CredentialListItem
        rootClassName='credentialitem-root-class-name'
        data={email}
        key={email.id}
        name={email.email}
        entity_uid={props.data.id}
        verified={false}
        type={"email"}
        editable={false}></CredentialListItem>
    );
  });

  props.data.confirmedEmail.map((email) => {
    _credential.push(
      <CredentialListItem
        rootClassName='credentialitem-root-class-name'
        data={email}
        key={email.id}
        name={email.email}
        entity_uid={props.data.id}
        verified={true}
        type={"email"}
        editable={false}></CredentialListItem>
    );
  });

  props.data.phone.map((phone) => {
    _credential.push(
      <CredentialListItem
        rootClassName='credentialitem-root-class-name'
        data={phone}
        key={phone.id}
        name={phone.phone}
        entity_uid={props.data.id}
        verified={false}
        type={"phone"}
        editable={false}></CredentialListItem>
    );
  });

  props.data.confirmedPhone.map((phone) => {
    _credential.push(
      <CredentialListItem
        rootClassName='credentialitem-root-class-name'
        data={phone}
        key={phone.id}
        name={phone.phone}
        entity_uid={props.data.id}
        verified={true}
        type={"phone"}
        editable={false}></CredentialListItem>
    );
  });

  const swapCred = () => {
    if (showCredentialAdder == true) {
      setShowCredentialAdder(false);
    } else {
      setAddEmail("");
      setAddPhone("");
      setAddUsername("");
      setShowCredentialAdder(true);
    }
  };

  const email_record_mutate = () => {
    registerEmail({
      email: addemail.trim(),
      entity: props.data.id,
    }).then((result) => {});
    props.reexecute({ id: props.data.id });
    swapCred();
    //history.push("/entity/" + props.data.id);
    //window.location.reload();
  };

  const phone_record_mutate = () => {
    registerPhone({
      phone: addphone.trim(),
      entity: props.data.id,
    }).then((result) => {});
    props.reexecute({ id: props.data.id });
    swapCred();
    //history.push("/entity/" + props.data.id);
    //window.location.reload();
  };

  const username_mutate = () => {
    registerUsername({
      username: addusername.trim(),
      entity: props.data.id,
    }).then((result) => {});
    props.reexecute({ id: props.data.id });
    swapCred();
    //history.push("/entity/" + props.data.id);
    //window.location.reload();
  };

  const changeStatus = (status) => {
    registerStatuschange({
      status: status,
      entity_id: props.data.id,
    }).then((result) => {
      registerDIDchange({
        loginAllowed: status,
        did_id: props.data.digitalId.id,
      }).then((result) => {});
    });
    props.reexecute({ id: props.data.id });
  };

  let profile_image = "";
  try {
    if (props.data.digitalId.entity.profiles[0].avatar.path == "") {
    } else {
      profile_image = process.env.REACT_APP_STORAGE_READ_HOST + "/" + props.data.digitalId.entity.profiles[0].avatar.path;
    }
  } catch {}

  let emails = "";
  let email_count = 0;

  try {
    props.data.digitalId.entity.email.map((email) => {
      if (email_count == 0) {
        emails = email.email;
        email_count = email_count + 1;
      } else {
        emails = emails + ", " + email.email;
        email_count = email_count + 1;
      }
    });
  } catch {}

  try {
    props.data.digitalId.entity.confirmedEmail.map((email) => {
      if (email_count == 0) {
        emails = email.email;
        email_count = email_count + 1;
      } else {
        emails = emails + ", " + email.email;
        email_count = email_count + 1;
      }
    });
  } catch {}

  if (email_count == 0) {
    emails = " ";
  }

  let phones = "";
  let phone_count = 0;

  try {
    props.data.digitalId.entity.phone.map((phone) => {
      if (phone_count == 0) {
        phones = phone.phone;
        phone_count = phone_count + 1;
      } else {
        phones = phones + ", " + phone.phone;
        phone_count = phone_count + 1;
      }
    });
  } catch {}

  try {
    props.data.digitalId.entity.confirmedPhone.map((phone) => {
      if (phone_count == 0) {
        phones = phone.phone;
        phone_count = phone_count + 1;
      } else {
        phones = phones + ", " + phone.phone;
        phone_count = phone_count + 1;
      }
    });
  } catch {}

  if (phone_count == 0) {
    phones = " ";
  }

  let _B = [];
  const [visibility, __] = useState("TRUE");

  _B.push(
    <PersonalFinance
      data={props.alldata.getClientWalletSummary}
      key={1}
      visibility={visibility}
      entity_uid={props.data.id}></PersonalFinance>
  );

  return (
    <div className='entityeditor-entityeditor'>
      {props.data.digitalId !== null ? (
        <div className='kyc-content-activecontainer' style={{ padding: "4px" }}>
          <Link to={"/kyc_info/" + props.data.digitalId.id} className='entityeditor-entityeditor' style={{ padding: "8px" }}>
            <div className='kyc-content-activepushitem'>
              <div className='kyc-content-container'>
                <div className='kyc-content-container1'>
                  <div className='kyc-content-container2'></div>
                  <div className='kyc-content-container3'>
                    {(props.data.digitalId.personalDetail.householdHead == true) |
                    (props.data.digitalId.personalDetail.householdHead == null) ? (
                      <svg viewBox='0 0 1024 1024' className='kyc-content-icon'>
                        <path d='M1024 590.444l-512-397.426-512 397.428v-162.038l512-397.426 512 397.428zM896 576v384h-256v-256h-256v256h-256v-384l384-288z'></path>
                      </svg>
                    ) : (
                      <></>
                    )}
                    <img src={profile_image} alt='image' className='kyc-content-image1' />
                    <div className='kyc-content-container4'>
                      <span className='kyc-content-text1'>{props.data.digitalId.personalDetail.name}</span>
                      <span className='kyc-content-text2'>{"idNo: " + props.data.digitalId.idNo.idNo}</span>
                      <span className='kyc-content-text3'>{phones}</span>
                      <span className='kyc-content-text4'>{emails}</span>
                      <span className='kyc-content-text5'>
                        {"DOB: " +
                          props.data.digitalId.personalDetail.dobYear +
                          "-" +
                          props.data.digitalId.personalDetail.dobMonth +
                          "-" +
                          props.data.digitalId.personalDetail.dobDay}
                      </span>
                      <span className='kyc-content-text6'>{props.data.digitalId.contactDetail.island.name}</span>
                      <span className='kyc-content-text7'>{props.data.digitalId.contactDetail.location}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ) : (
        <></>
      )}

      {props.data.digitalId !== null ? (
        <>
          {props.alldata.getDigitalIdCreator !== null && (
            <div className='entityeditor-container'>
              <span className='entityeditor-text'>Registered By</span>
              <span className='entityeditor-text1'>{props.alldata.getDigitalIdCreator.profiles[0].name}</span>
            </div>
          )}
        </>
      ) : (
        <></>
      )}

      {groupmeber == true && (
        <>
          <span className='entityeditor-text2'>Investments And Loans</span>
          <div style={{ padding: "4px", width: "100%" }}>
            <Investmentcontainer
              containerType='MAIN'
              entity_uid={props.data.id}
              data={props.alldata.investmentStatistics}
              loans={props.alldata.getEntityLoans}></Investmentcontainer>
          </div>
        </>
      )}

      <span className='entityeditor-text2'>Accounts</span>

      <div style={{ width: "100%" }}>{_B}</div>

      <span className='entityeditor-text2'>Associated Profiles</span>
      <div className='entityeditor-container1'>{_profiles}</div>

      <div className='entityeditor-container2'>
        <span className='entityeditor-text3'>Login Credentials</span>
        <svg viewBox='0 0 1024 1024' className='entityeditor-icon' onClick={swapCred}>
          <path d='M512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM554 298v172h172v84h-172v172h-84v-172h-172v-84h172v-172h84z'></path>
        </svg>
      </div>
      {showCredentialAdder == true && (
        <div className='entityeditor-credaddmain'>
          <div className='entityeditor-emailaddmain'>
            <input
              type='email'
              placeholder='add email'
              className='entityeditor-emailaddinput input'
              value={addemail}
              onChange={(e) => {
                setAddEmail(e.target.value);
              }}
            />
            <button className='entityeditor-emailaddbutton button' onClick={email_record_mutate}>
              Add
            </button>
          </div>
          <div className='entityeditor-phoneaddmain'>
            <input
              type='tel'
              placeholder='add phone'
              className='entityeditor-phoneaddinput input'
              value={addphone}
              onChange={(e) => {
                setAddPhone(e.target.value);
              }}
            />
            <button className='entityeditor-phoneaddbutton button' onClick={phone_record_mutate}>
              Add
            </button>
          </div>

          <div className='entityeditor-phoneaddmain'>
            <input
              type='text'
              placeholder='username'
              className='entityeditor-emailaddinput input'
              value={addusername}
              onChange={(e) => {
                setAddUsername(e.target.value);
              }}
            />
            <button className='entityeditor-emailaddbutton button' onClick={username_mutate}>
              Add
            </button>
          </div>
        </div>
      )}
      <div className='entityeditor-container3'>{_credential}</div>
      <span className='entityeditor-text4'>Access Status</span>
      {props.data.status == false ? (
        <div className='entityeditor-container4'>
          <svg viewBox='0 0 914.2857142857142 1024' className='entityeditor-icon2'>
            <path d='M146.286 329.143c0 20-16.571 36.571-36.571 36.571-20.571 0-36.571-16.571-36.571-36.571 0-20.571 16-36.571 36.571-36.571 20 0 36.571 16 36.571 36.571zM237.714 621.714v-365.714c0-20-16.571-36.571-36.571-36.571h-164.571c-20 0-36.571 16.571-36.571 36.571v365.714c0 20 16.571 36.571 36.571 36.571h164.571c20 0 36.571-16.571 36.571-36.571zM882.857 536.571c19.429 21.714 31.429 54.857 31.429 85.143-0.571 59.429-50.286 109.714-109.714 109.714h-158.286c4.571 18.286 10.286 24 16.571 36.571 14.857 29.714 32 62.857 32 109.714 0 44 0 146.286-128 146.286-9.714 0-18.857-4-25.714-10.857-24.571-24-31.429-59.429-37.714-93.143-6.857-33.143-13.143-67.429-35.429-89.714-17.714-17.714-37.143-42.286-57.714-68.571-25.143-33.143-80-101.143-101.143-102.857-18.857-1.714-34.857-17.714-34.857-36.571v-366.286c0-20 17.143-36 36.571-36.571 20-0.571 54.286-12.571 90.286-25.143 61.714-21.143 138.857-48 220.571-48h73.714c50.286 0.571 88 15.429 112.571 44.571 21.714 25.714 31.429 60.571 28 103.429 14.286 13.714 25.143 32.571 30.857 53.714 6.286 22.857 6.286 45.714 0 66.857 17.143 22.857 25.714 49.714 24.571 78.286 0 8-2.286 25.143-8.571 43.429z'></path>
          </svg>
          <span className='entityeditor-text5'>This entity is blocked from using the platform</span>
          <button
            className='entityeditor-button button'
            onClick={() => {
              changeStatus(true);
            }}>
            un-Block
          </button>
        </div>
      ) : (
        <div className='entityeditor-container5'>
          <svg viewBox='0 0 914.2857142857142 1024' className='entityeditor-icon4'>
            <path d='M146.286 768c0-20-16.571-36.571-36.571-36.571-20.571 0-36.571 16.571-36.571 36.571 0 20.571 16 36.571 36.571 36.571 20 0 36.571-16 36.571-36.571zM237.714 475.429v365.714c0 20-16.571 36.571-36.571 36.571h-164.571c-20 0-36.571-16.571-36.571-36.571v-365.714c0-20 16.571-36.571 36.571-36.571h164.571c20 0 36.571 16.571 36.571 36.571zM914.286 475.429c0 30.286-12 62.857-31.429 85.143 6.286 18.286 8.571 35.429 8.571 43.429 1.143 28.571-7.429 55.429-24.571 78.286 6.286 21.143 6.286 44 0 66.857-5.714 21.143-16.571 40-30.857 53.714 3.429 42.857-6.286 77.714-28 103.429-24.571 29.143-62.286 44-112.571 44.571h-73.714c-81.714 0-158.857-26.857-220.571-48-36-12.571-70.286-24.571-90.286-25.143-19.429-0.571-36.571-16.571-36.571-36.571v-366.286c0-18.857 16-34.857 34.857-36.571 21.143-1.714 76-69.714 101.143-102.857 20.571-26.286 40-50.857 57.714-68.571 22.286-22.286 28.571-56.571 35.429-89.714 6.286-33.714 13.143-69.143 37.714-93.143 6.857-6.857 16-10.857 25.714-10.857 128 0 128 102.286 128 146.286 0 46.857-16.571 80-32 109.714-6.286 12.571-12 18.286-16.571 36.571h158.286c59.429 0 109.714 50.286 109.714 109.714z'></path>
          </svg>
          <span className='entityeditor-text6'>This entity has free access to the platform</span>
          <button
            className='entityeditor-button1 button'
            onClick={() => {
              changeStatus(false);
            }}>
            Block
          </button>
        </div>
      )}

      <div className='entityeditor-container7'></div>
    </div>
  );
};

EntityEditor.defaultProps = {};

EntityEditor.propTypes = {};

export default EntityEditor;
