import React, { useState, useCallback } from "react";
import getCroppedImg from "../../utilities/cropImage";
import Cropper from "react-easy-crop";
import uuid from "react-uuid";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

import "./image_editor.css";

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const ImageEditor = (props) => {
  let shape = "square";

  if (props.round == true) {
    shape = "round";
  }

  const [editedimage, setEditedImage] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [uploading, setUploading] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      setEditedImage(imageDataUrl);
    }
  };

  return (
    <div className='imageeditor-imageeditor'>
      {uploading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <div className='imageeditor-imageeditorcropper'>
        <Cropper
          image={editedimage}
          crop={crop}
          zoom={zoom}
          aspect={1 / 1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          objectFit='contain'
          cropShape={shape}
          className='imageeditor-imageeditorcropper'></Cropper>
      </div>

      <div className='imageeditor-imageeditorcontainers'>
        <button
          className='imageeditor-imageeditorcancel button'
          onClick={() => {
            props.cancel(false);
          }}>
          Cancel
        </button>
        <input id='upload' type='file' onChange={onFileChange} accept='image/*' className='imageeditor-imageeditorfileselector input' />
        <button
          className='imageeditor-imageeditorsubmit button'
          onClick={() => {
            const img_uid = uuid();
            const pathprepare =
              "/" + props.database_name + "/" + props.object_type + "/" + props.parent_uid + "/" + props.field_name + "/" + img_uid;

            try {
              getCroppedImg(
                editedimage,
                croppedAreaPixels,
                pathprepare,
                props.parent_uid,
                props.object_type,
                props.field_name,
                img_uid,
                setUploading,
                "organisation",
                "org"
              );
              //props.cancel(false);
            } catch (e) {
              console.error(e);
            }
          }}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default ImageEditor;
