import React from "react";
import Avatar from "react-avatar";

import "./user_loan_item.css";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const UserLoanitem = (props) => {
  let dn = Date.now();
  var _d = new Date(props.data._Created__);
  var _e = new Date(props.data.originalDeadline);

  let pho = "no phone registered";

  try {
    pho = props.data.client.client.entity.confirmedPhone[0].phone;
  } catch {
    try {
      pho = props.data.client.client.entity.phone[0].phone;
    } catch {}
  }

  let avatar_img = "";
  try {
    if (props.data.files[0].path == "") {
    } else {
      avatar_img = process.env.REACT_APP_STORAGE_READ_HOST + "/" + props.data.files[0].path;
    }
  } catch {}

  return (
    <div className={`user-loanitem-userlistitem`}>
      {props.data.contractStage == "CLOSED" && <div className='user-loanitem-greyl'></div>}
      {props.data.contractStage == "REQUESTED" && <div className='user-loanitem-orangel'></div>}
      {(dn <= props.data.originalDeadline) & (props.data.contractStage == "OPEN") ? <div className='user-loanitem-greenr'></div> : <></>}
      {(dn > props.data.originalDeadline) & (props.data.contractStage == "OPEN") ? <div className='user-loanitem-gredl'></div> : <></>}
      {(dn > props.data.enforcmentCutoff) & (props.data.contractStage == "OPEN") ? <div className='user-loanitem-blackl'></div> : <></>}
      <div className='user-loanitem-container'>
        <Avatar src={avatar_img} size='114' name={props.data.client.client.name} className='user-loanitem-image' />

        <div className='user-loanitem-container1'>
          <div className='user-loanitem-container2'>
            {props.data.balance <= props.data.contractAmount && (
              <svg viewBox='0 0 1024 1024' className='user-loanitem-icon'>
                <path
                  d='M480 64c-265.096 0-480 214.904-480 480 0 265.098 214.904 480 480 480 265.098 0 480-214.902 480-480 0-265.096-214.902-480-480-480zM480 928c-212.078 0-384-171.922-384-384s171.922-384 384-384c212.078 0 384 171.922 384 384s-171.922 384-384 384zM512 512v-128h128v-64h-128v-64h-64v64h-128v256h128v128h-128v64h128v64h64v-64h128.002l-0.002-256h-128zM448 512h-64v-128h64v128zM576.002 704h-64.002v-128h64.002v128z'
                  className=''></path>
              </svg>
            )}
            <span className='user-loanitem-text'>{_d.toISOString().split("T")[0] + " -> " + _e.toISOString().split("T")[0]}</span>
          </div>
          <span className='user-loanitem-text1'>{props.data.client.client.name}</span>
          <span className='user-loanitem-text2'>{pho}</span>
          <span className='user-loanitem-text3'>{props.data.client.client.entity.digitalId.contactDetail.island.name}</span>
          <span className='user-loanitem-text4'>{props.data.client.client.entity.digitalId.contactDetail.location}</span>
          <span className='user-loanitem-text5'>{numberWithCommas(props.data.balance) + " VT"}</span>
        </div>
      </div>
      {(dn > props.data.enforcmentCutoff) & (props.data.contractStage == "OPEN") ? <div className='user-loanitem-blackl'></div> : <></>}
      {(dn > props.data.originalDeadline) & (props.data.contractStage == "OPEN") ? <div className='user-loanitem-gredl'></div> : <></>}
      {(dn <= props.data.originalDeadline) & (props.data.contractStage == "OPEN") ? <div className='user-loanitem-greenr'></div> : <></>}
      {props.data.contractStage == "REQUESTED" && <div className='user-loanitem-oranger'></div>}
      {props.data.contractStage == "CLOSED" && <div className='user-loanitem-greyr'></div>}
    </div>
  );
};

export default UserLoanitem;
