import React from "react";
import Avatar from "react-avatar";

import "./useritem_selected.css";

const UseritemSelected = (props) => {
  let avatar_img = "";
  try {
    if (props.data.avatar.path == "") {
    } else {
      avatar_img = process.env.REACT_APP_STORAGE_READ_HOST + "/" + props.data.avatar.path;
    }
  } catch {}

  return (
    <div className='useritem-selected-useritem-selected'>
      <Avatar src={avatar_img} size='50' name={props.data.name} className='useritem-selected-image' />

      <div className='useritem-selected-container'>
        <span className='useritem-selected-text'>{props.data.name}</span>
        <select
          className='useritem-selected-select'
          value={props.relation}
          onChange={(e) => {
            props.setrelation(e.target.value);
          }}>
          <option value='NONE'>Select an Option</option>
          <option value='CLOSE_FAMILY'>Close Family</option>
          <option value='WIDE_FAMILY'>Wide Family</option>
          <option value='FRIEND'>Friend</option>
          <option value='BUSINESS_PARTMENT'>Business Partner</option>
          <option value='OTHER'>Other</option>
        </select>
      </div>
    </div>
  );
};

export default UseritemSelected;
