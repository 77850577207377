import React, { useState } from "react";
import { useQuery } from "urql";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import storageService from "../../services/storage";

import Cbmcolorfulbar from "../components/cbmcolorfulbar";

import Header from "../components/header";
import UserLoanitem from "../components/user_loan_item";

import "./cbm_groups.css";

const getloans = `query getSlowLoans{
      getSlowLoans {
        id
        files {
          id
          path
        }
        balance
        debtAccount {
          id
          accountNo
        }
        client {
          id
          client {
            id
            name
            entity {
              id
              digitalId {
                id
                contactDetail {
                  id
                  island {
                    id
                    name
                  }
                  location
                }
              }
              confirmedPhone {
                id
                phone
              }
              phone {
                id
                phone
              }
            }
          }
        }
        createdBy
        originalDeadline
        enforcmentCutoff
        contractStage
        contractAmount
        _Created__
      }
    }
  `;

const Cbmslowloans = (props) => {
  let dta = JSON.parse(storageService.getStorageData("fps")).fronPageStats;

  const [result, reexecuteQuery] = useQuery({
    query: getloans,
    requestPolicy: "cache-and-network",
  });

  let _F = [];

  const { data, fetching, error } = result;

  if (data) {
    data.getSlowLoans.map((loan) => {
      try {
        _F.push(
          <Link
            to={
              "/cbm_user_loan/" + loan.id + "/" + loan.client.client.entity.id + "/" + loan.debtAccount[0].accountNo + "/" + loan.createdBy
            }
            style={{ width: "100%" }}
            key={loan.id + "x"}>
            <UserLoanitem data={loan} key={loan.id}></UserLoanitem>
          </Link>
        );
      } catch {}
    });
  }

  return (
    <div className='cbmgroups-container'>
      <Helmet>
        <title>Loans - CBM-UI</title>
        <meta property='og:title' content='Loans - CBM-UI' />
      </Helmet>
      <Header></Header>
      <div className='cbmgroups-body'>
        <div className='cbmgroups-maincontainer'>
          {dta.isHealthy == false && (
            <div className='pushscreen-systemwarning'>
              <span className='pushscreen-text'>FINANCIAL SYSTEM IS DOWN! CONTACT POST OFFICE IT.</span>
            </div>
          )}

          <Cbmcolorfulbar rootClassName='cbmcolorfulbar-root-class-name2'></Cbmcolorfulbar>
          <div style={{ padding: "12px", width: "100%" }}>{_F}</div>
        </div>
      </div>
    </div>
  );
};

export default Cbmslowloans;
