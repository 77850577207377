import React, { useState } from "react";

import PropTypes from "prop-types";

import "./profile_parameter_editor.css";

import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";
import { useMutation } from "urql";

import ImageEditor from "./image_editor";

import storageService from "../../services/storage";
import "react-toastify/dist/ReactToastify.css";

let db_name = "";
try {
  db_name = storageService.getStorageData("access_point").split("/")[2].split(".")[0];
} catch {}

const profile_mutation = `
    mutation saveProfile($id: ID!, $name: String) {
        saveProfile(data:[{id: $id, name: $name}]) {
            id           
        }
    }
  `;

const ProfileParameterEditor = (props) => {
  let history = useNavigate();
  let avatar_img = "";

  const [name, setName] = useState(props.data.getProfile.name);
  const [avatarEditor, setAvatarEditor] = useState(false);

  const [resultSave, saveProfile] = useMutation(profile_mutation);

  try {
    if (props.data.getProfile.avatar.path == "") {
    } else {
      avatar_img = process.env.REACT_APP_STORAGE_READ_HOST + "/" + props.data.getProfile.avatar.path;
    }
  } catch {}

  const posMutate = (event) => {
    event.preventDefault();

    saveProfile({
      id: props.data.getProfile.id,
      name: name.trim(),
    }).then((result) => {
      if (result.error) {
        console.error("Oh no!", result.error);
      } else {
        history("/entity/" + props.ent_uid);
      }
    });
  };

  return (
    <div className={`profileparametereditor-appparametereditor ${props.rootClassName} `}>
      <div className='profileparametereditor-profcont'>
        {avatarEditor === false ? (
          <Avatar
            src={avatar_img}
            size='250'
            name={name}
            round={true}
            className='profileparametereditor-image'
            onClick={() => {
              setAvatarEditor(true);
            }}
          />
        ) : (
          <ImageEditor
            cancel={setAvatarEditor}
            history={history}
            object_type={"Profile"}
            database_name={db_name}
            parent_uid={props.data.getProfile.id}
            round={true}
            field_name={"avatar"}></ImageEditor>
        )}

        <form className='profileparametereditor-form' onSubmit={posMutate}>
          <input
            type='text'
            name='profile_name'
            required
            placeholder='profile name'
            minLength='3'
            pattern="^(?!_$)(?!['-._])(?!.*[_.-]{2})[a-zA-Z0-9 '_.-]+(?<!['.-])$"
            className='profileparametereditor-textinput input'
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <div className='profileparametereditor-container'>
            <button type='submit' className='profileparametereditor-button button'>
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

ProfileParameterEditor.defaultProps = {
  rootClassName: "",
};

ProfileParameterEditor.propTypes = {
  rootClassName: PropTypes.string,
};

export default ProfileParameterEditor;
