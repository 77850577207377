import React from "react";
import Chart from "react-apexcharts";
import { useWindowSize } from "react-use";

import "./ledger_view.css";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }
  render() {
    if (this.state.errorInfo) {
      return <div></div>;
    }
    return this.props.children;
  }
}

export function numerizeFloat(value) {
  return [value["timestamp"], +value["value"]];
}

const LedgerViewMini = (props) => {
  let bal = [[100000, 0]];
  bal = props.data.getMadruhamSummary.tsBalance;

  bal.push({ timestamp: props.data.getMadruhamSummary.ts, value: props.data.getMadruhamSummary.balance });

  bal = bal.map(numerizeFloat);

  let tmp = [];
  if (bal.length == 0) {
    tmp = [[1000, 0.1]];
  } else {
    tmp = bal;
  }
  let apex_data = [{ data: tmp }];

  let { width, height } = useWindowSize();
  if (width > 565) {
    width = 565;
  } else {
    width = width - 35;
  }

  let apex_options = {
    colors: ["#0293cdff"],
    yaxis: {
      labels: {
        show: true,
        formatter: function (val) {
          return ~~val;
        },
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    chart: {
      id: "area-datetime",
      type: "area",
      animations: {
        enabled: false,
        dynamicAnimation: {
          enabled: false,
        },
      },
      zoom: {
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
      tooltip: {
        enabled: false,
        show: false,
      },
    },

    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
      show: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0.0,
        stops: [0, 100],
        gradientToColors: ["#0293cdff"],
      },
    },
  };

  return (
    <div className={`ledgerview-container`}>
      <div className='ledgerview-ledgerchart'>
        <ErrorBoundary>
          <Chart options={apex_options} series={apex_data} type='area' height='100%' width={width} />
        </ErrorBoundary>
      </div>
      <div className='ledgerview-balancestrip'>
        <span className='ledgerview-ledgerbalanetext'>Balance:</span>
        <span className='ledgerview-balanceamounttext'>{numberWithCommas(props.data.getMadruhamSummary.balance) + " VT"}</span>
      </div>
      <div className='ledgerview-balancestrip'>
        <span className='ledgerview-ledgerbalanetext-extra'>Click to review history</span>
        <span className='ledgerview-ledgerbalanetext-extra'>{"->"}</span>
      </div>
    </div>
  );
};

export default LedgerViewMini;
