import React, { useState } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import Header from "../components/header";
import Activepushitem from "../components/active_push_item";

import "./push_page.css";

import { useQuery, useMutation } from "urql";

const pushdata = `query pushStats($id:String!){
    pushStats(pushId:$id){
      ts
      pushData
    }
    }
  `;

const suspend_mut = `
    mutation suspendPush($pushID:ID!) {
        suspendPushStage(pushID:$pushID)
    }
  `;

const Pushpage = (props) => {
  let history = useNavigate();

  let { pushId } = useParams();

  const [confirm, setConfirm] = useState(false);
  const [suspresult, dosuspend] = useMutation(suspend_mut);

  const [result, reexecuteQuery] = useQuery({
    query: pushdata,
    variables: { id: pushId },
    requestPolicy: "cache-and-network",
  });

  let _F = [];
  let _D = [];

  const { data, fetching, error } = result;

  async function to_confirm() {
    setConfirm(true);
  }

  async function suspend() {
    dosuspend({ pushID: pushId });
    history("/");
  }

  if (data) {
    data.pushStats.pushData.islandList.map((island) => {
      _D.push(<Chip size='small' label={island} style={{ marginTop: "8px" }} key={island} />);
    });

    _F.push(
      <div className='pushpage-searchcontent'>
        <div className='pushpage-activecontainer'>
          <Activepushitem key={data.pushStats.pushData.id} ts={data.pushStats.ts} data={data.pushStats.pushData}></Activepushitem>
        </div>
        <div className='pushpage-historiccontainer'></div>
        <span className='pushscreen-activetext' style={{ marginTop: "12px" }}>
          Islands
        </span>

        <div className='pushitem-container'>
          <Stack direction='row' spacing={1} flexWrap='wrap' justifyContent='space-evenly' alignItems='flex-start'>
            {_D}
          </Stack>
        </div>
        {data.pushStats.pushData.status == 1 && (
          <div className='pushitem-a-container1'>
            <span className='pushitem-a-text3'>  </span>
            {confirm ? (
              <button className='pushitem-a-button1 button' onClick={suspend}>
                Confirm
              </button>
            ) : (
              <button className='pushitem-a-button2 button' onClick={to_confirm}>
                SUSPEND
              </button>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className='pushpage-container'>
      <Helmet>
        <title>pushpage - CBM-UI</title>
        <meta property='og:title' content='pushpage - CBM-UI' />
      </Helmet>
      <Header></Header>
      <div className='pushpage-body'>
        <div className='pushpage-maincontainer'>
          <div className='pushpage-screencontent'>{_F}</div>
        </div>
      </div>
    </div>
  );
};

export default Pushpage;
