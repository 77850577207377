import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Avatar from "react-avatar";

import PropTypes from "prop-types";
import Profile from "./profile";

import "./header.css";
import storageService from "../../services/storage";

import { useQuery } from "urql";

const frontpageData = `query fronPageStats{
    fronPageStats{
      noOfPushes
      didCount
      storeCount
      transactedValue
      isHealthy
      activePushes
      ts
      historicPushes
      myCashBalance
      cashAccount
      goodLoans
      slowLoans
      badLoans   
    }
    }
  `;

const Header = (props) => {
  const [visibility, setVisibility] = useState(false);
  const location = useLocation();

  if (location.pathname !== "/") {
    try {
      const [result, reexecuteQuery] = useQuery({
        query: frontpageData,
        requestPolicy: "cache-and-network",
      });

      const { data, fetching, error } = result;

      if (data) {
        storageService.setStorageData("fps", JSON.stringify(data));
      }
    } catch {}
  }

  let main_text = "Vanuatu Post";

  if (
    (location.pathname == "/") |
    ((location.pathname == "/users") | (location.pathname == "/departments") | (location.pathname == "/banking"))
  ) {
  } else {
    main_text = "<- " + main_text;
  }

  async function swicthProfile() {
    if (visibility == true) {
      setVisibility(false);
    } else {
      setVisibility(true);
    }
  }

  const all_profiles = JSON.parse(storageService.getStorageData("profiles"));
  const active_profile = storageService.getStorageData("active_profile");

  let profile_data = {};

  all_profiles.forEach((element) => {
    if (element["id"] === active_profile) {
      profile_data = element;
    }
    if (element["uid"] === active_profile) {
      profile_data = element;
    }
  });

  let avatar_img = "";
  try {
    if (profile_data["avatar"]["path"] == "") {
    } else {
      avatar_img = process.env.REACT_APP_STORAGE_READ_HOST + "/" + profile_data["avatar"]["path"];
    }
  } catch {}

  return (
    <div className={`header-header`}>
      <Link to='/' className='header-navlink'>
        {main_text}
      </Link>
      <div className='header-container'></div>
      <div className='header-container1'>
        <svg viewBox='0 0 1024 1024' className='header-icon' onClick={swicthProfile}>
          <path d='M316 366l196 196 196-196 60 60-256 256-256-256z' className=''></path>
        </svg>
        <Avatar src={avatar_img} size='34' name={profile_data["name"]} className='header-image' />
      </div>
      {visibility && <Profile setVisibility={swicthProfile} profile_data={profile_data}></Profile>}
    </div>
  );
};

Header.defaultProps = {
  rootClassName: "",
};

Header.propTypes = {
  rootClassName: PropTypes.string,
};

export default Header;
