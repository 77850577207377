import React from "react";
import storageService from "../../services/storage";
import { useQuery } from "urql";

import { Helmet } from "react-helmet";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

import Header from "../components/header";
import ProfileParameterEditor from "../components/profile_parameter_editor";
import "./edit_profile.css";

let access_point = storageService.getStorageData("access_point");

const prof_querry = `
    query Profile($id:ID!) {
        getProfile(id:$id) {
            id
            name
            avatar {
                id
                path
            }
        }
    }
  `;

const EditProfile = (props) => {
  let profile_uid = props.match.params.profile_uid;
  let ent_uid = props.match.params.ent_uid;

  const ProfileFunction = () => {
    const [result, reexecuteQuery] = useQuery({
      url: access_point,
      query: prof_querry,
      variables: { id: profile_uid },
      requestPolicy: "cache-and-network",
    });

    const { data, fetching, error } = result;

    if (error) {
      return <></>;
    }

    if (fetching) {
      return (
        <div>
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      );
    }
    return (
      <ProfileParameterEditor
        rootClassName='profileparametereditor-root-class-name'
        profile_uid={profile_uid}
        ent_uid={ent_uid}
        data={data}
        key={profile_uid}></ProfileParameterEditor>
    );
  };

  return (
    <div className='editprofile-container'>
      <Helmet>
        <title>edit profile - bravhurDB</title>
        <meta
          name='description'
          content='the business ready database, featuring field level streaming, IoT support, GraphQL, fine grained access control, in-memory graph database backend'
        />
        <meta property='og:title' content='edit profile - bravhurDB' />
        <meta
          property='og:description'
          content='the business ready database, featuring field level streaming, IoT support, GraphQL, fine grained access control, in-memory graph database backend'
        />
      </Helmet>
      <Header rootClassName='header-root-class-name11'></Header>
      <div className='editprofile-contentframe'>
        <div className='editprofile-contentcontainer'>
          <div className='editprofile-homepanel'>
            <div className='editprofile-leftpanel'>
              <span className='editprofile-leftpaneltext'>
                <span>Edit Profile</span>
                <br></br>
              </span>
              <ProfileFunction></ProfileFunction>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
