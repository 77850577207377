import React from "react";
import { Link } from "react-router-dom";
import { truncate } from "../../services/utilities";

import ReactECharts from "echarts-for-react";

import "./active_push_item.css";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }
  render() {
    if (this.state.errorInfo) {
      return <div></div>;
    }
    return this.props.children;
  }
}

export function numerizeFloat(value) {
  return [value["timestamp"], +value["value"]];
}

const Activepushitem = (props) => {
  let _d = new Date(props.data.expiration);
  let exp = "UNTIL: " + _d.toISOString().split("T")[0];

  let bal = props.data.distributedSum;

  bal = bal.map(numerizeFloat);

  bal.push([props.ts, props.data.distributed]);

  let tmp = [];
  if (bal.length == 0) {
    tmp = [[1000, 0.1]];
  } else {
    tmp = bal;
  }

  let bal2 = props.data.usedSum;

  bal2 = bal2.map(numerizeFloat);

  bal2.push([props.ts, Math.abs(props.data.used)]);

  let tmp2 = [];
  if (bal2.length == 0) {
    tmp2 = [[1000, 0.1]];
  } else {
    tmp2 = bal2;
  }

  const ec_options = {
    grid: { top: 16, right: 16, bottom: 16, left: 56 },
    animation: false,
    xAxis: {
      scale: true,
      show: false,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: bal,
        type: "line",
        smooth: true,
      },

      {
        data: bal2,
        type: "line",
        smooth: true,
      },
    ],
  };

  return (
    <Link to={"/push/" + props.data.id} style={{ width: "100%", maxWidth: "600px" }}>
      <div className='activepushitem-activepushitem'>
        <div className='activepushitem-container'></div>
        <div className='activepushitem-container1'>
          <div className='activepushitem-graphcomeshere'>
            <ErrorBoundary>
              <ReactECharts
                option={ec_options}
                opts={{ renderer: "canvas", useDirtyRect: false, useCoarsePointer: false, ssr: false, height: "150px", silent: true }}
                style={{ height: "300px", width: "100%" }}
                lazyUpdate={false}
              />
            </ErrorBoundary>
          </div>
          <div className='activepushitem-container2'>
            <div className='activepushitem-container3'>
              <span className='activepushitem-text'>{exp}</span>
            </div>
            <span className='activepushitem-text1'>{truncate(props.data.description, 25)}</span>
            <span className='activepushitem-text2'>{numberWithCommas(props.data.amount) + " VT"}</span>
            <span className='activepushitem-text3'>{"eligable: " + numberWithCommas(props.data.eligableCitizen) + " citizen"}</span>
            <span className='activepushitem-text4'>{"islands: " + props.data.islandCount + " selected"}</span>
            <span className='activepushitem-text5'>{"CLAIMED: " + numberWithCommas(props.data.distributed) + " VT"}</span>
            <span className='activepushitem-text6'>{"USED: " + numberWithCommas(Math.abs(props.data.used)) + " VT"}</span>
          </div>
        </div>
        <div className='activepushitem-container4'></div>
      </div>
    </Link>
  );
};

export default Activepushitem;
