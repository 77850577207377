import React, { useState } from "react";

import Avatar from "react-avatar";
import uuid from "react-uuid";
import { useMutation, useQuery } from "urql";

import "./edit_member_remove.css";

const savesettings = `mutation detachGroup($id:ID!, $adm_id:ID!){
    detachGroup(data:[{id:$id, administrator:{id:$adm_id}}]){
        id
    }
    }
  `;

const savesettingsmember = `mutation detachGroup($id:ID!, $adm_id:ID!){
    detachGroup(data:[{id:$id, member:{id:$adm_id}}]){
        id
    }
    }
  `;

const EditMemberRemove = (props) => {
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [resultMut, domutate] = useMutation(savesettings);
  const [result02Mut, domutate02] = useMutation(savesettingsmember);

  const confirm_delete = () => {
    setDeleteConfirmation(true);
  };

  const actual_delete = () => {
    if (props.widget_type == "ADMINISTRATORS") {
      domutate({ id: props.group_uid, adm_id: props.profile_id });
    } else {
      domutate02({ id: props.group_uid, adm_id: props.client_id });
    }

    window.location.reload();
  };

  let avatar_img = "";
  try {
    if (props.avatar.path == "") {
    } else {
      avatar_img = process.env.REACT_APP_STORAGE_READ_HOST + "/" + props.avatar.path;
    }
  } catch {}

  return (
    <div className={`entitymemeberremove-entitylistitem`}>
      <Avatar src={avatar_img} size='50' name={props.name} className='entitymemeberremove-image' />

      <div className='entitymemeberremove-container'>
        <span className='entitymemeberremove-text'>{props.name}</span>
      </div>
      {deleteConfirmation && (
        <button type='button' className='entitymemeberremove-button button' onClick={actual_delete}>
          CONFIRM
        </button>
      )}
      {deleteConfirmation == false && (
        <svg viewBox='0 0 1024 1024' className='entitymemeberremove-icon' onClick={confirm_delete}>
          <path
            d='M768 298.667v554.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-426.667c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-554.667zM725.333 213.333v-42.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-170.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v42.667h-170.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h42.667v554.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h426.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-554.667h42.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM384 213.333v-42.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v42.667zM384 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM554.667 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667z'
            className=''></path>
        </svg>
      )}
    </div>
  );
};

export default EditMemberRemove;
