import React, { useState } from "react";

import PropTypes from "prop-types";

import Bankstrip from "./bankstrip";
import "./banktransferlist.css";
import { CSVLink } from "react-csv";
import { useQuery, useMutation } from "urql";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const Banktransferlist = (props) => {
  const [transid, setTrnsId] = useState("");
  let transferlist = JSON.parse(props.data.export);
  let _tmp_ts = "" + props.data.timestamp;

  let ts_actual = props.data.timestamp;

  if (_tmp_ts.length > 10) {
    ts_actual = ts_actual / 1000;
  }

  let _d = new Date(ts_actual * 1000);

  let _F = [];

  let _finalTransfer = [];

  Object.keys(transferlist).forEach(function (key) {
    let sum_amount = 0;

    transferlist[key].map((ind) => {
      sum_amount = sum_amount + ind["amount"];
      _finalTransfer.push(ind);
    });

    let _sum = numberWithCommas(sum_amount) + " VT";

    _F.push(<Bankstrip bank_name={key} key={key} sum={_sum}></Bankstrip>);
  });

  return (
    <div className='banktransferlist-banktransferlist'>
      <div className='banktransferlist-container'>
        <span className='banktransferlist-text'>{_d.toISOString().replace("T", " ").split(".")[0]}</span>
        <CSVLink data={_finalTransfer} filename={"bankWithdrawalList_" + _d.toISOString().split("T")[0] + ".csv"}>
          <button className='banktransferlist-button button'>
            <span className='banktransferlist-text1'>Download CSV</span>
            <svg viewBox='0 0 1024 1024' className='banktransferlist-icon'>
              <path d='M877.254 621.254l-320 320c-24.992 24.994-65.514 24.994-90.508 0l-320-320c-24.994-24.994-24.994-65.516 0-90.51 24.994-24.996 65.516-24.996 90.51 0l210.744 210.746v-613.49c0-35.346 28.654-64 64-64s64 28.654 64 64v613.49l210.746-210.746c12.496-12.496 28.876-18.744 45.254-18.744s32.758 6.248 45.254 18.746c24.994 24.994 24.994 65.514 0 90.508z'></path>
            </svg>
          </button>
        </CSVLink>
      </div>

      {_F}
    </div>
  );
};

Banktransferlist.defaultProps = {};

Banktransferlist.propTypes = {};

export default Banktransferlist;
