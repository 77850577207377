import React from "react";
import { getNumberUnit } from "../../services/utilities";
import Chart from "react-apexcharts";
import { useWindowSize } from "react-use";
import { useQuery } from "urql";

import "./demographicstatistics.css";

const search_profile = `
    query getStoreCountPerIsland {
      getStoreCountPerIsland
    }
  `;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }
  render() {
    if (this.state.errorInfo) {
      return <div></div>;
    }
    return this.props.children;
  }
}

export function numerizeFloat(value) {
  return [value["timestamp"], +value["value"]];
}

const SoloStatisticsStorePerIsland = (props) => {
  let { width, height } = useWindowSize();

  const [result, reexecuteQuery] = useQuery({
    query: search_profile,
    requestPolicy: "cache-and-network",
  });

  if (width > 565) {
    width = 565;
  } else {
    width = width - 35;
  }

  let _F = [];
  let ao01 = null;
  const { data, fetching, error } = result;

  if (data) {
    ao01 = {
      chart: {
        type: "bar",
        animations: {
          enabled: false,
          dynamicAnimation: {
            enabled: false,
          },
        },
        zoom: {
          autoScaleYaxis: true,
        },
        toolbar: {
          show: false,
        },
        tooltip: {
          enabled: false,
          show: false,
        },
      },
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: true,
          horizontal: false,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        shared: false,
        intersect: false,
      },
      xaxis: {
        categories: data.getStoreCountPerIsland.names,
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
          formatter: function (val) {
            return getNumberUnit(~~val);
          },
        },
      },
    };

    _F.push(
      <Chart
        options={ao01}
        series={[{ data: data.getStoreCountPerIsland.Islands, name: "Stores Per Island" }]}
        type='bar'
        height='100%'
        width={width}
        key='x01'
      />
    );
  }

  return (
    <div className='demographicstatistics-demographicstatistics'>
      <div className='demographicstatistics-container01'>
        <ErrorBoundary>{_F}</ErrorBoundary>
      </div>
    </div>
  );
};

export default SoloStatisticsStorePerIsland;
