import React, { useState } from "react";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";
import { useWindowSize } from "react-use";
import { useQuery, useMutation } from "urql";
import uuid from "react-uuid";

import { getNumberUnit } from "../../services/utilities";

import "./cashholderlistitem.css";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function numerizeFloat(value) {
  return [value["timestamp"], +value["value"]];
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }
  render() {
    if (this.state.errorInfo) {
      return <div></div>;
    }
    return this.props.children;
  }
}

const trans_uid = uuid();

let transgql = `
        mutation submitCashTransfer($fromEntity:ID!, $toEntity:ID!, $amount:Int!, $transactionId:String!) {
          submitCashTransfer(fromEntity: $fromEntity, toEntity: $toEntity, amount: $amount, transactionId: $transactionId)
        }
      `;

const Cashholderlistitem = (props) => {
  const [box, setBox] = useState(false);
  const [transfervalue, set_transfervalue] = useState(null);

  const [ct_result, cash_transfer] = useMutation(transgql);

  let avatar_img = "";
  try {
    if (props.data.avatar == "") {
    } else {
      avatar_img = process.env.REACT_APP_STORAGE_READ_HOST + "/" + props.data.avatar;
    }
  } catch {}

  const switch_box = () => {
    if (box == true) {
      setBox(false);
    } else {
      setBox(true);
    }
  };

  let bal = [[100000, 0]];
  bal = props.data.ts_balance;

  bal.push({ timestamp: props.data.ts, value: props.data.balance });

  bal = bal.map(numerizeFloat);

  let tmp = [];
  if (bal.length == 0) {
    tmp = [[1000, 0.1]];
  } else {
    tmp = bal;
  }
  let apex_data = [{ data: tmp }];

  let { width, height } = useWindowSize();
  if (width > 545) {
    width = 545;
  } else {
    width = width - 55;
  }

  let apex_options = {
    colors: ["#0293cdff"],
    yaxis: {
      labels: {
        show: true,
        formatter: function (val) {
          return getNumberUnit(~~val);
        },
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    chart: {
      id: "area-datetime",
      type: "area",
      animations: {
        enabled: false,
        dynamicAnimation: {
          enabled: false,
        },
      },
      zoom: {
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
      tooltip: {
        enabled: false,
        show: false,
      },
    },

    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
      show: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0.0,
        stops: [0, 100],
        gradientToColors: ["#0293cdff"],
      },
    },
  };

  const add_amount = () => {
    if (transfervalue !== null) {
      if (transfervalue > 0) {
        cash_transfer({ amount: ~~transfervalue, fromEntity: props.myEntity, toEntity: props.data.entity_uid, transactionId: trans_uid });
        window.location.reload();
      }
    }
  };

  const remove_amount = () => {
    if (transfervalue !== null) {
      if (transfervalue > 0) {
        cash_transfer({ amount: ~~transfervalue, fromEntity: props.data.entity_uid, toEntity: props.myEntity, transactionId: trans_uid });
        window.location.reload();
      }
    }
  };

  return (
    <div className={`cashholderlistitem-entitylistitem`}>
      <div className='cashholderlistitem-container'>
        <ErrorBoundary>
          <Chart options={apex_options} series={apex_data} type='area' height='100%' width={width} style={{ maxHeight: "150px" }} />
        </ErrorBoundary>
        <Link to={"/wallet/" + props.data.accountNo}>
          <svg viewBox='0 0 1024 1024' className='cashholderlistitem-icon'>
            <path d='M512 170l342 342-342 342-60-60 238-240h-520v-84h520l-238-240z' className=''></path>
          </svg>
        </Link>
      </div>
      <div className='cashholderlistitem-container1'>
        <Avatar src={avatar_img} size='50' name={props.data.name} className='cashholderlistitem-image' />

        <div className='cashholderlistitem-container2'>
          <span className='cashholderlistitem-text'>{props.data.name}</span>
          <span className='cashholderlistitem-text1'>{numberWithCommas(props.data.balance) + " VT"}</span>
          <span className='cashholderlistitem-text2'>{numberWithCommas(props.data.missing) + " VT"}</span>
        </div>
        <svg viewBox='0 0 1024 1024' className='cashholderlistitem-icon2' onClick={switch_box}>
          <path d='M86 682l170-170v128h640v86h-640v128zM938 342l-170 170v-128h-640v-86h640v-128z' className=''></path>
        </svg>
      </div>

      {box && (
        <div className='cashholderlistitem-container3'>
          <input
            type='number'
            min='1'
            step='1'
            required
            placeholder='amount to move'
            className='cashholderlistitem-textinput input'
            value={transfervalue}
            onChange={(e) => {
              set_transfervalue(e.target.value);
            }}
          />
          <div className='cashholderlistitem-container4'>
            <button type='button' className='cashholderlistitem-button button' onClick={add_amount}>
              ADD
            </button>
            <button type='button' className='cashholderlistitem-button1 button' onClick={remove_amount}>
              REMOVE
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cashholderlistitem;
