import React, { useState } from "react";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import storageService from "../../services/storage";

import Header from "../components/header";

import Cbmcolorfulbar from "../components/cbmcolorfulbar";
import Mycashlistitem from "../components/mycashlistitem";
import Cashholderlistitem from "../components/cashholderlistitem";

import "./manager.css";
import "./cash_holders.css";

import { useQuery } from "urql";

const frontpageData = `query getCashHolders{
    getCashHolders
    }
  `;

const CashHolders = (props) => {
  let dta = JSON.parse(storageService.getStorageData("fps")).fronPageStats;

  const [result, reexecuteQuery] = useQuery({
    query: frontpageData,
    requestPolicy: "cache-and-network",
  });

  let _F = [];
  let _D = [];
  let _E = [];

  let _FPS = [];
  const { data, fetching, error } = result;

  let me_ent = "";

  if (data) {
    data.getCashHolders.me.map((me) => {
      me_ent = me.entity_uid;
      _D.push(<Mycashlistitem data={me} key={me.accountNo}></Mycashlistitem>);
    });

    data.getCashHolders.cashHolders.map((ch) => {
      _E.push(<Cashholderlistitem data={ch} key={ch.accountNo} myEntity={me_ent}></Cashholderlistitem>);
    });

    _FPS.push(
      <div className='manager-contentcontainer' key='001'>
        {dta.isHealthy == false && (
          <div className='pushscreen-systemwarning'>
            <span className='pushscreen-text'>FINANCIAL SYSTEM IS DOWN! CONTACT POST OFFICE IT.</span>
          </div>
        )}
        <Cbmcolorfulbar rootClassName='cbmcolorfulbar-root-class-name2'></Cbmcolorfulbar>

        <div className='cashholders-activecontainer' style={{ width: "100%" }}>
          {_D}
        </div>
        <div className='cashholders-activecontainer' style={{ width: "100%" }}>
          {_E}
        </div>
      </div>
    );
  }
  return (
    <div className='manager-container'>
      <Helmet>
        <title>Cash Holders - Vanuatu Post</title>
        <meta property='og:title' content='Cash Holders - Vanuatu Post' />
      </Helmet>
      <Header rootClassName='header-root-class-name'></Header>
      <div className='manager-contentframe'>{_FPS}</div>
    </div>
  );
};

export default CashHolders;
