import React from "react";
import { useQuery } from "urql";

import { Helmet } from "react-helmet";
import storageService from "../../services/storage";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";

import Header from "../components/header";
import "./entity.css";

import EntityEditor from "../components/entity_editor";

let access_point = storageService.getStorageData("access_point");

const entity_query = `
    query entityQuery($id:ID!) {
      getClientWalletSummary(entity:$id)

      investmentStatistics(entityUid:$id)

      getEntityLoans(entityUid:$id) {
        id
        files {
          id
          path
        }
        balance
        debtAccount {
          id
          accountNo
        }
        client {
          id
          client {
            id
            name
            entity {
              id
              digitalId {
                id
                contactDetail {
                  id
                  island {
                    id
                    name
                  }
                  location
                }
              }
              confirmedPhone {
                id
                phone
              }
              phone {
                id
                phone
              }
            }
          }
        }
        createdBy
        originalDeadline
        enforcmentCutoff
        contractStage
        contractAmount
        _Created__
      }


      getEntity(id:$id) {
        id   
        profiles {
            id
            name
            avatar {
                id
                path
            }
        }
        status
        user {
            id
            username
        }
        email {
          id
          email
        }
        confirmedEmail {
          id
          email
        }

        phone {
          id
          phone
        }
        confirmedPhone {
          id
          phone
        }        

        digitalId {
          id
          loginAllowed



                entity {
                    id
                    profiles {
                        id
                        avatar {
                            id
                            path
                        }                        
                    }

                    email{
                      id
                      email
                    }      
                    confirmedEmail {
                      id
                      email
                    }      
                    phone{
                      id
                      phone
                    }      
                    confirmedPhone{
                      id
                      phone
                    } 


                }
                idImage {
                    id
                    path
                    status
                    reasoning
                }
                idNo {
                    id
                    status
                    idNo
                }
                personalDetail {
                    id
                    name
                    dobYear
                    dobMonth
                    dobDay
                    gender
                    status
                    householdHead
                }
                contactDetail {
                    id
                    location
                    island {
                        id
                        name
                    } 
                    status                   
                }


        }
      }

      getEntityBalances(entityId:$id)

      getDigitalIdCreator(id:$id) {
        id
        profiles {
            id
            name
            avatar {
                id
                path
            }
        }        
      }

    }
  `;

const Entity = (props) => {
  let { entity_uid } = useParams();

  const EntityFunction = () => {
    const [result, reexecuteQuery] = useQuery({
      url: access_point,
      query: entity_query,
      variables: { id: entity_uid },
      requestPolicy: "cache-and-network",
    });

    const { data, fetching, error } = result;

    if (error) {
      return <></>;
    }

    if (fetching) {
      return (
        <div>
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      );
    }
    return <EntityEditor data={data.getEntity} balances={data.getEntityBalances} alldata={data} reexecute={reexecuteQuery} />;
  };

  return (
    <div className='entity-container'>
      <Helmet>
        <title>entity - bravhurDB</title>
        <meta
          name='description'
          content='the business ready database, featuring field level streaming, IoT support, GraphQL, fine grained access control, in-memory graph database backend'
        />
        <meta property='og:title' content='entity - bravhurDB' />
        <meta
          property='og:description'
          content='the business ready database, featuring field level streaming, IoT support, GraphQL, fine grained access control, in-memory graph database backend'
        />
      </Helmet>
      <Header rootClassName='header-root-class-name9'></Header>
      <div className='entity-contentframe'>
        <div className='entity-contentcontainer'>
          <div className='entity-homepanel'>
            <div className='entity-leftpanel'>
              <EntityFunction></EntityFunction>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Entity;
