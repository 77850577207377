import React, { useState, useEffect } from "react";
import { useQuery } from "urql";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import Header from "../components/header";

import UserListIteWwithArrow from "../components/user_listitem_with_arrow";

import storageService from "../../services/storage";

import Cbmcolorfulbar from "../components/cbmcolorfulbar";

import "./users.css";

import LastRegistered from "../components/lastregistered";
import SoloStatisticsUserPerIsland from "../components/soloChart_userPerIsland";

let access_point = storageService.getStorageData("access_point");

const search_profile = `
    query allProfile($filter:String) {
      allProfile(filter:{name:$filter}, fuzzy:true) {
        id
        name
        entity {
            id
        }
        avatar {
          id
          path
        }      
      }
    }
  `;

const Users = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [searchInputInput, setSearchInputInput] = useState("");

  const [usersearch, setUserSearch] = useState([]);
  let _usersearch = [];

  let dta = JSON.parse(storageService.getStorageData("fps")).fronPageStats;

  const zeros = () => {
    if (searchInput.trim().length == 0) {
      return true;
    } else {
      return false;
    }
  };

  const szero = zeros() === true;

  const slength = () => {
    if (searchInput.trim().length < 3) {
      return true;
    } else {
      return false;
    }
  };

  const shouldPause = slength() === true;
  const [result, reexecuteQuery] = useQuery({
    url: access_point,
    query: search_profile,
    variables: { filter: searchInput.trim() },
    requestPolicy: "cache-and-network",
    pause: shouldPause,
  });
  const { data, fetching, error } = result;

  useEffect(() => {
    if (searchInput.trim().length > 2) {
      reexecuteQuery();
    }
    _usersearch = [];

    if (error) {
      _usersearch.push();
    }

    if (fetching) {
      _usersearch.push();
    }

    try {
      data.allProfile.map((emp) => {
        _usersearch.push(
          <Link to={"/entity/" + emp.entity.id} key={"x-" + emp.id} style={{ width: "100%" }}>
            <UserListIteWwithArrow
              rootClassName='avatarlistitemwithadder-root-class-name'
              user_name={emp.name}
              avatar={emp.avatar}
              key={emp.id}></UserListIteWwithArrow>
          </Link>
        );
      });
    } catch {}

    setUserSearch(_usersearch);
  }, [searchInput]);

  return (
    <div className='users-container'>
      <Helmet>
        <title>users - Vanuatu Post</title>
        <meta
          name='description'
          content='the business ready database, featuring field level streaming, IoT support, GraphQL, fine grained access control, in-memory graph database backend'
        />
        <meta property='og:title' content='users - Vanuatu Post' />
        <meta
          property='og:description'
          content='the business ready database, featuring field level streaming, IoT support, GraphQL, fine grained access control, in-memory graph database backend'
        />
      </Helmet>
      <Header rootClassName='header-root-class-name8'></Header>
      <div className='users-contentframe'>
        <div className='users-contentcontainer'>
          {dta.isHealthy == false && (
            <div className='pushscreen-systemwarning'>
              <span className='pushscreen-text'>FINANCIAL SYSTEM IS DOWN! CONTACT POST OFFICE IT.</span>
            </div>
          )}
          <Cbmcolorfulbar rootClassName='cbmcolorfulbar-root-class-name2'></Cbmcolorfulbar>

          <div className='storescreen-container1'>
            <SoloStatisticsUserPerIsland></SoloStatisticsUserPerIsland>
          </div>

          <Link to='/demographics' style={{ width: "100%" }}>
            <div className='userscreen-container1'>
              <span className='userscreen-text1'>Demographics</span>
              <span className='userscreen-text2'>-&gt;</span>
            </div>
          </Link>
          <div className='userscreen-searchbar'>
            <Link to='/team'>
              <svg viewBox='0 0 1024 1024' className='userscreen-icon'>
                <path d='M768 896v-85.333c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464h-341.333c-58.88 0-112.299 23.936-150.869 62.464s-62.464 91.989-62.464 150.869v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-85.333c0-35.371 14.293-67.285 37.504-90.496s55.125-37.504 90.496-37.504h341.333c35.371 0 67.285 14.293 90.496 37.504s37.504 55.125 37.504 90.496v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667zM597.333 298.667c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464-112.299 23.936-150.869 62.464-62.464 91.989-62.464 150.869 23.936 112.299 62.464 150.869 91.989 62.464 150.869 62.464 112.299-23.936 150.869-62.464 62.464-91.989 62.464-150.869zM512 298.667c0 35.371-14.293 67.285-37.504 90.496s-55.125 37.504-90.496 37.504-67.285-14.293-90.496-37.504-37.504-55.125-37.504-90.496 14.293-67.285 37.504-90.496 55.125-37.504 90.496-37.504 67.285 14.293 90.496 37.504 37.504 55.125 37.504 90.496zM1024 896v-85.333c-0.043-53.12-19.499-101.76-51.883-139.136-27.819-32.128-65.195-55.936-107.904-67.243-22.784-6.016-46.123 7.552-52.139 30.336s7.552 46.123 30.336 52.139c25.899 6.869 48.469 21.248 65.195 40.619 19.413 22.443 31.019 51.456 31.061 83.285v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667zM672.085 174.891c34.261 8.789 61.653 30.507 78.379 58.752s22.656 62.72 13.867 96.981c-7.509 29.312-24.533 53.589-47.147 70.485-13.397 10.027-28.8 17.493-45.355 21.803-22.784 5.973-36.437 29.312-30.421 52.096s29.312 36.437 52.096 30.421c27.179-7.125 52.565-19.413 74.795-36.011 37.675-28.16 66.219-68.821 78.72-117.675 14.592-57.045 4.693-114.731-23.125-161.621s-73.6-83.328-130.645-97.963c-22.827-5.845-46.080 7.936-51.925 30.763s7.936 46.080 30.763 51.925z'></path>
              </svg>
            </Link>
            <input
              type='text'
              placeholder='search'
              autoComplete='off'
              className='userscreen-textinput input'
              value={searchInputInput}
              onChange={(e) => {
                setSearchInput(e.target.value.trim());
                setSearchInputInput(e.target.value);
              }}
            />
            <Link to='/add_user'>
              <svg viewBox='0 0 1024 1024' className='userscreen-icon2'>
                <path d='M725.333 896v-85.333c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464h-298.667c-58.88 0-112.299 23.936-150.869 62.464s-62.464 91.989-62.464 150.869v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-85.333c0-35.371 14.293-67.285 37.504-90.496s55.125-37.504 90.496-37.504h298.667c35.371 0 67.285 14.293 90.496 37.504s37.504 55.125 37.504 90.496v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667zM576 298.667c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464-112.299 23.936-150.869 62.464-62.464 91.989-62.464 150.869 23.936 112.299 62.464 150.869 91.989 62.464 150.869 62.464 112.299-23.936 150.869-62.464 62.464-91.989 62.464-150.869zM490.667 298.667c0 35.371-14.293 67.285-37.504 90.496s-55.125 37.504-90.496 37.504-67.285-14.293-90.496-37.504-37.504-55.125-37.504-90.496 14.293-67.285 37.504-90.496 55.125-37.504 90.496-37.504 67.285 14.293 90.496 37.504 37.504 55.125 37.504 90.496zM981.333 426.667h-85.333v-85.333c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v85.333h-85.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h85.333v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-85.333h85.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667z'></path>
              </svg>
            </Link>
          </div>

          <div className='userscreen-searchcontent'>
            <div className='userscreen-activecontainer'>{szero == true ? <LastRegistered></LastRegistered> : usersearch}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
