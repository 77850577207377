import React, { useState } from "react";
import { useMutation } from "urql";
import { useNavigate } from "react-router-dom";

import { MultiSelect } from "react-multi-select-component";
import uuid from "react-uuid";
import "./newpush.css";

const push_create = `
    mutation saveaidPush($id:ID!, $description:String!, $amount:Float!, $islands:[node!]!, $expiration:Float!) {
        saveaidPush(data:[{id:$id, description:$description, amount:$amount, expiration:$expiration, status:true, islands:$islands}]) {
            id

        }
    }
  `;

const Newpush = (props) => {
  let history = useNavigate();

  let _F = [];

  const options = [];

  const [isl_selection, set_isl_selection] = useState([]);

  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState(null);
  var today = new Date().toISOString().split("T")[0];
  const [expiration, setExpiration] = useState(today);

  const [mutresult, domutate] = useMutation(push_create);

  if (props.data != null) {
    if (props.data.allIsland != null) {
      props.data.allIsland.map((island) => {
        options.push({ label: island.name, value: island.id });
      });
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    let tmp_isl = [];
    isl_selection.map((isl) => {
      tmp_isl.push({ id: isl.value });
    });
    domutate({ id: uuid(), description: description, amount: amount * 1, expiration: Date.parse(expiration), islands: tmp_isl });
    history("/");
  }

  return (
    <div className={`newpush-container`}>
      <form className='newpush-newpush' onSubmit={handleSubmit}>
        <input
          type='text'
          required
          placeholder='description'
          className='newpush-textinput input'
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
        <div className='newpush-container1'>
          <span className='newpush-text'>Amount</span>
          <input
            type='number'
            min='0'
            required
            placeholder='amount'
            className='newpush-textinput1 input'
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
          />
        </div>
        <div className='newpush-container2'>
          <span className='newpush-text1'>Expires</span>
          <input
            type='date'
            required
            placeholder='expires'
            className='newpush-textinput2 input'
            value={expiration}
            onChange={(e) => {
              setExpiration(e.target.value);
            }}
          />
        </div>

        <MultiSelect
          options={options}
          value={isl_selection}
          onChange={set_isl_selection}
          labelledBy='Select'
          style={{ width: "100%" }}
          className='newpush-select'
        />

        <div className='newpush-container3'>
          <button className='newpush-button button'>Save</button>
        </div>
      </form>
    </div>
  );
};

export default Newpush;
