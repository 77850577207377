import React from "react";

import "./count_positive.css";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const Countpositive = (props) => {
  let _d = new Date(props.data.timestamp * 1000);

  return (
    <div className='countpositive-ledgeritemnegative'>
      <span className='countpositive-typetext'>REPORTED OVER</span>
      <span className='countpositive-amounttext'>{"+" + numberWithCommas(props.data.amount) + " VT"}</span>
      <div className='countpositive-bottomcont'>
        <span className='countpositive-bytext'>{props.data.transacted_by}</span>
        <span className='countpositive-datetext'>{_d.toISOString().replace("T", " ")}</span>
      </div>
    </div>
  );
};

export default Countpositive;
